import React, { Fragment, useEffect } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useNavigate, useLocation } from "react-router";
import { ArrowBack } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import Typography from "@mui/material/Typography";
import InputField2 from "../../common/InputComponents/InputField2";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import ToggleButtons from "../../common/InputComponents/ToggleButtons";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import { Box, Button, Grid } from "@mui/material";
import {
    Loading,
    useUpdate,
    useGetList,
    useNotify,
    useRefresh,
    useStore,
    useListContext
} from "react-admin";
import { useParams } from "react-router-dom";
import save_icon from "../../../assets/images/save_icon.svg";
import formValidation from "../../../yupFormValidationRules/formValidator";
import InputSelectField from "../../common/InputComponents/InputSelectField";
import TextInputPhone from "../../DocumentAnalysis/components/inputComponents/TextInputPhone";
import InputCheckBoxField from "../../common/InputComponents/InputCheckBoxField";
import { JSONTree } from "react-json-tree";
import CopyUrls from "../PropertySetup/CopyUrls";
import { REACT_APPLICANT } from "../../../config";
import MFAPopup from "../../manageUsers/MFAPopup";

const UpdateUser = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const notify = useNotify();
    const [open, setOpen] = React.useState(false);
    const refresh = useRefresh();
    // const { register, setValue, watch } = useFormContext();
    const associationTypes = [
        {
            AssociationType: "Property/Office",
            id: 1,
        }

    ];
    const toggleButton = [
        {
            value: 0,
            label: "Inactive",
            icon: <ClearIcon />,
            borderColor: "#909090",
            backgroundColor: "#EEE",
            boxShadowColor: "#90909033",
            color: "#606060",
        },
        {
            value: 1,
            label: "Active",
            icon: <CheckIcon />,
            borderColor: "#46A96F",
            backgroundColor: "#EBFFF3",
            boxShadowColor: "#46a96f1a",
            color: "#46A96F",
        },
    ];

    const [switchSubscriberObj] = useStore("switchSubscriberName");
    const { Id, UserType } = useParams();


    const {
        data: getrole,
        isLoading: loadingrole,
    } = useGetList(
        "get-role",
        {
            filter: {
                subscriberid: switchSubscriberObj?.value,

            },
        },
    );
    const {
        data: getassociation,
        isLoading: loadingassociation,
    } = useGetList(
        "get-association",
        {
            filter: {
                subscriberid: switchSubscriberObj?.value,

            },
        },
    );
    const {
        data: getagent,
        isLoading: loadingagent,
    } = useGetList(
        "get-agent",
        {
            filter: {
                subscriberid: switchSubscriberObj?.value,

            },
        },
    );
    const {
        data: getmanager,
        isLoading: loadingmanager,
    } = useGetList(
        "get-manager",
        {
            filter: {
                subscriberid: switchSubscriberObj?.value,

            },
        },
    );
    // const { displayedFilters, filterValues, setFilters, hideFilter, sort } =
    //     useListContext();
    //     console.log(filterValues,"pooja")
    const {
        data: getusersetup,
        isLoading: loadingUserSetupDetails,
    } = useGetList(
        "user-setup", {
        filter: {
            SubscriberId: switchSubscriberObj?.value,
            id: [Id],
            UserType: UserType,


        },
    }


    );
    const usernameVal = getusersetup?.[0]?.Name
    // form validation schema
    const validationSchema = yup.lazy((value) =>
        yup.object().shape({
            firstname: formValidation.name,
            lastname: formValidation.name,
            active: formValidation.StringRequiredValidation,
            twofactorenabled: formValidation.StringRequiredValidation,
            email: formValidation.email,
            // username: formValidation.username,
            mobile: formValidation.mobileNo,
            otherphone: formValidation.mobileNo,
            usertype: formValidation.validationForDropDown,
            associationType: yup
                .object()
                .when("usertype", {
                    is: (usertype) => usertype?.label === "Agent" || usertype?.value === "Manager",
                    then: yup.object().typeError("Required").required("Required!"),
                    otherwise: yup.object().nullable(), // Not required if usertype is not "Agent"
                }),
            managerid: yup
                .object()
                .when("usertype", {
                    is: (usertype) => usertype?.label === "Agent",
                    then: yup.object().typeError("Required").required("Required!"),
                    otherwise: yup.object().nullable(), // Not required if usertype is not "Agent"
                }),
            // agentlist: yup
            //     .array()
            //     .when("usertype", {
            //         is: (usertype) => usertype?.value === "Manager",
            //         then: yup
            //             .array()
            //             .min(1, "Required!")
            //             .of(
            //                 yup.object().shape({
            //                     // Assuming each item in the array is an object with specific fields
            //                     value: yup.string().required("Value is required!"),
            //                     label: yup.string().required("Label is required!"),
            //                 })
            //             ),
            //         otherwise: yup.array().nullable(),

            //     }),

            officeList: yup
                .array()
                .when(["associationType", "usertype"], {
                    is: (associationType, usertype) =>
                        associationType?.label === "Property/Office" &&
                        (usertype?.value === "Manager" || usertype?.value === "Agent"),
                    then: yup
                        .array()
                        .min(1, "Required!")
                        .of(
                            yup.object().shape({
                                value: yup.string().required("Value is required!"),
                                label: yup.string().required("Label is required!"),
                            })
                        ),
                    otherwise: yup.array().nullable(),
                }),




        })
    );

    // Initial form values


    const methods = useForm<any>({
        //defaultValues: defaultFormValues,
        resolver: yupResolver(validationSchema),
        mode: "onChange",
    });

    useEffect(() => {
        //   if (!state?.isAdding) {
        let data = getusersetup?.[0];
        console.log(data, "1dataform12")
        const getFormData: any = {
            firstname: data?.FirstName,
            lastname: data?.LastName,
            active: parseInt(data?.Active),
            twofactorenabled: parseInt(data?.MFA),
            email: data?.Email,
            username: data?.UserName,
            managerid: {
                value: data?.ManagerID,
                label: data?.Managers,
            },
            otherphone: data?.OtherPhone,
            mobile: data?.Mobile,
            agentlist: data?.Agents?.split(",")
                .map((item, index) => {
                    const trimmedItem = item.trim();
                    return {
                        value: trimmedItem,
                        label: trimmedItem,
                    };
                })
                .filter(agent => agent.value != ""),

            officeList: (data?.Offices || data?.Properties)?.split(",").map((item, index) => ({
                value: item.trim(),
                label: item.trim(),
            })).filter(office => office.value != ""),
            associationType: {
                value: "Property/Office",
                label: "Property/Office",
            },
            // associationTypes:associationTypes?.AssociationType,

            // managerlist:data?.Manager?.split(",").map((item, index) => ({
            //     value: item.trim(),
            //     label: item.trim(),
            // })),
            usertype: {
                value: data?.UserType,
                label: data?.UserType,
            },

        };
        console.log(getFormData, "getFormData")
        methods.reset(getFormData);
        //  }
    }, [getusersetup && getusersetup[0]]);

    const [update, { data, isLoading, error }] = useUpdate();

    // form submit handler
    const onSubmit = (value) => {
        // debugger;
        console.log(value, "hello");

        const updateData = {
            ...value,
            id: value?.id,
            subscriberid: parseInt(switchSubscriberObj?.value),
            firstname: value?.firstname,
            lastname: value?.lastname,
            active: parseInt(value?.active),
            twofactorenabled: parseInt(value?.twofactorenabled),
            email: value?.email,
            username: value?.username,
            mobile: value?.mobile,
            usertype: value?.usertype?.value,
            officeList: value?.officeList.map((item) => item.value),
            agentlist: value?.agentlist.map((item) => item.value),
            managerid: value?.managerid?.value,
        };
        update(
            `update-user`,
            { data: updateData },
            {
                onSuccess: (data) => {
                    notify(

                        `User updated successfully.`,

                        {
                            type: "success",
                            anchorOrigin: { vertical: "top", horizontal: "right" },
                        }
                    );
                    refresh();
                    methods?.reset();
                    navigate(-1);
                },
                onError: (e: any) =>
                    notify(`Operation fail:${e.message}`, {
                        type: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                    }),
            }
        );
    };

    const isManager = methods.watch<any>("usertype")?.label === "Manager";
    const isAdmin = methods.watch<any>("usertype")?.label === "Admin";
    const isAgent = methods.watch<any>("usertype")?.label === "Agent";

    const associationtype = methods.watch<any>("associationType")?.label === "Property/Office";
    if (loadingUserSetupDetails || loadingrole || loadingassociation || loadingagent || loadingmanager) return <Loading />

    return (
        <Fragment>
            <div className="home_content">
                <Grid style={{ marginTop: 10, marginBottom: 14 }} container spacing={3}>
                    {/* Left side with title */}
                    <Grid item xs={12} md={2}>
                        <div className="row">
                            <div className="col-12 d-flex">
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Typography sx={{ fontWeight: "bold" }}>
                                        <button
                                            title="Go Back"
                                            style={{ color: "#2cc0e4" }}
                                            className="btn btn-link"
                                            onClick={() => navigate(-1)}
                                        >
                                            <ArrowBack />
                                        </button>

                                        <span>User Details</span>

                                    </Typography>
                                </Breadcrumbs>
                            </div>
                        </div>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={10}
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <div className="group-btn">

                            <button
                                type="button"
                                className="blue-btn px-3 bg-transparent shadow-none"
                                style={{ color: "#2DC3E8" }}
                                onClick={() => {
                                    navigate(`/ats/settings/usersetup/userlogs/${usernameVal}`);
                                    setTimeout(() => {
                                        localStorage.setItem('username', usernameVal); // Store usernameVal in localStorage after navigation
                                    }, 0);
                                }}
                            >
                                User Logs
                            </button>
                        </div>
                    </Grid>
                </Grid>


                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)} className="mr-t30">
                       
                        <div className="row">
                            <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <InputField2
                                    label="First Name"
                                    source={`firstname`}
                                    placeholder="Enter First Name"
                                    labelClass={"lable-title"}
                                    additionalInputClass={"cus-control"}
                                />
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <InputField2
                                    label="Last Name"
                                    source={`lastname`}
                                    placeholder="Enter Last Name"
                                    labelClass={"lable-title"}
                                    additionalInputClass={"cus-control"}
                                />
                            </div>
                            <div className="col-sm-12 col-md-3 col-lg-3 mr-b20">
                                <ToggleButtons
                                    toggleButton={toggleButton}
                                    label="Status"
                                    labelClass={"lable-title"}
                                    source={`active`}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <InputField2
                                    label="Email"
                                    source={`email`}
                                    placeholder="Email"
                                    labelClass={"lable-title"}
                                    additionalInputClass={"cus-control"}
                                />
                            </div>


                            {getusersetup && getusersetup?.[0]?.UserName && <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <InputField2
                                    label="User Name"
                                    source={`username`}
                                    placeholder="Enter User Name"
                                    disable={true}
                                    labelClass={"lable-title"}

                                    additionalInputClass={"cus-control"}
                                />
                            </div>}
                            <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <ToggleButtons
                                    toggleButton={toggleButton}
                                    label="Multi Factor Authentication"
                                    labelClass={"lable-title"}
                                    source={`twofactorenabled`}
                                    onClickHandler={(e) => {
                                        // if (!state?.isAdding) {
                                        if (methods?.watch(`twofactorenabled`) != e?.target?.value) {
                                            setOpen(true);
                                        }
                                        // }
                                    }}
                                />
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <TextInputPhone
                                    source={`mobile`}
                                    label={`Mobile Number`}
                                    isCountryCode={`applicant.mobile.${0}.country_code`}
                                    labelClass={"lable-title"}
                                    additionalInputClass={"cus-control"}
                                    widthClass={"w-100"}
                                    requiredClass={{
                                        fontSize: "70%",
                                    }}
                                // onChangeCallback={() => {
                                //   props?.handleAPIDebounced();
                                // }}
                                />
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <TextInputPhone
                                    required={false}
                                    source={`otherphone`}
                                    label={`Other Phone `}
                                    //  isCountryCode={`applicant.mobile.${0}.country_code`}
                                    labelClass={"lable-title"}
                                    additionalInputClass={"cus-control"}
                                    widthClass={"w-100"}
                                    requiredClass={{
                                        fontSize: "70%",
                                    }}
                                // onChangeCallback={() => {
                                //   props?.handleAPIDebounced();
                                // }}
                                />
                            </div>

                            <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <InputSelectField

                                    label={`Role`}
                                    source={`usertype`}
                                    options={
                                        getrole?.map((item, index) => ({
                                            value: item?.RoleName,
                                            label: item?.RoleName,
                                        }))
                                    }
                                    labelAsteriskClass={"color-red"}
                                    labelClass={"lable-title"}
                                    requiredClass={{
                                        fontSize: "70%",
                                    }}
                                    onChangeCallback={(event) => {
                                        // Clear fields when usertype changes
                                        methods.setValue("officeList", []);
                                        methods.setValue("agentlist", []);
                                        methods.setValue("managerid", []);
                                        methods.setValue("associationType", []);
                                    }}


                                />
                            </div>
                        </div>

                        <div className="row">
                            {(isAgent || isManager) && <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <InputSelectField
                                    label={`Association Type`}
                                    source={`associationType`}
                                    options={
                                        associationTypes?.map((item, index) => ({
                                            label: item?.AssociationType,
                                            value: item?.AssociationType,
                                        }))
                                    }
                                    labelAsteriskClass={"color-red"}
                                    labelClass={"lable-title"}
                                    requiredClass={{
                                        fontSize: "70%",
                                    }}
                                // required={false}
                                // multiple={true}
                                />
                            </div>}
                            {(isAgent || isManager) && associationtype && <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <InputSelectField
                                    label={`Select From List`}
                                    source={`officeList`}
                                    options={
                                        getassociation?.map((item, index) => ({
                                            label: item?.OfficeName || item.PropertyName,
                                            value: item?.OfficeName || item.PropertyName,
                                        }))
                                    }
                                    labelAsteriskClass={"color-red"}
                                    labelClass={"lable-title"}
                                    requiredClass={{
                                        fontSize: "70%",
                                    }}
                                    // required={false}
                                    multiple={true}
                                />
                            </div>}

                            {isManager && <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <InputSelectField
                                    label={`Agent List`}
                                    source={`agentlist`}
                                    options={
                                        getagent?.map((item, index) => (

                                            {
                                                value: item?.Name,
                                                label: item?.Name,
                                            }

                                        ))

                                    }
                                    labelAsteriskClass={"color-red"}
                                    labelClass={"lable-title"}
                                    requiredClass={{
                                        fontSize: "70%",
                                    }}
                                    multiple={true}
                                    required={false}
                                />
                            </div>}

                            {isAgent && <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <InputSelectField
                                    label={`Reporting Manager`}
                                    source={`managerid`}
                                    options={
                                        getmanager?.map((item, index) => (

                                            {
                                                value: item?.ID,
                                                label: item?.Name,
                                            }

                                        ))


                                    }

                                    labelAsteriskClass={"color-red"}
                                    labelClass={"lable-title"}
                                    requiredClass={{
                                        fontSize: "70%",
                                    }}
                                // required={false}
                                />
                            </div>}
                        </div>

                        <div className="row mr-b50">
                            {getusersetup?.[0]?.URL && (
                                <div className="col-12 mr-b10">
                                    <CopyUrls
                                        heading={"Application URL"}
                                        url={getusersetup?.[0]?.URL}
                                    />
                                </div>)}

                            {getusersetup?.[0]?.URL && (
                                <div className="col-12 mr-b10">
                                    <CopyUrls
                                        heading={"DocUpload URL"}
                                        url={getusersetup?.[0]?.DocuploadURL}
                                    />
                                </div>)}

                            {getusersetup?.[0]?.URL && (
                                <div className="col-12 mr-b10">
                                    <CopyUrls
                                        heading={"GuestCard URL"}
                                        url={getusersetup?.[0]?.GuestCardURL}
                                    />
                                </div>)}

                            <div className="col-12 mr-b20" >
                                <div
                                    className="p-3"
                                    style={{ background: "#f5fdff", borderRadius: "8px" }}
                                >
                                    <div className="row" style={{
                                        display: "flex",
                                        alignItems: "flex-end",
                                        justifyContent: "flex-end"

                                    }}>

                                        <div className="col-sm-12 col-md-8 col-lg-4" style={{
                                            marginLeft: "800px"
                                        }}>
                                            <div className="d-flex justify-content-end">
                                                <div className="mx-5">
                                                    <Button
                                                        sx={{ mx: 1 }}
                                                        onClick={() => {
                                                            refresh();
                                                            methods?.reset();
                                                            navigate(-1);
                                                        }}
                                                        variant="outlined"
                                                        color="secondary"
                                                        type="button"
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        startIcon={!isLoading && <img src={save_icon} alt="save icon" />}
                                                        sx={{ mx: 1 }}
                                                        variant="contained"
                                                        type="submit"
                                                        disabled={isLoading}
                                                    >
                                                        {isLoading ? (
                                                            <CircularProgress size="20px" sx={{ color: "white" }} /> // Show loader when isLoading is true
                                                        ) : (
                                                            <span>Update</span> // Show text when isLoading is false
                                                        )}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                </FormProvider>
            </div>
            <MFAPopup
                open={open}
                setOpen={setOpen}
                setValue={methods?.setValue}
                status={methods?.getValues("twofactorenabled")}
            />
        </Fragment>
    );
};

export default UpdateUser;
