import React, { useEffect, useMemo } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Alert from '@mui/material/Alert';
import { useMutation, useQueryClient } from 'react-query';
import { JSONTree } from 'react-json-tree';
import { Add, Edit } from '@mui/icons-material';
import AddValidationComment from '../../Ats/SuperAdminInbox/AddValidationComment';
import EditValidationComment from '../../Ats/SuperAdminInbox/EditValidationComment';

const ShowValidationMessages = (props) => {

  // const [loading, setLoading] = React.useState(false);
  const [isHideAll, setIsHideAll] = React.useState(false);
  const queryClient = useQueryClient()

  const dataProvider = useDataProvider();
  const notify = useNotify();

  React.useEffect(() => {
    const isAllDeleted = props?.validation_message?.every(
      (message) => message.isDeleted
    );
    setIsHideAll(isAllDeleted);
  }, [props?.validation_message]);


  const { mutate, isLoading, isSuccess } = useMutation(props.tag, (data: any) => dataProvider.disableValidationMessage({
    id: data.id,
    isDeleted: data.isDeleted,
  }),
    {
      onSuccess: (data) => {

        queryClient.invalidateQueries([`${props.invalidateQueries}`])
        notify("Validation message status updated", { type: "success", anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      },
      onError: (data) => {
        alert("error")
      },
      onMutate: () => {
        notify('Updating status...', { type: 'info', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      }
    })

  const { mutate: mutateHandleHideAll, isLoading: isLoadingHideAll, isSuccess: isSuccessHideAll } = useMutation(`updateAllValidationMessages_${props.tag}`, (data: any) => {
    let promises: any = [];
    props?.validation_message?.map(
      async (message) =>
        promises.push(
          dataProvider.disableValidationMessage({
            id: message.ID,
            isDeleted: data.isDeleted,
          }))
    );
    return Promise.all(promises)
  },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([`${props.invalidateQueries}`])
        notify("Validation message status updated", { type: "success", anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      },
      onError: (data) => {
        alert("error")
      },
      onMutate: () => {
        notify('Updating status...', { type: 'info', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      }
    })


  const showOrHideValidationMessage = async (id, isDeleted) => {
    mutate({
      id: id,
      isDeleted: isDeleted,
    })
  };

  const handleHideAll = async (val) => {
    setIsHideAll(!val);
    mutateHandleHideAll({
      isDeleted: val
    })
  };
  let validationMessageList: any[] = props?.validation_message

  // Function for set a flag value
  const hasAnyNotificationOne = (objects) => {
    return objects
      ? objects?.some(
        (notification) => notification.message && notification.message !== ""
      )
      : false;
  };
  return (
    validationMessageList?.length > 0
      ?
      (
        <div className="notify-wrap mt-2 mb-1">
          {hasAnyNotificationOne(
            // props.role === "Docuverus Super Admin"
            // ? 
            validationMessageList
            // : validationMessageList?.filter((item) => !item.isDeleted)
          ) ? (
            <div className="notify-head" style={{ marginBottom: 0 }}>
              <h4 style={{ fontSize: 12 }}>{!props?.isApplicantTitle?'Docuverus Validation':'Applicant validation'} </h4>
              {/* props.role === "Docuverus Super Admin" */}
              {true && (<div>
                <Button
                  className="print-hide"
                  startIcon={isHideAll ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  onClick={() => handleHideAll(isHideAll ? false : true)}
                >
                  {isHideAll ? "Unhide All" : "Hide All"}
                </Button>
                <AddValidationComment {...props} />
                {/* <Button
                  className="print-hide"
                  startIcon={<Add/>}
                  onClick={() => {}}
                >
                  Add New
                </Button> */}
              </div>
              )}
            </div>
          ) : (
            ""
          )}
          {(true ? validationMessageList : validationMessageList?.filter(item => !item.isDeleted))?.map((notification, index) => (

            (notification.message && notification.message !== "" ? <Alert
              severity="warning"
              key={notification.message + index}
              className={notification.isDeleted && 'print-hide'}
              sx={{
                '& .MuiAlert-icon': {
                  fontSize: 15,
                  marginTop: '3px',
                },
                fontSize: 11,
                textDecoration: notification.isDeleted ? 'line-through' : 'none',
                paddingTop: 0,
                paddingBottom: 0
              }}
              action={true ?
                notification.isDeleted ?
                  <IconButton
                    size='small'
                    className='print-hide'
                    color="success"
                    aria-label="mark comment false"
                    component="label"
                    onClick={() =>
                      showOrHideValidationMessage(notification.ID, false)
                    }
                  >
                    <TaskAltIcon style={{ fontSize: 14 }} />
                  </IconButton>
                  :
                  <>
                    {/* <IconButton
                      size='small'
                      // color="primary"
                      aria-label="mark comment true"
                      className='print-hide'
                      component="label"
                      onClick={() =>
                        showOrHideValidationMessage(notification.ID, true)
                      }
                    >
                      <Edit style={{ fontSize: 14 }} />
                    </IconButton> */}
                    {/* <EditValidationComment/> */}
                    {!notification?.defaultValidationMsg && <EditValidationComment {...props} editData={notification} />}

                    <IconButton
                      size='small'
                      color="warning"
                      aria-label="mark comment true"
                      className='print-hide'
                      component="label"
                      onClick={() =>
                        showOrHideValidationMessage(notification.ID, true)
                      }
                    >
                      <CancelOutlinedIcon style={{ fontSize: 14 }} />
                    </IconButton>
                  </>
                : null
              }
            >{notification.message}</Alert> : null)
          ))}
        </div>
      )
      : null
  );
}

export default ShowValidationMessages;