import React, { useEffect } from "react";
import ToggleButtons from "../../componets/common/InputComponents/ToggleButtons";
import "../../App.css";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box, Button } from "@mui/material";
import save_icon from "../../assets/images/save_icon.svg";
import { useCreate, useGetIdentity, useGetList, useNotify, useStore } from "react-admin";
import QuillTextEditor from "../../componets/common/InputComponents/TextEditorWithDataField/QuillTextEditor";
import { JSONTree } from "react-json-tree";
import {useNavigate} from 'react-router-dom'

const TermsAndConditionForm = ({ DataFrom, dataFieldOptions }) => {
    // Component logic goes here
    const [create, { isLoading, data }] = useCreate();
    const [docuverusAccordion, setDocuverusAccordion] = React.useState(false);
    const [switchSubscriberObj, setSwitchSubscriberObj] = useStore("switchSubscriberName");
    console.log(switchSubscriberObj?.value, "idofsub");
    const schema =
        yup.object().shape({

            PropertyOfficeDescription:
                yup.string().when('PropertyOfficeActive', {
                    is: 'true',
                    then: yup.string().required("Required").test(
                        'not-only-br', // Name of the test
                        'Required', // Error message
                        value => value !== '<p><br></p>' // Test function
                    ),
                    otherwise: yup.string().notRequired(),
                }),
        });

    const defaultFormValues = {

        PropertyOfficeDescription: "",
        PropertyOfficeActive: "false",
        Type: ''
    };

    const methods = useForm({
        defaultValues: defaultFormValues,
        resolver: yupResolver(schema),
        mode: "onChange",
    });
    const notify = useNotify();

    const onSubmit = (data) => {
        notify("Updating Terms and Conditions Please wait a moment", { type: 'info', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        create("addupdatetermsandcondition", { data: { Type: data.Type, SubscriberId: switchSubscriberObj?.value, Description: data?.PropertyOfficeDescription, Active: data?.PropertyOfficeActive == "true" ? 1 : 0 } });
    };
    useEffect(() => {
        if (data) {
            notify("Terms and Conditions Updated Successfully", { type: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
    }, [data]);
    useEffect(() => {
        if (DataFrom) {
            methods.setValue('PropertyOfficeDescription', DataFrom?.PropertyOfficeDescription || '');
            methods.setValue('PropertyOfficeActive', DataFrom?.PropertyOfficeActive?.toString() || 'false');
            methods.setValue('Type', DataFrom?.Type?.toString());
        }
    }, [DataFrom]);
    const navigate = useNavigate();
    const toggleButton = [
        {
            value: "false",
            label: "Inactive",
            icon: <ClearIcon />,
            borderColor: "#909090",
            backgroundColor: "#EEE",
            boxShadowColor: "#90909033",
            color: "#606060",
        },
        {
            value: "true",
            label: "Active",
            icon: <CheckIcon />,
            borderColor: "#46A96F",
            backgroundColor: "#EBFFF3",
            boxShadowColor: "#46a96f1a",
            color: "#46A96F",
        },
    ];

    return (
        <div>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)} className="mr-t30">
                    {" "}
                    <div className="info-toggle">
                        <div
                            className="toggle-heads"
                            onClick={(event) => {
                                event.preventDefault();
                                setDocuverusAccordion((v) => !v);
                            }}
                        >
                            <div className="row align-items-center justify-content-between ">
                                <div className="col">
                                    <div className="d-flex align-items-center">
                                        <b
                                            className="ms-3 me-2"
                                            style={{ fontSize: "18px", fontWeight: "700", fontFamily: "Open Sans" }}
                                        >
                                            Property Management Company
                                        </b>

                                    </div>
                                </div>

                                <div className="col text-end m-1 remove_mr">
                                    <ToggleButtons
                                        toggleButton={toggleButton}
                                        label=""
                                        labelClass={"lable-title"}
                                        source={`PropertyOfficeActive`}
                                    />
                                </div>
                            </div>
                            {/*  ${!docuverusAccordion ? "d-none" : "d-display"
                              } */}
                            <div
                                className={` card-body cust-card-appl`}
                            >
                                <div
                                    className="card card-body w-100"
                                    style={{ paddingTop: 0 }}
                                >
                                    <br />
                                    <div className="row">
                                        <div className="col-sm-12 mr-b20">
                                            <QuillTextEditor dataFieldOptions={dataFieldOptions} source={'PropertyOfficeDescription'} control={methods.control} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Box
                        sx={{
                            height: "60px",
                            background: "#F5FDFF",
                            boxShadow: "0px -1px 12px 0px rgba(0, 0, 0, 0.12)",
                        }}
                        display={"flex"}
                        justifyContent={"end"}
                        alignItems={"center"}
                    >
                        <Box>
                            <Button
                                sx={{ mx: 1 }}
                                onClick={() => {

                                    navigate(-1)
                                }}
                                variant="outlined"
                                color="secondary"
                                type="button"
                            >
                                Cancel
                            </Button>
                            <Button
                                startIcon={<img src={save_icon} alt="save icon" />}
                                sx={{ mx: 1 }}
                                variant="contained"
                                type="submit"
                                disabled={isLoading}
                            >
                                Update
                            </Button>
                        </Box>
                    </Box>
                </form>
            </FormProvider>
        </div>
    );
};

export default TermsAndConditionForm;