import React, { useState } from "react";

import SideBar from "../../pages/Sidebar/sidebar";
import { FormProvider, useForm } from "react-hook-form";
import InputField2 from "../../componets/common/InputComponents/InputField2";
import Grid from "@mui/material/Grid";
import "../../assets/styles/style.css";
import InputSelectField from "../../componets/common/InputComponents/InputSelectField";
import { Card, CardActions, CardContent, CardHeader } from "@mui/material";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import crossIcon from "../../assets/images/crossIcon.svg";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  FormLabel,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import Header from "../../componets/common/Header/Header";
import YesNoRadioButton from "../../componets/common/InputComponents/YesNoRadioButton";
import InputField from "../../componets/common/InputComponents/InputField";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ClearIcon from "@mui/icons-material/Clear";
import QuillTextEditor from "../common/InputComponents/TextEditorWithDataField/QuillTextEditor";
import CheckIcon from "@mui/icons-material/Check";
import ToggleButtons from "../../componets/common/InputComponents/ToggleButtons";
import TextEditor from "../../componets/common/InputComponents/TextEditor";
import InputDateField from "../../componets/common/InputComponents/InputDateFieldOld";
import {
  Loading,
  useCreate,
  useGetList,
  useNotify,
  useRefresh,
  useStore,
  useUpdate,
  useListContext,
  useGetOne,
  useGetIdentity,
} from "react-admin";
import { useNavigate, useParams } from "react-router";
import { JSONTree } from "react-json-tree";
const AddNotification = (props) => {
  // const login = useLogin();
  const notify = useNotify();
  const navigate = useNavigate();
  const refresh = useRefresh();
  const { states } = props;
  const {
    displayedFilters,
    filterValues,
    setFilters,
    hideFilter,
    sort,
    total,
  } = useListContext();
  const { data: dataField } = useGetList("GETDataFiled");
  // const methods = useForm();
  const schema = yup.object().shape({
    TemplateName: yup.string().required("required"),
    Active: yup.string().required("required"),
    StartDate: yup.string().required("required"),
    StartTime: yup.string().required("required"),
    EndDate: yup.string().required("required"),
    EndTime: yup.string().required("required"),
    Template: yup.string().required("required"),
    // SubscriberID: yup.object().required("required").typeError("required"),
    // PropertyOrOffice: yup.object().required("required").typeError("required"),
    // States: yup.object().required("required").typeError("required"),
  });
  const toggleButton = [
    {
      value: "Inactive",
      label: "Inactive",
      icon: <ClearIcon />,
      borderColor: "#909090",
      backgroundColor: "#EEE",
      boxShadowColor: "#90909033",
      color: "#606060",
    },
    {
      value: "Active",
      label: "Active",
      icon: <CheckIcon />,
      borderColor: "#46A96F",
      backgroundColor: "#EBFFF3",
      boxShadowColor: "#46a96f1a",
      color: "#46A96F",
    },
  ];
  const { id } = useParams();
  //   const { data: applicationDetail, isLoading: isLoadingData } = useGetOne(
  //     "getnewapplicationdetail",
  //     {
  //     id,
  //     }
  // );
  const { data: getStates, isLoading: isLoadingStates } = useGetOne(
    "getStates",
    {
      id: 1,
    },
    { refetchOnWindowFocus: false }
  );

  const methods = useForm({
    defaultValues: {
      TemplateName: "",
      StartDate: "",
      StartTime: "",
      EndDate: "",
      EndTime: "",
      Active: "",
      SubscriberID: [],
      PropertyOrOffice: [],
      States: [],
    },
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const [create, { isLoading, error }] = useCreate();
  const form = useForm({});
  const [officeData, setOfficeData] = useState([]);
  // form submit handler
  const onSubmit = (value) => {
    console.log("Dat123", value.id);
    const addData = {
      // ...value,
      // subscriberid: parseInt(switchSubscriberObj?.value),
      // usertype: value?.usertype?.value,
      // officeList: value?.officeList?.map((item) => item.value),
      // agentlist: value?.agentlist?.map((item) => item.value),
      // managerid: value?.managerid?.value,
      // firstname: value?.firstname,
      // lastname: value?.lastname,
      // active: parseInt(value?.active),
      // twofactorenabled: parseInt(value?.twofactorenabled),
      // isregister: value?.isregister,
      // email: value?.email,
      // username: value?.username,
      // mobile: value?.mobile,
      // otherphone: value?.otherphone,
      // ID: value?.ID,
      ...value,
      // ID:0,
      TemplateName: value?.TemplateName,
      Template: value?.Template,
      StartDate: value?.StartDate,
      StartTime: value?.StartTime,
      EndTime: value?.EndTime,
      // Active:parseInt(value?.Active),
      SubscriberID: value?.SubscriberID?.map((item) => item.value),
      VisibleTo: value?.VisibleTo?.map((item) => item.value),
      PropertyOrOffice: value?.PropertyOrOffice?.map((item) => item.value),
      States: value?.States?.map((item) => item.label),
      Active: value?.Active == "Active" ? 1 : 0,
    };

    console.log(value, "dump");
    create(
      `addSystemNotification`,
      { data: addData },
      {
        onSuccess: (data) => {
          notify(
            `Notification created successfully.`,

            {
              type: "success",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            }
          );
          refresh();
          methods?.reset();
          navigate(-1);
        },
        onError: (e: any) =>
          notify(`Operation fail:${e.message}`, {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }),
      }
    );
    // create(
    //   `getCollectiveListOfPropertyAndOffice`,
    //   {
    //     data: {
    //       SubscriberID: value?.SubscriberID?.map((item) => item.value),
    //     },
    //   },
    //   {
    //     onSuccess: (response) => {
    //       // Store the Office data in state
    //       setOfficeData(response?.data?.Office || []);
    //     },
    //     onError: (e:any) =>
    //       notify(`Failed to load office data: ${e.message}`, {
    //         type: "error",
    //         anchorOrigin: { vertical: "top", horizontal: "right" },
    //       }),
    //   }
    // );
  };

  const {
    data: subscriberlist,
    isLoading: subscriberlistIsLoading,
    error: subscriberlistError,
  } = useGetList("getsubscribers", {});
  const { data: identity } = useGetIdentity();
  const [switchSubscriberObj] = useStore("switchSubscriberName");
  const [propertyFilter, setPropertyFilter] = useState([]);
  const {
    data,
    isLoading: officelistIsLoading,
    error: officelistError,
  } = useGetList("getCollectiveListOfPropertyAndOffice", {
    filter: propertyFilter,
  },{enabled:propertyFilter.length>0});
  return (
    <>
      <div className="home_content">
        <div className="row">
          <div className="col-12 d-flex">
            <Breadcrumbs aria-label="breadcrumb" separator=">">
              <Typography sx={{ fontWeight: "bold" }}>
                <Typography
                  color="textPrimary"
                  style={{ fontWeight: "bold", color: "rgba(0, 0, 0, 0.38)" }}
                  onClick={() => navigate(-1)}
                >
                  System Notifications
                </Typography>
              </Typography>
              <Typography color="textPrimary" style={{ fontWeight: "bold" }}>
                Create New System Notifications
              </Typography>
            </Breadcrumbs>
          </div>
        </div>
        <div className="form">
          <div className="auth-form mr-t30">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Grid container spacing={3} columns={12}>
                  <Grid item xs={4}>
                    <InputField2
                      label="Template Name"
                      // register={methods.register("email")}
                      source={`TemplateName`}
                      placeholder="Enter template name here"
                      labelClass={"lable-title"}
                      additionalInputClass={"cus-control"}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <ToggleButtons
                      toggleButton={toggleButton}
                      label="Status"
                      labelClass={"lable-title"}
                      source={`Active`}
                      onClickHandler={(event) => event.stopPropagation()}
                    />
                  </Grid>
                </Grid>{" "}
                <br />
                <Grid container spacing={3} columns={12}>
                  <Grid item xs={4}>
                    <InputDateField
                      source={`StartDate`}
                      label="Start Date"
                      labelClass={"lable-title"}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputField2
                      label="Start Time"
                      type="time"
                      source={`StartTime`}
                      labelClass={"lable-title"}
                      additionalInputClass={"cus-control"}
                      placeholder={undefined}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} columns={12}>
                  <Grid item xs={4}>
                    {/* <JSONTree data={methods.watch("EndDate")}/> */}
                    <InputDateField
                      source={`EndDate`}
                      label="End Date"
                      labelClass={"lable-title"}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputField2
                      label="End Time"
                      type="time"
                      source={`EndTime`}
                      labelClass={"lable-title"}
                      additionalInputClass={"cus-control"}
                      placeholder={undefined}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} columns={12}>
                  <Grid item xs={4}>
                    {/* <p style={{paddingTop:10}}>Subscribers</p> */}
                    {/* <JSONTree data={methods.watch("SubscriberID")}/> */}

                    {subscriberlist && (
                      <InputSelectField
                        onChangeCallback={(value) => {
                          console.log(value," on change")
                          setPropertyFilter(value.map(item=>item.value))
                        }}
                        label="Subscribers"
                        options={subscriberlist?.map((item) => ({
                          label: item.value,
                          value: item.id,
                        }))}
                        source={"SubscriberID"}
                        required={false}
                        stopAutoFocus={true}
                        multiple
                      />
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    {data && (
                      <InputSelectField
                        onChangeCallback={() => {
                          //  setFilters({
                          //     ...filterValues,
                          //    office: form.getValues('office').value,  // Update the search_by filter with the new search value
                          // },[])
                          // resetForm();
                        }}
                        label="Properties/Offices"
                        options={data?.map((item) => {
                          return {
                            value: item?.ID,
                            label: item.PropertyName || item.OfficeName,
                          };
                        })}
                        source={"PropertyOrOffice"}
                        required={false}
                        stopAutoFocus={true}
                        multiple
                      />
                    )}
                     {/* {data && (
                      <InputSelectField
                        onChangeCallback={() => {
                          //  setFilters({
                          //     ...filterValues,
                          //    office: form.getValues('office').value,  // Update the search_by filter with the new search value
                          // },[])
                          // resetForm();
                        }}
                        label="Properties/Offices"
                        options={data?.map((item) => {
                          return {
                            value: item?.id,
                            label: item.OfficeName,
                          };
                        })}
                        source={"PropertyOrOffice"}
                        required={false}
                        stopAutoFocus={true}
                        multiple
                      />
                    )} */}
                  </Grid>
                </Grid>
                <Grid container spacing={3} columns={12}>
                  <Grid item xs={4}>
                    <InputSelectField
                      label="States"
                      source={`States`}
                      required={false}
                      options={getStates?.data?.map((item) => ({
                        value: item?.id,
                        label: item.StateShortCode + " - " + item.StateName,
                      }))}
                      multiple
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputSelectField
                      label="Visible To"
                      source="VisibleTo"
                      required={false}
                      options={[
                        { label: "All Real Estate", value: "realEstate" },
                        { label: "All ATS", value: "ats" },
                        { label: "All Doc-Upload", value: "docUpload" },
                        { label: "All Guestcard", value: "guestcard" },
                      ]}
                      multiple
                    />
                  </Grid>
                </Grid>
                <br />
                <div className="row d-flex">
                  <div className="col-sm-11 mr-b20">
                    <label htmlFor="quillTextEditor" className="form-label">
                      Text
                    </label>
                    <QuillTextEditor
                      // id="quillTextEditor"
                      dataFieldOptions={dataField}
                      source={"Template"}
                      control={methods.control}
                    />
                  </div>
                </div>
                <Box
                  sx={{
                    height: "60px",
                    background: "#F5FDFF",
                    boxShadow: "0px -1px 12px 0px rgba(0, 0, 0, 0.12)",
                     width: "1100px"
                  }}
                  display={"flex"}
                  justifyContent={"end"}
                  alignItems={"center"}
                >
                  <div className="d-flex justify-content-end">
                    <div className="mx-3">
                      <Button
                        sx={{ mx: 1 }}
                        onClick={() => {
                          refresh();
                          methods?.reset();
                          navigate(-1);
                        }}
                        variant="outlined"
                        color="secondary"
                        type="button"
                      >
                        Cancel
                      </Button>

                      <Button
                        startIcon={!isLoading && <CheckIcon />} // Conditionally render the startIcon
                        sx={{ mx: 0 }}
                        variant="contained"
                        type="submit"
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <CircularProgress
                            size="20px"
                            sx={{ color: "white" }}
                          /> // Show loader when isLoading is true
                        ) : (
                          <span>Create Notification</span> // Show text when isLoading is false
                        )}
                      </Button>
                    </div>
                  </div>
                </Box>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNotification;
