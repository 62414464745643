import React, { useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { useCreate, useNotify, useRefresh, useStore } from "react-admin";
import { CircularProgress } from "@mui/material";
import { MdOutlineUploadFile } from "react-icons/md";

const file_type = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
  "text/csv",
];
// const file_size = 4000 * 1024;

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const InputFileImport = ({
  buttonText = "Import CSV",
  importType,
  startIcon=false,
  ...props
}) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const submitBtnRef = useRef<HTMLButtonElement>(null);
  const [switchSubscriberObj] = useStore("switchSubscriberName");
  const buttonStyle: any = {
    color: "#2DC3E8",
    border: "1px solid #2DC3E8",
    borderRadius: "4px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
    fontFamily: "OpenSans-Bold",
    textTransform: "capitalize",
    margin: "0px 0px 0px 10px",
    textAlign: "center",
    minWidth:"7rem",
  };

  const [create, { isLoading, error }] = useCreate();

  // form submit handler
  const onSubmit = (value) => {
    const formData: any = new FormData();
    formData.append("importType", importType);
    formData.append("SubscriberID", switchSubscriberObj?.value);
    formData.append("file", value);
    if (props?.propertyID) {
      formData.append("PropertyID", props?.propertyID);
    }
console.log(formData,"import another place")
    create(
      `import-xls`,
      { data: formData },
      {
        onSuccess: (data) => {
          notify(`File import successfully.`, {
            type: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          refresh();
        },
        onError: (e: any) => {
          notify(`Operation fail:${e.message}`, {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        },
      }
    );
  };

  const errorMessage = (error: any) => {
    notify(error, {
      type: "error",
      anchorOrigin: { vertical: "top", horizontal: "right" },
    });
  };

  const validateFile = (newFile) => {
    if (!newFile) return;
    // if (newFile.size > file_size) {
    //   errorMessage("File size exceeds 4Mb");
    //   return false;
    // }
    if (!file_type.includes(newFile.type)) {
      errorMessage("Supported file format is Excel and CSV files only.");
      return false;
    }
    return true;
  };

  const handleChange = (event: any) => {
    const newFile = event.target.files[0];
    submitBtnRef?.current?.click();
    if (validateFile(newFile)) {
      onSubmit(newFile);
    }
  };

  return (
    <>
      {isLoading ? (
        <Button component="label" variant="outlined" style={{ ...buttonStyle }}>
          <CircularProgress size="20px" />
          <h5
            style={{
              fontSize: "12px",
              color: "#303030",
              fontFamily: "OpenSans-Regular",
            }}
          >
            &nbsp; Please wait...
          </h5>
        </Button>
      ) : (
        <>
          <Button
            component="label"
            startIcon={startIcon == true ? <MdOutlineUploadFile size={18}/> :false }
            variant="outlined"
            className="px-3"
            style={{ ...buttonStyle }}
          >
            {buttonText}
            <VisuallyHiddenInput
              type="file"
              onChange={handleChange}
              accept={file_type.join(", ")}
            />
          </Button>
        </>
      )}
    </>
  );
};

export default InputFileImport;
