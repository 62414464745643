import * as React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

const SubscriberNotification = () => {
  return (
    <Stack sx={{ width: '100%', marginTop: '15px' }} spacing={2}>
      <Alert 
        severity="warning" 
        onClose={() => {}} 
        sx={{ 
          height: '40px', 
          width: '100%', 
          backgroundColor: '#ECFBFF', 
        
        }}
        icon={false}
      >
        <span style={{ 
          color: 'red', 
          fontFamily: 'Open Sans', 
          fontWeight: 700, 
          paddingLeft: '10px', 
          fontSize: '12px' 
        }} >
          This Alert displays the default close icon.
        </span>
      </Alert>
    </Stack>
  );
};

export default SubscriberNotification;



