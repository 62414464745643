import React from "react";
import ArchiveIcon from "@mui/icons-material/Archive";
import { Box, Button, InputAdornment } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteIcon from "@mui/icons-material/Delete";
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import {
  useGetOne,
  SelectInput,
  useGetList,
  useUpdate,
  useNotify,
  useRefresh,
} from "react-admin";
import { useForm, FormProvider, get } from "react-hook-form";
import { Button as MuiButton } from "@mui/material";
import { useParams } from "react-router-dom";
import InputSelectField from "../common/InputComponents/InputSelectField";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import LoadingSpinner from "../common/loadingSpinner";
import Transfer from "../../assets/images/Transfer.svg";

export const ApplicantList = (props) => {
  const { id: paramsId } = useParams();
  const id = paramsId || props.subscriberId;
  console.log("id 2", id);
  const { ApplicantionId, selected } = props;
  console.log("props 1", props);
  const { data, isLoading, error } = useGetList(
    "get_subscriber_inbox_detials",
    {
      filter: { ApplicationNumber: ApplicantionId, subscriberid: id },
    }
  );
  console.log("data 4", data);
  return (
    <Box component="span">
      <div className="row">
        <div className="col-12 mr-b30">
          {isLoading && <p>Loading...</p>}
          <div className="row">
            <div className="col-12 mr-b30">
              {!isLoading && data && (
                <>
                  <>
                    <InputSelectField
                      label="Co-applicant/Cosigner"
                      source={`ApplicantId`}
                      options={data
                        ?.filter((item) => item.id != selected && item.ApplicantStatus !== "Removed")
                        .map((item) => ({
                          label: item.FirstName + "  " + item.LastName,
                          value: item.id,
                        }))}
                      labelAsteriskClass={"color-red"}
                      labelClass={"lable-title"}
                      requiredClass={{
                        fontSize: "70%",
                      }}
                    />
                  </>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default function SwitchApplicant(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  console.log("props6", props);

  const { record, subscriberId, switchApplicantProps } = props;

  const selectedApplicant = record.filter(
    (check) => check.ApplicantType == "Applicant"
  );
  console.log("selectedApplicant", selectedApplicant);

  const validationSchema = Yup.lazy((value) =>
    Yup.object().shape({
      ApplicantId: Yup.object()
        .nullable()
        .required("Please Select Co-applicant/Co-signer")
    })
  );

  console.log("props 5", props);

  // form initial states
  const form = useForm({
    defaultValues: {
      ApplicantId: null,
    },
    resolver: yupResolver(validationSchema),
  });

  // Destructuring form
  const { register, reset } = form;

  const [update, { data, isLoading, error }] = useUpdate();

  const onSubmit = (value) => {
    console.log("value 4", value);
    update(
      "switchApplicant",
      {
        data: {
          ApplicationNumber: selectedApplicant[0].AppID,
          ApplicantID: value.ApplicantId.value,
        },
      },
      {
        onSuccess: (d) => {
          if (props.switchApplicantProps) {
            notify(`${value.ApplicantId.label} has selected as a Applicant`, {
              type: "success",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            });
            refresh();
            handleClose();
            reset();

          }

          if (!props.switchApplicantProps) {
            update(
              "removeapplicant",
              {
                id: selectedApplicant[0].id,
                data: {
                  Reason: "Applicant Role Change or Switch",
                  SubscriberID: subscriberId,
                  ApplicationNumber: selectedApplicant[0].AppID,
                  role: "Applicant",
                },
              },
              {
                onSuccess: (d) => {
                  notify(`${value.ApplicantId.label} has selected as a Applicant.Application Number ${d.ID} has been Removed`, {
                    type: "success",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                  });
                  refresh();
                  localStorage.removeItem("RaStore.getAdminAllinboxType.selectedIds");
                  localStorage.removeItem("RaStore.firstSelectedApp");
                  localStorage.removeItem("RaStore.getsubscriberinbox.selectedIds");
                  //  props.onRemove();
                  handleClose();
                  reset();
                },
                onError: (e: any) =>
                  notify(`Operation fail:${e.message}`, {
                    type: "error",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                  }),
              }
            );
          }

        },
        onError: (e: any) =>
          notify(`Operation fail:${e.message}`, {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }),
      }
    );
  };

  return (
    <>
      {props?.ApplicantRemove ? (
        <li key={selectedApplicant[0]?.id} onClick={handleOpen}>
          {selectedApplicant[0]?.FirstName} {selectedApplicant[0]?.LastName}
        </li>
      ) : (
        <MuiButton
          color={props.switchApplicantProps ? "primary" : "error"}
          onClick={handleOpen}
          disableRipple
          startIcon={props.switchApplicantProps ? <SwitchAccountIcon sx={{ fontSize: '15px !important', marginRight: '-2px' }} /> : <DeleteIcon />}
          variant="text"
          sx={{
            textTransform: "capitalize",
            '&:hover': {
              backgroundColor: 'inherit',   
            }
          }}
        >
          {props.switchApplicantProps ? "Switch" : "Remove"}
        </MuiButton>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.switchApplicantProps ? "Switch Applicant" : "Remove Applicant"}</DialogTitle>
        <DialogContent style={{ minHeight: 300 }}>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <ApplicantList
                selected={selectedApplicant[0].id}
                ApplicantionId={selectedApplicant[0].AppID}
                subscriberId={subscriberId}
              />
              <Box display={"flex"} justifyContent={"spaceBetween"} alignItems={"center"} marginLeft={"12px"}>
                <Button
                  sx={{ mx: 1 }}
                  onClick={() => {
                    handleClose();
                    reset();
                  }}
                  variant="outlined"
                  color="secondary"
                  type="button"
                >
                  Cancel
                </Button>
                <Button
                  startIcon={props.switchApplicantProps ? <SwitchAccountIcon /> : <DeleteIcon />}
                  sx={{ mx: 1 }}
                  variant="contained"
                  type="submit"
                >
                  {props.switchApplicantProps ? "Switch" : "Remove"}
                </Button>
              </Box>
            </form>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </>
  );
}