// in src/Dashboard.js
import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Title, useGetIdentity, useGetList, useGetOne, useListContext } from "react-admin";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { JSONTree } from "react-json-tree";
import TotalApplicationGraph from "../Ats/TotalApplicationGraph";
import { useParams } from "react-router";

import { LineChart, Line } from "recharts";
import { NumericFormat } from "react-number-format";

const StatsAndGraphVerification = () => {
  const {
    data,
    isLoading: loadingdocuploadmanagementstats,
} = useGetOne(
    "verificationmanagementstats",
    {
        id: 1
    }

);
 console.log(data?.records?.data,'Data statsAndGrap')
  
  // const statusCountData:any[]=docuploadmanagementstats?.record4
 
  // const subscriberCount = docuploadmanagementstats
  // const isAdmin = true
  // console.log("Response", docuploadmanagementstats)

  const [toggleButton, setToggleButton] = React.useState(true);
  const statusCountData:any = data?.records?.data ;
  // statusCountData
  // const statusCountDataNew:any[]=statusCountData;

  const percentageHandler = (value: number, type: string, totalPerCount?: string) => {
    
    const application =
      statusCountData?.filter((item) => item.ID == 13)[0]?.Count +
      statusCountData?.filter((item) => item.ID == 14)[0]?.Count +
      statusCountData?.filter((item) => item.ID == 15)[0]?.Count;
    const docUpload =
      statusCountData?.filter((item) => item.ID == 16)[0]?.Count +
      statusCountData?.filter((item) => item.ID == 17)[0]?.Count +
      statusCountData?.filter((item) => item.ID == 18)[0]?.Count;
    const total = type === "Application" ? application : docUpload;
    const percentage =
      value && value > 0 && total && total > 0 ? (value / total) * 100 : 0;
    // return Math.round(percentage) + "%";
    // return percentage.toFixed(2) + "%";
    if (totalPerCount) {
      return percentage;
    } else {
      return percentage.toFixed(2) + "%";
    }
  };

  const totalPercentageCountHandler = (type: string) => {
    
    const forApplication: any = (value: number) => {
      const per = percentageHandler(
        statusCountData?.filter((item) => item.ID == value)[0]?.Count,
        "Application",
        "totalPerCount"
      );
      return per;
    };
    const forDocUpload: any = (value: number) => {
      const per = percentageHandler(
        statusCountData?.filter((item) => item.ID == value)[0]?.Count,
        "DocUpload",
        "totalPerCount"
      );
      return per;
    };
    let totalPercentageCount: number = 100;
    if (type == "Application") {
      totalPercentageCount =
        forApplication(13) + forApplication(14) + forApplication(15);
    } else {
      totalPercentageCount =
        forDocUpload(16) + forDocUpload(17) + forDocUpload(18);
    }
    return Math.round(totalPercentageCount) + "%";
  };

  return (
    <>
      {/* <JSONTree data={statusCountData} /> */}
      <div className="">
        {/* <div className="green-alert">
                <h5 className="red-alert-text">
                    Please note: The system will be down for maintainance on 21st June, 2022 from 12:00 hrs EST to 14:00 hrs
                    EST. We are sorry for any inconvinience caused.
                </h5>
                <button type="button" className="alert-close">
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M4.99999 4.05733L8.29999 0.757332L9.24266 1.7L5.94266 5L9.24266 8.3L8.29999 9.24267L4.99999 5.94266L1.69999 9.24267L0.757324 8.3L4.05732 5L0.757324 1.7L1.69999 0.757332L4.99999 4.05733Z"
                            fill="#909090" />
                    </svg>
                </button>
            </div> */}


        {true && statusCountData && (
          <div className="app-count-table mr-t30 mr-b30">
            <table>

              <tbody>
                <tr>





                  {!toggleButton &&
                    true && (
                      <td colSpan={3} className="light-green-td">
                        <div className="ta-no-block d-flex align-items-center justify-content-center">
                          <p className="per_count">
                            {totalPercentageCountHandler("Application")}
                          </p>
                          <h4 className="color-green">
                            {statusCountData?.filter((item) => item.ID == 13)[0]
                              ?.Count +
                              statusCountData?.filter((item) => item.ID == 14)[0]
                                ?.Count +
                              statusCountData?.filter((item) => item.ID == 15)[0]
                                ?.Count
                              ? statusCountData?.filter(
                                (item) => item.ID == 13
                              )[0]?.Count +
                              statusCountData?.filter(
                                (item) => item.ID == 14
                              )[0]?.Count +
                              statusCountData?.filter(
                                (item) => item.ID == 15
                              )[0]?.Count
                              : 0}
                          </h4>
                          <small className="color-green mr-l10">
                            Completed Screened
                          </small>
                        </div>
                      </td>
                    )}
                  {(toggleButton ||
                    true) && (
                      <td colSpan={3} className="light-green-td">
                        <div className="ta-no-block d-flex align-items-center justify-content-center">
                          <p className="per_count">
                            {totalPercentageCountHandler("DocUpload")}
                          </p>
                          <h4 className="color-green">
                            {statusCountData?.filter((item) => item.ID == 16)[0]
                              ?.Count +
                              statusCountData?.filter((item) => item.ID == 17)[0]
                                ?.Count +
                              statusCountData?.filter((item) => item.ID == 18)[0]
                                ?.Count
                              ? statusCountData?.filter(
                                (item) => item.ID == 16
                              )[0]?.Count +
                              statusCountData?.filter(
                                (item) => item.ID == 17
                              )[0]?.Count +
                              statusCountData?.filter(
                                (item) => item.ID == 18
                              )[0]?.Count
                              : 0}
                          </h4>
                          <small className="color-green mr-l10">
                            Completed Screened
                          </small>
                        </div>
                      </td>
                    )}
                </tr>
                {!toggleButton && true && (
                  <tr>
                    <td className="light-green-td">
                      <div className="ta-no-block">
                        <p className="per_count">
                          {percentageHandler(
                            statusCountData?.filter((item) => item.ID == 13)[0]
                              ?.Count,
                            "Application"
                          )}
                        </p>
                        <h4 className="color-green">
                          {
                            statusCountData?.filter((item) => item.ID == 13)[0]
                              ?.Count
                          }
                        </h4>
                        <small className="color-green">Approved</small>
                      </div>
                    </td>
                    <td className="light-orange-td">
                      <div className="ta-no-block">
                        <p className="per_count">
                          {percentageHandler(
                            statusCountData?.filter((item) => item.ID == 14)[0]
                              ?.Count,
                            "Application"
                          )}
                        </p>
                        <h4 className="color-orange">
                          {
                            statusCountData?.filter((item) => item.ID == 14)[0]
                              ?.Count
                          }
                        </h4>
                        <small className="color-orange">Conditional</small>
                      </div>
                    </td>
                    <td className="light-red-td">
                      <div className="ta-no-block">
                        <p className="per_count">
                          {percentageHandler(
                            statusCountData?.filter((item) => item.ID == 15)[0]
                              ?.Count,
                            "Application"
                          )}
                        </p>
                        <h4 className="color-red">
                          {
                            statusCountData?.filter((item) => item.ID == 15)[0]
                              ?.Count
                          }
                        </h4>
                        <small className="color-red">Denied</small>
                      </div>
                    </td>
                  </tr>
                )}
                {(toggleButton || true) && (
                  <tr>
                    <td className="light-green-td">
                      <div className="ta-no-block">
                        <p className="per_count">
                          {percentageHandler(
                            statusCountData?.filter((item) => item.ID == 16)[0]
                              ?.Count,
                            "DocUpload"
                          )}
                        </p>
                        <h4 className="color-green">
                          {
                            statusCountData?.filter((item) => item.ID == 16)[0]
                              ?.Count
                          }
                        </h4>
                        <small className="color-green">Verified</small>
                      </div>
                    </td>
                    <td className="light-orange-td">
                      <div className="ta-no-block">
                        <p className="per_count">
                          {percentageHandler(
                            statusCountData?.filter((item) => item.ID == 17)[0]
                              ?.Count,
                            "DocUpload"
                          )}
                        </p>
                        <h4 className="color-orange">
                          {
                            statusCountData?.filter((item) => item.ID == 17)[0]
                              ?.Count
                          }
                        </h4>
                        <small className="color-orange">Doc Req</small>
                      </div>
                    </td>
                    <td className="light-red-td">
                      <div className="ta-no-block">
                        <p className="per_count">
                          {percentageHandler(
                            statusCountData?.filter((item) => item.ID == 18)[0]
                              ?.Count,
                            "DocUpload"
                          )}
                        </p>
                        <h4 className="color-red">
                          {
                            statusCountData?.filter((item) => item.ID == 18)[0]
                              ?.Count
                          }
                        </h4>
                        <small className="color-red">Unverifiable</small>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
      {/* Code new end here */}
    </>
  );
};

export default StatsAndGraphVerification;