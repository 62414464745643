import React, { useRef, useEffect } from 'react';
import { Button, useNotify } from 'react-admin';
import SignaturePad from 'signature_pad';

const SignaturePadComponent = ({setSignatureImage}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const signaturePadRef = useRef<SignaturePad | null>(null);
  const notify = useNotify();

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      signaturePadRef.current = new SignaturePad(canvas);

      // Resize the canvas to fill the container
      resizeCanvas();
      window.addEventListener('resize', resizeCanvas);

      return () => {
        window.removeEventListener('resize', resizeCanvas);
      };
    }
  }, []);

  const resizeCanvas = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      canvas.width = canvas.offsetWidth * ratio;
      canvas.height = canvas.offsetHeight * ratio;
      canvas.getContext('2d')?.scale(ratio, ratio);
    }
  };

  const handleClear = () => {
    signaturePadRef.current?.clear();
  };

  const handleSave = () => {
    if (signaturePadRef.current?.isEmpty()) {
      notify('Please provide a signature first.',{type:'warning',anchorOrigin: { vertical: 'top', horizontal: 'right' }});

    } else {
      const dataURL = signaturePadRef.current.toDataURL();
      // console.log(dataURL); // You can send this dataURL to your server or save it as an image
      // alert('Signature saved!');
      setSignatureImage('signatureImage',dataURL,{shouldValidate:true});
    }
  };

  return (
    <div style={{ border: '1px solid #000', padding: '10px', width: '400px', height: '200px' }}>
      <canvas ref={canvasRef} style={{ width: '100%', height: '100%' }} />
      <div style={{ marginTop: '10px' }}>
        <Button variant='outlined' label='Clear'  onClick={handleClear} />
        <Button label='Save' variant='contained' style={{marginLeft:4}} onClick={handleSave} />

        {/* <button className='btn btn-blue' onClick={handleClear}>Clear</button> */}
        {/* <button  className='btn' onClick={handleSave}>Save</button> */}
      </div>
    </div>
  );
};


export default SignaturePadComponent;
