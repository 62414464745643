import { Button, Empty, Layout, List, LoadingIndicator, Menu, Sidebar, TitlePortal, UserMenu, useCreate, useGetIdentity, useGetOne, useNotify, usePermissions, useStore } from "react-admin";
import { ReactQueryDevtools } from 'react-query/devtools'
import { AppBar } from 'react-admin';
import Box from '@mui/material/Box';
import BookIcon from '@mui/icons-material/Book';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import PeopleIcon from '@mui/icons-material/People';
import LabelIcon from '@mui/icons-material/Label';
import { BsBack, BsFillGearFill } from "react-icons/bs";
import EmailSettingsLogo from "../assets/images/mailLogo.png";
import Leadmgt from "../assets/images/Frame (2).png";
import Revenuemgt from "../assets/images/Frame.png";
import Docuploadmgt from "../assets/images/Frame (1).png";
import AgentMgt from "../assets/images/Frame (3).png"
import userSetupLogo from "../assets/images/userSetupLogo.png";
import ManageUserLogo from "../assets/images/ManageUser.png";
import {
  FaCircle,
  FaFileUpload,
  FaFlag,
  FaHistory,
  FaList,
  FaUpload,
} from "react-icons/fa";
import { BiArchive, BiArrowBack, BiExit, BiUpload } from "react-icons/bi";
import SwitchSubscriber from "./Ats/SwitchSubscriber";


import DocuverusLogo from "../assets/images/docuverus_logo.png";
import {
  Dashboard,
  DashboardCustomizeSharp,
  DashboardOutlined,
  Square,
} from "@mui/icons-material";
import { useParams } from "react-router";
import { JSONTree } from "react-json-tree";
import React, { useEffect } from "react";
import { ATS_DOCS_URL, SOFTWARE_VERSION, typesofUsers } from "../config";
import { ImUpload2 } from "react-icons/im";
import { RiFileUploadFill } from "react-icons/ri";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import { useLocation } from "react-router-dom";
import MuiList from "@mui/material/List";
import { IoMdSettings } from "react-icons/io";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ArticleIcon from '@mui/icons-material/Article';
import DnsIcon from '@mui/icons-material/Dns';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { Button as MuiButton } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { styled, alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import GoogleTranslate from "./common/GoogleTranslate";
import { BsShieldFillCheck } from "react-icons/bs";
import { color } from "html2canvas/dist/types/css/types/color";

declare global {
  interface Window {
      google: any;
  }
}




const MyUserMenu = () => {
  const [create, { isSuccess, data }] = useCreate();
  useEffect(() => {
    if (data) {
      localStorage.removeItem("auth");
      window.location.replace(
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.href.split(
          window.location.protocol + "//" + window.location.host
        )[1]
      );
    }
  }, [data]);

  return (
    <UserMenu>
      {/* <Button
            sx={{ width: 140 }}
            startIcon={<BiExit />}
            onClick={() => {
              create("logout");
            }}
            label="Logout"
          /> */}
      <MuiButton
        sx={{ width: 140 }}
        startIcon={<BiExit />}
        onClick={() => {
          create("logout");
        }}
        size="small"
      >
        Logout
      </MuiButton>
    </UserMenu>
  );
};

export const MyAppBar = () => {
  const navigate = useNavigate();
  const [switchSubscriberObj] = useStore("switchSubscriberName");
  const { data } = useGetIdentity();
  const subscriberView: boolean = data?.subscriberView;
  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }));

  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));
  const { permissions } = usePermissions();
  const switchSubscriberStatus = localStorage.getItem("RaStore.switchSubscriber")
  return (
    <AppBar userMenu={<MyUserMenu />} color="primary" toolbar={<>
      <LoadingIndicator />
      <GoogleTranslate />
    </>
    }>

      <img src={DocuverusLogo} alt="Docuverus" />
      &nbsp;
      {!subscriberView && <strong> {switchSubscriberObj?.label}</strong>}
      <Box flex="1" />
      <Box flex="1" />
      
      {/* <Toolbar>
       
         
        {/* <div className="cus-search">
          <input
            // ref={ref}
            type="text"
            style={{ backgroundColor: '#fff', border: '1px solid #000', borderRadius: '4px' }}
            onChange={(e) => {
              let timeout;
              if (timeout) clearTimeout(timeout);
              timeout = setTimeout(() => {
                navigate("/Ats/Admin/getglobalsearch", {
                  state: { search: e.target.value },
                });
              }, 2000);
            }}
            placeholder="Global Search"
          />
        </div> */}

      {/* {!subscriberView && <SwitchSubscriber />} */}
    </AppBar>
  );
};

export const MyMenu = (props) => {
  const [open, setOpen] = React.useState(false);
  const [openSettngs, setOpenSettngs] = useStore('openSettings', false);
  const [openMgt, setopenMgt] = useStore('openMgt', false);

  const [openTerms, setOpenTerms] = useStore('openTermsCondtions', false);
  const [aopenMgt, setaopenMgt] = useStore('aopenMgt', false);
  const [openIntegrationSettings, setOpenIntegrationSettings] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const settingsonClickHandler = () => {
    setOpenSettngs(!openSettngs);
  };
  const mgtonClickHandler = () => {
    setopenMgt(!openMgt);
  };
  const integrationSettingsonClickHandler = () => {
    setOpenIntegrationSettings(!openIntegrationSettings);
  };
  const location = useLocation();
  const { data } = useGetIdentity();
  const [switchSubscriberObj, setSwitchSubscriberObj] = useStore('switchSubscriberName', { value: '', label: '', companyType: '' });
  // const [switchSubscriber, setSwitchSubscriber] = useStore('switchSubscriber');
  // const {permissions:switchSubscriber} = usePermissions();
  const switchSubscriber = localStorage.getItem("RaStore.switchSubscriber")
  const subscriberView: boolean = data?.subscriberView;

  const [create] = useCreate();
  const notify = useNotify();
  useEffect(() => {
    console.log(window.location.pathname.split("/")[1]);
    if (window.location.pathname.split("/")[1] == "ml") setOpen(true);
  }, [window.location]);
  //handled redirected back to the old system
  const onClickHandler = () => {
    create(
      "logout",
      {},
      {
        onSuccess: (res) => {
          localStorage?.removeItem("auth");
          window?.location?.replace(`${ATS_DOCS_URL}`);
        },
        onError: (error) => {
          notify("Error: Retrain process abort", {
            type: "warning",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        },
      }
    );
  };

  // get company type Data
  const { data: subscriberThirdPartyMenu } = useGetOne(
    "subscriber-third-party-menu",
    {
      id: switchSubscriberObj?.value,
    },
    {
      refetchOnWindowFocus: false,
      enabled: switchSubscriberObj?.value ? true : false,
    }
  );
  return (
    <Menu {...props}>
        <Menu.Item to="/landloard" primaryText="Inbox" leftIcon={<InboxIcon />} />
    </Menu>
  )
};

const MySidebar = (props) => (
  <Sidebar
    sx={{
      "& .RaSidebar-fixed": {
        backgroundColor: "#eeeeee",
      },
    }}
    {...props}
  />
);

const MyLayoutLandLoard = (props: any) => (
  <div>

    <Layout
      // sx={{'& .RaLayout-appFrame':{margin:0}}}
      sx={{ "& .RaLayout-appFrame": { marginTop: "50px" } }}
      {...props}
      appBar={MyAppBar}
      menu={MyMenu}
      sidebar={MySidebar}
    //   notification=""
    >

    </Layout>
    <footer
      style={{
        backgroundColor: "#f8f9fa",
        padding: "10px",
        position: "relative",
        bottom: "0",
        width: "100%",
        zIndex: "100", // You can adjust the z-index if needed
        display: "flex",
        justifyContent: "space-between", // Align content to the left and right edges
        alignItems: "center", // Vertically center content
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <p style={{ fontSize: "12px", margin: 0 }}>
          Need help completing your application? Docuverus can be reached by
          phone at <b>856.886.8843</b> or by email at{" "}
          <b>support@docuverus.com</b>
        </p>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <p
          style={{
            fontSize: "12px",
            margin: 0,
            marginRight: "20px",
            textAlign: "right",
          }}
        >
          © 2024 Docuverus (version{SOFTWARE_VERSION} )
        </p>
      </div>
    </footer>
  </div>
);

export default MyLayoutLandLoard;
