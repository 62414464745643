import { typesofUsers } from "../config";
import { ATS_API_URL, REACT_APPLICANT } from "../config";
export interface identity {
    tokendata: string;
    refreshtoken: string;
    userid: string;
    userrole: string;
    subscriberid?: null;
    url?: null;
    trn_id?: null;
    messages: string;
}


const authProviderLandLoard = {
    login: async (data) => {

        const request = new Request(ATS_API_URL + '/account/Login', {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        try {
            const response = await fetch(request);

            const auth = await response.json();
            if (response.status === 429) {
                alert("Too many login attempts from this IP, please try again after 10 min.")
                throw new Error("Too many login attempts from this IP, please try again after after 10 min.");
            }
            // if(response.status === 401){
            //     // throw new Error("MFA");
            //     sessionStorage.setItem("userName", data.username);
            //     return { redirectTo: "/atss/mfa" };
            // }
            if (response.status < 200 || response.status >= 400) {
                throw new Error(auth.message);
            }

            if (response.status == 301) {
                return { redirectTo: false, ...auth };
            }

            localStorage.removeItem('RaStore.switchSubscriber');
            localStorage.removeItem('RaStore.switchSubscriberName');

            const userTypes = {
                landloards: [typesofUsers?.landlordThirdPartyAgent],
                subscribers: [
                    typesofUsers?.subscriberUser,
                    typesofUsers?.subscriberAdmin,
                    typesofUsers?.agent,
                    typesofUsers?.manager,
                    typesofUsers?.admin,
                ],
                admins: [
                    typesofUsers?.docuverusSuperAdmin,
                    typesofUsers?.superAdmin,
                ],
                applicants: [
                    typesofUsers?.applicant,
                    typesofUsers?.coApplicant,
                    typesofUsers?.coSigner,
                ],
                thirdPartyAgent: [typesofUsers?.landlordThirdPartyAgent],
            };
            localStorage.setItem('orginalUserRole', JSON.stringify(auth?.data?.userrole));
            localStorage.setItem('userRole', JSON.stringify(auth?.data?.userrole));
            console.log(auth?.data, "auth?.data");
            const termConditionStatus = localStorage.getItem("TermAndConditionVefificationStatus");

            if (userTypes.subscribers.includes(auth?.data?.userrole)) {

                localStorage.setItem('auth', JSON.stringify({ ...auth, data: { ...auth.data, subscriberView: true } }));


                if (!auth?.data?.isPasswordChanged) {
                    return { redirectTo: "/Ats/TempForgotPassword" };
                }
                if (!auth?.data?.isTandCAccepted && termConditionStatus != "1") {
                    return { redirectTo: "/Ats/TermsAndConditionForUserSetup" };
                }
                else
                    return { redirectTo: "/Ats" };
            }
            if (userTypes.admins.includes(auth?.data?.userrole)) {
                localStorage.setItem('auth', JSON.stringify({ ...auth, data: { ...auth.data, subscriberView: false } }));


                if (!auth?.data?.isPasswordChanged) {
                    return { redirectTo: "/Ats/TempForgotPassword" };
                }
                else if (!auth?.data?.isTandCAccepted) {
                    return { redirectTo: "/Ats/TermsAndConditionForUserSetup" };
                }

                else
                    return { redirectTo: "/Ats" };
            }

            else if (userTypes.landloards.includes(auth?.data?.userrole)) {
                localStorage.setItem('auth', JSON.stringify({ ...auth, data: { ...auth.data, subscriberView: false, landLoadView: true } }));

                if (!auth?.data?.isPasswordChanged) {
                    return { redirectTo: "/Ats/TempForgotPassword" };
                }
                else if (!auth?.data?.isTandCAccepted) {
                    return { redirectTo: "/Landloard/TermsAndConditionForUserLandLoard" };
                }

                else
                    return { redirectTo: "/landloard/landLoardInbox" };
            }


            if (userTypes.applicants.includes(auth?.data?.userrole)) {
                window.open(
                    `${REACT_APPLICANT}/login?trn_id=${auth?.data?.trn_id}&url=${auth.data.url}&token=${auth?.data?.tokendata}`,
                    "_self"
                );
            }
            if (userTypes.thirdPartyAgent.includes(data?.data?.userrole)) {
                throw new Error('Please login to the old system.');
            }

            return { redirectTo: false };
        } catch (error: any) {
            throw new Error(error.message);
        }
    },
    logout: () => {
        localStorage.removeItem('auth');
        localStorage.removeItem('RaStore.switchSubscriber');
        localStorage.removeItem('RaStore.switchSubscriberName');
        localStorage.removeItem('orginalUserRole');
        localStorage.removeItem('userRole');
        return Promise.resolve();
    },
    checkAuth: () => {
        const auth: any = localStorage.getItem('auth');
        const data = !!auth ? JSON.parse(auth) : '';
        const isAuthenticated = data?.data?.userrole == typesofUsers.landlordThirdPartyAgent;
        if (!isAuthenticated) return Promise.reject();
        return localStorage.getItem('auth') ? Promise.resolve() : Promise.reject()
    },
    checkError: (error) => {
        if (error) {
            const status = error.status;
            if (status === 401 || status === 403) {
                localStorage.removeItem('auth');
                return Promise.reject();
            }
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    getIdentity: <identity>() => {
        const { data } = JSON.parse(<any>localStorage.getItem('auth'))
        return Promise.resolve({ id: data.userid, fullName: data.fullName || data.userrole, avatar: data.avatar || "https://ui-avatars.com/api/?color=000&name=" + (data.fullName || data.userrole), ...data })
    },
    getPermissions: () => {
        const userRole = JSON.parse(<any>localStorage.getItem('userRole'));
        const Permission = {
            'Docuverus Super Admin': ['global_search', 'switch_subscriber', 'view_internal_comments', 'add_internal_comments'],
            'Landlord Third Party Agent': ['apply_rental', 'view_app', '']
        }
        const PermissionAsPerRole = Permission[userRole];
        return Promise.resolve(PermissionAsPerRole)
    },
};
export default authProviderLandLoard;