import { Button, Empty, Layout, List, LoadingIndicator, Menu, Sidebar, TitlePortal, UserMenu, useCreate, useGetIdentity, useGetOne, useNotify, usePermissions, useStore } from "react-admin";
import { ReactQueryDevtools } from 'react-query/devtools'
import { AppBar } from 'react-admin';
import Box from '@mui/material/Box';
import BookIcon from '@mui/icons-material/Book';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import PeopleIcon from '@mui/icons-material/People';
import LabelIcon from '@mui/icons-material/Label';
import { BsBack, BsFillGearFill } from "react-icons/bs";
import EmailSettingsLogo from "../assets/images/mailLogo.png";
import Leadmgt from "../assets/images/Frame (2).png";
import Revenuemgt from "../assets/images/Frame.png";
import Docuploadmgt from "../assets/images/Frame (1).png";
import AgentMgt from "../assets/images/Frame (3).png"
import TermnsAndConditions from "../assets/images/termsConditions.png"

import userSetupLogo from "../assets/images/userSetupLogo.png";
import Loglogo from "../assets/images/Vector (1).png";
// import AdverseLogo from "../assets/images/Frame (6).png";
import ManageUserLogo from "../assets/images/ManageUser.png";
import {
  FaCircle,
  FaFileUpload,
  FaFlag,
  FaHistory,
  FaList,
  FaUpload,
} from "react-icons/fa";
import { BiArchive, BiArrowBack, BiExit, BiUpload } from "react-icons/bi";
import SwitchSubscriber from "./Ats/SwitchSubscriber";


import DocuverusLogo from "../assets/images/docuverus_logo.png";
import {
  Dashboard,
  DashboardCustomizeSharp,
  DashboardOutlined,
  Square,
} from "@mui/icons-material";
import { useParams } from "react-router";
import { JSONTree } from "react-json-tree";
import React, { useEffect } from "react";
import { ATS_DOCS_URL, SOFTWARE_VERSION, typesofUsers } from "../config";
import { ImUpload2 } from "react-icons/im";
import { RiFileUploadFill } from "react-icons/ri";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import { useLocation } from "react-router-dom";
import MuiList from "@mui/material/List";
import { IoMdSettings } from "react-icons/io";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
// import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { RiListSettingsLine } from "react-icons/ri";
import ArticleIcon from '@mui/icons-material/Article';
import DnsIcon from '@mui/icons-material/Dns';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { Button as MuiButton } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { styled, alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import GoogleTranslate from "./common/GoogleTranslate";
import { BsShieldFillCheck } from "react-icons/bs";
import { color } from "html2canvas/dist/types/css/types/color";
import AdverseLogo from "../assets/images/Frame (6).png";
import AdminNotificationLogo from "../assets/images/Frame (7).png";



declare global {
  interface Window {
      google: any;
  }
}




const MyUserMenu = () => {
  const [create, { isSuccess, data }] = useCreate();
  useEffect(() => {
    if (data) {
      localStorage.removeItem("auth");
      window.location.replace(
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.href.split(
          window.location.protocol + "//" + window.location.host
        )[1]
      );
    }
  }, [data]);

  return (
    <UserMenu>
      {/* <Button
            sx={{ width: 140 }}
            startIcon={<BiExit />}
            onClick={() => {
              create("logout");
            }}
            label="Logout"
          /> */}
      <MuiButton
        sx={{ width: 140 }}
        startIcon={<BiExit />}
        onClick={() => {
          create("logout");
        }}
        size="small"
      >
        Logout
      </MuiButton>
    </UserMenu>
  );
};

export const MyAppBar = () => {
  const navigate = useNavigate();
  const [switchSubscriberObj] = useStore("switchSubscriberName");
  const { data } = useGetIdentity();
  const subscriberView: boolean = data?.subscriberView;
  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }));

  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));
  const { permissions } = usePermissions();
  const switchSubscriberStatus = localStorage.getItem("RaStore.switchSubscriber")
  return (
    <AppBar userMenu={<MyUserMenu />} color="primary" toolbar={<>
      {(true && switchSubscriberStatus=='false') && <Search>
        {/* {(permissions?.includes("global_search") && switchSubscriberStatus=='false') && <Search> */}
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          onChange={(e) => {
            let timeout;
            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(() => {
              navigate("/Ats/getglobalsearch", {
                state: { search: e.target.value },
              });

            }, 3500);
          }}
          placeholder="Global Search"
          inputProps={{ 'aria-label': 'search' }}
        />
      </Search>}
     {true && <SwitchSubscriber />}
     {/* {permissions?.includes("switch_subscriber") && <SwitchSubscriber />} */}
      <LoadingIndicator />
      <GoogleTranslate />
    </>
    }>

      <img src={DocuverusLogo} alt="Docuverus" />
      &nbsp;
      {!subscriberView && <strong> {switchSubscriberObj?.label}</strong>}
      <Box flex="1" />
      <Box flex="1" />
      
      {/* <Toolbar>
       
         
        {/* <div className="cus-search">
          <input
            // ref={ref}
            type="text"
            style={{ backgroundColor: '#fff', border: '1px solid #000', borderRadius: '4px' }}
            onChange={(e) => {
              let timeout;
              if (timeout) clearTimeout(timeout);
              timeout = setTimeout(() => {
                navigate("/Ats/Admin/getglobalsearch", {
                  state: { search: e.target.value },
                });
              }, 2000);
            }}
            placeholder="Global Search"
          />
        </div> */}

      {/* {!subscriberView && <SwitchSubscriber />} */}
    </AppBar>
  );
};

export const MyMenu = (props) => {
  const [open, setOpen] = React.useState(false);
  const [openSettngs, setOpenSettngs] = useStore('openSettings', false);
  const [openMgt, setopenMgt] = useStore('openMgt', false);

  const [openTerms, setOpenTerms] = useStore('openTermsCondtions', false);
  const [aopenMgt, setaopenMgt] = useStore('aopenMgt', false);
  const [openIntegrationSettings, setOpenIntegrationSettings] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const settingsonClickHandler = () => {
    setOpenSettngs(!openSettngs);
  };
  const mgtonClickHandler = () => {
    setopenMgt(!openMgt);
  };
  const integrationSettingsonClickHandler = () => {
    setOpenIntegrationSettings(!openIntegrationSettings);
  };
  const location = useLocation();
  const { data } = useGetIdentity();
  const [switchSubscriberObj, setSwitchSubscriberObj] = useStore('switchSubscriberName', { value: '', label: '', companyType: '' });
  // const [switchSubscriber, setSwitchSubscriber] = useStore('switchSubscriber');
  // const {permissions:switchSubscriber} = usePermissions();
  const switchSubscriber = localStorage.getItem("RaStore.switchSubscriber")
  const subscriberView: boolean = data?.subscriberView;

  const [create] = useCreate();
  const notify = useNotify();
  useEffect(() => {
    console.log(window.location.pathname.split("/")[1]);
    if (window.location.pathname.split("/")[1] == "ml") setOpen(true);
  }, [window.location]);
  //handled redirected back to the old system
  const onClickHandler = () => {
    create(
      "logout",
      {},
      {
        onSuccess: (res) => {
          localStorage?.removeItem("auth");
          window?.location?.replace(`${ATS_DOCS_URL}`);
        },
        onError: (error) => {
          notify("Error: Retrain process abort", {
            type: "warning",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        },
      }
    );
  };

  // get company type Data
  const { data: subscriberThirdPartyMenu } = useGetOne(
    "subscriber-third-party-menu",
    {
      id: switchSubscriberObj?.value,
    },
    {
      refetchOnWindowFocus: false,
      enabled: switchSubscriberObj?.value ? true : false,
    }
  );
  return (
    <Menu {...props}>
      {!(switchSubscriber === "true") ? (location.pathname.split('/')[1] == 'ml') ? <Menu.Item to={'/ats'} primaryText="Dashboard" leftIcon={<Dashboard />} /> : <Menu.DashboardItem /> : null}
      {(switchSubscriber === "true" && subscriberView) ? (location.pathname.split('/')[1] == 'ml') ? <Menu.Item to={'/ats'} primaryText="Dashboard" leftIcon={<Dashboard />} /> : <Menu.DashboardItem /> : null}
      {(switchSubscriber === "true" && !subscriberView) && <Menu.Item to={'/ats/subscriber/inbox/' + switchSubscriberObj?.value} primaryText="Dashboard" leftIcon={<Dashboard />} />}
      {(subscriberView || (switchSubscriber === "true")) && <Menu.Item to={"/Ats/subscriber/getarchivedcancelled/" + (switchSubscriberObj?.value || data?.subscriberid)} primaryText="Archived / Cancelled" leftIcon={<BiArchive />} />}
      {(!subscriberView && !(switchSubscriber === "true")) && <Menu.Item to="/Ats/SubscriberUsageList" primaryText="Subscriber Usage List" leftIcon={<PeopleIcon />} />}
      {(!subscriberView && !(switchSubscriber === "true")) && <Menu.Item to="/Ats/getloginactivitylogs" primaryText="Log report " leftIcon={<BookIcon />} />}

      {(!subscriberView && !(switchSubscriber === "true")) && <Menu.Item to="/Ats/getEmailSettings" primaryText="Email Settings " leftIcon={ <img src={EmailSettingsLogo} style={{color:"gray"}} alt="email settings" />} />}
      {/* {(!subscriberView && !(switchSubscriber === "true")) && <Menu.Item to="/ats/termsandconditions" primaryText="Terms and Conditions" leftIcon={<BsShieldFillCheck />} />} */}
      
      {!(switchSubscriber === "true") && data?.userrole == "Docuverus Super Admin" && <MuiList dense disablePadding>
        <Menu.Item to="#" onClick={(e) => { e.preventDefault(); setOpenTerms(!openTerms)}} primaryText="Terms and Conditions" style={{ fontSize: '13.7px' }} leftIcon={openTerms ? <ExpandLess /> : <ExpandMore />} />
        <Collapse sx={{ background: "#2cc0e414" }} in={openTerms} timeout="auto" unmountOnExit>
          <MuiList component="div" disablePadding sx={{ marginLeft: "16px" }}>
          <Menu.Item to="/ats/termsandconditions_applicant" primaryText="Applicant" leftIcon={<img src={TermnsAndConditions}  style={{ fontSize: 11 }} />} style={{ fontSize: '10.7px' }}/>
          <Menu.Item to="/ats/termsandconditions_subscriber" primaryText="Subscriber User" leftIcon={<img src={TermnsAndConditions}  style={{ fontSize: 11 }} />} style={{ fontSize: '10.7px' }}/>
          <Menu.Item to="/ats/termsandconditions_landloard" primaryText="Landloard/Third Party" leftIcon={<img src={TermnsAndConditions}  style={{ fontSize: 11 }} />} style={{ fontSize: '10.7px' }}/>
          <Menu.Item to="/ats/termsandconditions_privacy" primaryText="Data Privacy" leftIcon={<img src={TermnsAndConditions}  style={{ fontSize: 11 }} />} style={{ fontSize: '10.7px' }}/>
          <Menu.Item to="/ats/termsandconditions_termsofuse" primaryText="Terms of Use" leftIcon={<img src={TermnsAndConditions}  style={{ fontSize: 11 }} />} style={{ fontSize: '10.7px' }}/>
            {/* <Menu.Item to="/ml/predictps" primaryText="Predict Paystub" leftIcon={<RiFileUploadFill size={11} />} style={{ fontSize: '10.7px' }} />
            <Menu.Item to="/ml/predictbs" primaryText="Predict Bank" leftIcon={<FaFileUpload size={11} />} style={{ fontSize: '10.7px' }} />
            <Menu.Item to="/ml/mlclassdetails" primaryText="List & Retrain" leftIcon={<FaList size={11} />} style={{ fontSize: '10.7px' }} />
            <Menu.Item to="/ml/getclassretrainlogs" primaryText="Retrain Logs" leftIcon={<FaHistory size={11} />} style={{ fontSize: '10.7px' }} />
            <Menu.Item to="/ml/getFlaggedDocuments" primaryText="Flagged Documents" leftIcon={<FaFlag size={11} />} style={{ fontSize: '10.7px' }} />
            <Menu.Item to="/ml/getFlaggedSOCRData" primaryText="Flagged SOCR Data" leftIcon={<FaFlag size={11} />} style={{ fontSize: '10.7px' }} /> */}
          </MuiList>
        </Collapse>
      </MuiList>}
      {!(switchSubscriber === "true") && data?.userrole == "Docuverus Super Admin" && <MuiList dense disablePadding>
        <Menu.Item to="#" onClick={(e) => { e.preventDefault(); setaopenMgt(!aopenMgt)}} primaryText="Management Reports" style={{ fontSize: '13.7px' }} leftIcon={aopenMgt ? <ExpandLess /> : <ExpandMore />} />
        <Collapse sx={{ background: "#2cc0e414" }} in={aopenMgt} timeout="auto" unmountOnExit>
          <MuiList component="div" disablePadding sx={{ marginLeft: "16px" }}>
          <Menu.Item to="/ats/adminrevenue" primaryText="Admin Revenue" leftIcon={<img src={AgentMgt}  style={{ fontSize: 11 }} />} style={{ fontSize: '10.7px' }}  />
          <Menu.Item to="/ats/adminscreen" primaryText="Screening Revenue" leftIcon={<img src={AgentMgt}  style={{ fontSize: 11 }} />} style={{ fontSize: '10.7px' }}  />
          <Menu.Item to="/ats/adminlead" primaryText="Lead Management" leftIcon={<img src={AgentMgt}  style={{ fontSize: 11 }} />} style={{ fontSize: '10.7px' }} />
          <Menu.Item to="/ats/adminverification" primaryText="Verification Report" leftIcon={<img src={AgentMgt}  style={{ fontSize: 11 }} />} style={{ fontSize: '10.7px' }}  />
          {/* <Menu.Item to="/ats/termsandconditions_termsofuse" primaryText="Terms of Use" leftIcon={<BsShieldFillCheck />} /> */}
            {/* <Menu.Item to="/ml/predictps" primaryText="Predict Paystub" leftIcon={<RiFileUploadFill size={11} />} style={{ fontSize: '10.7px' }} />
            <Menu.Item to="/ml/predictbs" primaryText="Predict Bank" leftIcon={<FaFileUpload size={11} />} style={{ fontSize: '10.7px' }} />
            <Menu.Item to="/ml/mlclassdetails" primaryText="List & Retrain" leftIcon={<FaList size={11} />} style={{ fontSize: '10.7px' }} />
            <Menu.Item to="/ml/getclassretrainlogs" primaryText="Retrain Logs" leftIcon={<FaHistory size={11} />} style={{ fontSize: '10.7px' }} />
            <Menu.Item to="/ml/getFlaggedDocuments" primaryText="Flagged Documents" leftIcon={<FaFlag size={11} />} style={{ fontSize: '10.7px' }} />
            <Menu.Item to="/ml/getFlaggedSOCRData" primaryText="Flagged SOCR Data" leftIcon={<FaFlag size={11} />} style={{ fontSize: '10.7px' }} /> */}
          </MuiList>
        </Collapse>
      </MuiList>}
      {!(switchSubscriber === "true") && data?.userrole == "Docuverus Super Admin" && <MuiList dense disablePadding>
        <Menu.Item to={'/ml'} onClick={(e) => { e.preventDefault(); handleClick() }} primaryText="ML Prediction" style={{ fontSize: '13.7px' }} leftIcon={open ? <ExpandLess /> : <ExpandMore />} />
        {/* <ListItemButton onClick={handleClick}>
                    <ListItemIcon sx={{ minWidth: 40 }}>
                        <BsFillGearFill />
                    </ListItemIcon>
                    <ListItemText primary="ML Prediction" primaryTypographyProps={{ style: { fontSize: '13.7px' } }}/>
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton> */}
        <Collapse sx={{ background: "#2cc0e414" }} in={open} timeout="auto" unmountOnExit>
          <MuiList component="div" disablePadding sx={{ marginLeft: "16px" }}>
            <Menu.Item to="/ml/predictps" primaryText="Predict Paystub" leftIcon={<RiFileUploadFill size={11} />} style={{ fontSize: '10.7px' }} />
            <Menu.Item to="/ml/predictbs" primaryText="Predict Bank" leftIcon={<FaFileUpload size={11} />} style={{ fontSize: '10.7px' }} />
            <Menu.Item to="/ml/mlclassdetails" primaryText="List & Retrain" leftIcon={<FaList size={11} />} style={{ fontSize: '10.7px' }} />
            <Menu.Item to="/ml/getclassretrainlogs" primaryText="Retrain Logs" leftIcon={<FaHistory size={11} />} style={{ fontSize: '10.7px' }} />
            <Menu.Item to="/ml/getFlaggedDocuments" primaryText="Flagged Documents" leftIcon={<FaFlag size={11} />} style={{ fontSize: '10.7px' }} />
            <Menu.Item to="/ml/getFlaggedSOCRData" primaryText="Flagged SOCR Data" leftIcon={<FaFlag size={11} />} style={{ fontSize: '10.7px' }} />
          </MuiList>
        </Collapse>
      </MuiList>}
     
      {((subscriberView || (switchSubscriber === "true")) && (data?.userrole !== "Agent" && data?.userrole !== "Manager")) && <MuiList dense disablePadding>
        <Menu.Item to={'/ats/settings'} onClick={(e) => { e.preventDefault(); settingsonClickHandler() }} primaryText="Settings" style={{ fontSize: '13.7px' }} leftIcon={openSettngs ? <ExpandLess /> : <ExpandMore />} />
        <Collapse sx={{ background: "#2cc0e414" }} in={openSettngs} timeout="auto" unmountOnExit>
          <MuiList component="div" disablePadding sx={{ marginLeft: "10px" }}>
          {/* {data?.userrole === "Docuverus Super Admin" && */}
              <Menu.Item to={"/ats/settings/emailSettings"} primaryText="Email Settings" leftIcon={<MailOutlineIcon sx={{ fontSize: 11 }} />} style={{ fontSize: '10.7px' }} />
            {/* } */}
             {/* {data?.userrole === "Docuverus Super Admin" && */}
               {/* {(!subscriberView && !(switchSubscriber === "true")) && <Menu.Item to="/Ats/getEmailSettings" primaryText="Email Settings " leftIcon={ <img src={EmailSettingsLogo} style={{color:"gray"}} alt="email settings" />} />} */}
              <Menu.Item to={"/ats/settings/usersetup"} primaryText="User Setup" leftIcon={<img src={ManageUserLogo} style={{ color: "gray" }} alt="email settings" />} style={{ fontSize: '10.7px' }} />
            {/* } */}
            {/* {data?.userrole === "Docuverus Super Admin" && */}
              <Menu.Item to={"/ats/settings/hierarchyLevel"} primaryText="Hierarchy Level" leftIcon={<AccountTreeIcon sx={{ fontSize: 11 }} />} style={{ fontSize: '10.7px' }} />
            {/* } */}
            
             {/* {data?.userrole === "Docuverus Super Admin" && */}
              <Menu.Item to={"/ats/settings/getadverseletter"} primaryText="Adverse Letter" leftIcon={<img src={AdverseLogo} style={{ color: "gray" }} alt="email settings" />} style={{ fontSize: '10.7px' }}/>
            {/* } */}
             {/* {data?.userrole === "Docuverus Super Admin" && */}
              <Menu.Item to={"/ats/settings/getconfidentialitylogs"} primaryText="Confidentiality Log" leftIcon={<img src={Loglogo} style={{ color: "gray" }} alt="email settings" />} style={{ fontSize: '10.7px' }}/>
            {/* } */}
            <Menu.Item style={{ fontSize: "10.7px" }} to="/ats/settings/termsandconditions" primaryText="Terms and Conditions" leftIcon={<BsShieldFillCheck />} />
            {switchSubscriberObj?.companyType === "Property Management" && (
              <Menu.Item
                to={"/ats/settings/propertySetup"}
                primaryText="Property Setup"
                leftIcon={<MapsHomeWorkIcon sx={{ fontSize: 11 }} />}
                style={{ fontSize: "10.7px" }}
              />
            )}
            {switchSubscriberObj?.companyType === "Real Estate" && (
              <Menu.Item
                to={"/ats/settings/officeSetup"}
                primaryText="Office Setup"
                leftIcon={<MapsHomeWorkIcon sx={{ fontSize: 11 }} />}
                style={{ fontSize: "10.7px" }}
              />
            )}
            {/* {switchSubscriberObj?.companyType === "Real Estate" && (
              <Menu.Item
                to={"/settings/getleadmanagementreport"}
                primaryText="Lead Management"
                leftIcon={<MapsHomeWorkIcon sx={{ fontSize: 11 }} />}
                style={{ fontSize: "10.7px" }}
              />
            )} */}
            {subscriberThirdPartyMenu?.data &&
              subscriberThirdPartyMenu?.data?.length > 0 && (
                <>
                  <Menu.Item to={'/ats/settings/integrationSettings'} onClick={(e) => { e.preventDefault(); integrationSettingsonClickHandler() }} primaryText="Integration Settings" style={{ fontSize: '13.7px' }} leftIcon={openIntegrationSettings ? <ExpandLess /> : <ExpandMore />} />
                  <Collapse
                    sx={{ background: "#2cc0e414" }}
                    in={openIntegrationSettings}
                    timeout="auto"
                    unmountOnExit
                  >
                    <MuiList component="div" disablePadding sx={{ marginLeft: "10px" }}>
                      {subscriberThirdPartyMenu?.data &&
                        subscriberThirdPartyMenu?.data?.length > 0 &&
                        subscriberThirdPartyMenu?.data?.map((menus) => (
                          <Menu.Item
                            to={`/ats/settings/integrationSettings/${menus?.appName}`}
                            primaryText={menus?.appName}
                            leftIcon={<DnsIcon sx={{ fontSize: 11 }} />}
                            style={{ fontSize: "10.7px" }}
                            key={menus?.appID}
                          />
                        ))}
                    </MuiList>
                  </Collapse>
                </>
              )}
          </MuiList>
        </Collapse>
      </MuiList>}
       {((subscriberView || (switchSubscriber === "true")) 
      //  && (data?.userrole !== "Agent" && data?.userrole !== "Manager")
      ) && 
       <MuiList dense disablePadding>
      <Menu.Item to={'/ats/managementreport'} onClick={(e) => { e.preventDefault(); mgtonClickHandler() }} primaryText="Management Report " style={{ fontSize: '13.7px' }} leftIcon={openMgt ? <ExpandLess /> : <ExpandMore />} />
      <Collapse sx={{ background: "#2cc0e414" }} in={openMgt} timeout="auto" unmountOnExit>
      <MuiList component="div" disablePadding sx={{ marginLeft: "10px" }}>
      {/* {data?.userrole === "Docuverus Super Admin" && */}
              <Menu.Item to={"/ats/managementreport/getleadmanagementreport"} primaryText="Lead Management" leftIcon={<img src={Leadmgt} style={{ fontSize: 11 }} />} style={{ fontSize: '10.7px' }} />
            {/* } */}
            {/* {data?.userrole === "Docuverus Super Admin" && */}
              <Menu.Item to={"/ats/managementreport/getrevenuemanagementreport"} primaryText="Revenue Management" leftIcon={<img src={Revenuemgt}  style={{ fontSize: 11 }} />} style={{ fontSize: '10.7px' }} />
            {/* } */}
              {/* {data?.userrole === "Docuverus Super Admin" && */}
              <Menu.Item to={"/ats/managementreport/getdocuploadmanagementreport"} primaryText="Docupload Management" leftIcon={<img src={Docuploadmgt}  style={{ fontSize: 11 }} />} style={{ fontSize: '10.7px' }} />
            {/* } */}
      </MuiList>
      </Collapse>
      </MuiList>}
      {/* <Menu.Item to={"/ats/emailSettings"} primaryText="Settings" leftIcon={<IoMdSettings />} /> */}
      {/* <Menu.Item to={"/ats/emailSettings"} primaryText="Email Settings" leftIcon={<MailOutlineIcon />} /> */}
      {(!subscriberView && !(switchSubscriber === "true")) && <Menu.Item to={"/ats/manageSubscribers"} primaryText="Manage Subscribers" leftIcon={<RiListSettingsLine />} />}
      {(!subscriberView && !(switchSubscriber === "true")) && <Menu.Item to={"/ats/manageUsers"} primaryText="Manage Users" leftIcon={ <img src={ManageUserLogo} style={{color:"gray"}} alt="Manage Users" />} />}
      {(!subscriberView && !(switchSubscriber === "true")) && <Menu.Item to={"/ats/adminnotification"} primaryText="System Notifications" leftIcon={ <img src={AdminNotificationLogo} style={{color:"gray"}} alt="Manage Users" />} />}
      <Menu.Item to={"#"} onClick={() => onClickHandler()} primaryText="Back to Old system" leftIcon={<BiArrowBack />} />
    </Menu>
  )
};

const MySidebar = (props) => (
  <Sidebar
    sx={{
      "& .RaSidebar-fixed": {
        backgroundColor: "#eeeeee",
      },
    }}
    {...props}
  />
);

const MyLayout = (props: any) => (
  <div>

    <Layout
      // sx={{'& .RaLayout-appFrame':{margin:0}}}
      sx={{ "& .RaLayout-appFrame": { marginTop: "50px" } }}
      {...props}
      appBar={MyAppBar}
      menu={MyMenu}
      sidebar={MySidebar}
    //   notification=""
    >

    </Layout>
    <footer
      style={{
        backgroundColor: "#f8f9fa",
        padding: "10px",
        position: "relative",
        bottom: "0",
        width: "100%",
        zIndex: "100", // You can adjust the z-index if needed
        display: "flex",
        justifyContent: "space-between", // Align content to the left and right edges
        alignItems: "center", // Vertically center content
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <p style={{ fontSize: "12px", margin: 0 }}>
          Need help completing your application? Docuverus can be reached by
          phone at <b>856.886.8843</b> or by email at{" "}
          <b>support@docuverus.com</b>
        </p>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <p
          style={{
            fontSize: "12px",
            margin: 0,
            marginRight: "20px",
            textAlign: "right",
          }}
        >
          © 2024 Docuverus (version{SOFTWARE_VERSION} )
        </p>
      </div>
    </footer>
  </div>
);

export default MyLayout;
