import { JSONTree } from "react-json-tree";
import { RootState } from "../../../Store/store";
import { useSelector } from "react-redux";
import { useGetList, useGetOne } from "react-admin";
import Dialog from "@mui/material/Dialog";
import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import DialogContent from "@mui/material/DialogContent";
import ResendIcon from "../../assets/images/resend.svg";
import CircularProgress, {
    CircularProgressProps,
} from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { Box, Button as MuiButton, Avatar } from "@mui/material";
import {
    useCreate,
    Resource,
    useNotify,
    useRecordContext,
    useStore,
    useRefresh,
    useGetIdentity,
} from "react-admin";
import { FiEye } from "react-icons/fi";
import { RiSaveLine } from "react-icons/ri";
import CheckIcon from "@mui/icons-material/Check";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import { FiMoreVertical, FiX } from "react-icons/fi";
import DialogActions from "@mui/material/DialogActions";
import { useNavigate, useParams } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { TextField, Button, FormLabel } from "@mui/material";
import { RiDeleteBinLine } from "react-icons/ri";
import { FaRegNoteSticky } from "react-icons/fa6";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup"; // Import yupResolver
import InputFieldButtonGroup from "../../common/InputComponents/InputFieldButtonGroup";
import InputSelectField from "../../common/InputComponents/InputSelectField";
import { Add } from "@mui/icons-material";


const ApplyRental = (props) => {
    const trn_id = useSelector((state: RootState) => state.users.userTrnId);
    const [openRentalDecision, setOpenRentalDecision] = useState(false);
    const [buttonIsDisabled, setButtonIsDisabled] = useState(false);
     const refresh=useRefresh();
    const handleCloseRentalDecision = () => {
        setOpenRentalDecision(false);
    };
    const validationSchema = Yup.object().shape({
        adverseAction: Yup.object()
            .shape({
                value: Yup.string().required("Required!").nullable(),
                label: Yup.string().nullable(),
            })
            .nullable()
            .required("Required!"),
        applicantName: Yup.array()
            .when("adverseAction", {
                is: (item) => (item?.value == "1" ? true : false),
                then: Yup.array().required("Required!"),
                otherwise: Yup.array().nullable(),

            }),
        LandlordStatus: Yup.object().required(),
    });
    const methods = useForm({
        resolver: yupResolver(validationSchema), // Pass validationSchema to useForm
    });
    const notify = useNotify();
    const [create] = useCreate()
    const adverseActionOptions = [
        { label: "Email", value: "1" },
        { label: "Download", value: "2" },
        { label: "Print", value: "3" },
    ];
    // const {
    //     data: applicantDatasub,
    //     isLoading: loading,
    //     refetch,
    // } = useGetOne("getApplicantDetails", { id: trn_id }, { enabled: !!trn_id });
    const {
        data: applicationData,
        isLoading,
        error,
    } = useGetOne(
        "getapplicationinboxdetails",
        {
            id: props?.data?.id,
        },
        { enabled: !!props?.data?.id }
    );

    const { data: applicantData } = useGetList(
        "get_subscriber_inbox_detials",
        {
            filter: { ApplicationNumber: props?.data?.id, subscriberid: props.data.SubscriberID },
        }
    );

    const funApprovedWithConditions = (formValues) => {
        setButtonIsDisabled(true);
        console.log(formValues, "win34");
        const formData = formValues?.LandlordStatus;

        create(
            "approvedWithConditions",
            {
                data: {
                    ...formData,
                    ApplicationNumber: Number(applicationData?.id),
                    subscriberid: props?.data?.SubscriberID,

                    IsPDF:
                        formValues?.adverseAction?.value == 2
                            || formValues?.adverseAction?.value == 3
                            ? true
                            : false,
                    IsPrint: formValues?.adverseAction?.value == 3 ? true : false,
                    IsEmail: formValues?.adverseAction?.value == 1 ? true : false,
                    ApplicantIDs:
                        formValues?.applicantName?.length
                            ? formValues.applicantName.map(item => Number(item.value))
                            : [Number(applicantData?.[0]?.id)],
                },
            },
            {
                onSuccess: async (data) => {
                    let message = "";
                    if (data.IsEmail) {
                        message = `Mail sent succesfully`;
                    } else if (data.IsPrint) {
                        message = `PDF successfully opened in new tab`;
                    } else if (data.IsPDF) {
                        message = `PDF downloaded successfully`;
                    } else {
                        message = "";
                    }

                    notify(message, {
                        type: "success",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                    });
                    refresh();
                    methods?.reset();
                    handleCloseRentalDecision();
                    setButtonIsDisabled(false);
                },

                onError: (e: any) =>
                    notify(`Operation fail:${e.message}`, {
                        type: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                    }),
            }
        );
    };
    const applicantNameOptions = applicantData?.map((item) => ({
        label: item?.FirstName,
        value: item?.id,
      }));
      const handleOpen=()=>{
        setOpenRentalDecision(true)
      }
    return <>
        <MuiButton onClick={handleOpen} startIcon={ <FaRegNoteSticky style={{ fontSize: "14px" }} />} variant='text' sx={{textTransform:"capitalize"}}>{props.edit==true?'Change':'Apply'} Rental Decision</MuiButton>
        {/* <JSONTree data={props} />
        <JSONTree data={applicationData} /> */}
        {/* Apply Rental Decision */}
        <Dialog
            open={openRentalDecision}
            fullWidth
            sx={{ "& .MuiDialog-paperFullWidth ": { maxWidth: "800px" } }}
        >
            <DialogTitle
                sx={{ m: 0, p: 1, backgroundColor: "Gainsboro" }}
                id="customized-dialog-title"
            >
                Apply Rental Decision
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleCloseRentalDecision}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent style={{ width: "800px", height: "400px" }}>
                <FormProvider {...methods}>
                    <form
                        id="checkboxForm"
                        onSubmit={(event) => {
                            event.preventDefault(); // Prevent default form submission
                            methods.handleSubmit(funApprovedWithConditions)(event);
                        }}
                    >
                        <div>
                            <InputFieldButtonGroup source="LandlordStatus" />
                            <div className="row" style={{ marginTop: "10px" }}>
                                <div className="col-sm-12 col-md-4 col-lg-4 mr-b10">
                                    <InputSelectField
                                        label="How to send adverse action"
                                        stopAutoFocus={true}
                                        source={"adverseAction"}
                                        options={adverseActionOptions}
                                        disable={
                                            methods.watch("LandlordStatus")?.LandlordStatus ==
                                            "Approved"
                                        }
                                        labelAsteriskClass={"color-red"}
                                        labelClass={"lable-title"}
                                        requiredClass={{
                                            fontSize: "70%",
                                        }}
                                    />
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4 mr-b10">
                                    {methods.watch("adverseAction")?.value === "1" && <InputSelectField
                                        multiple={true}
                                        label="Whom to send adverse action Notice"
                                        source={"applicantName"}
                                        stopAutoFocus={true}
                                        options={applicantNameOptions}
                                        labelAsteriskClass={"color-red"}
                                        labelClass={"lable-title"}
                                        requiredClass={{
                                            fontSize: "70%",
                                        }}
                                    />}
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12">
                            <div className=" d-flex align-items-center justify-content-end mr-t70">
                                <button
                                    type="button"
                                    className="blue-btn px-4"
                                    style={{
                                        height: "40px",
                                    }}
                                    onClick={handleCloseRentalDecision}
                                >
                                    {" "}
                                    <svg
                                        className="mr-r10"
                                        width="10"
                                        height="9"
                                        viewBox="0 0 10 9"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M5 3.55732L8.3 0.257324L9.24267 1.19999L5.94267 4.49999L9.24267 7.79999L8.3 8.74266L5 5.44266L1.7 8.74266L0.757334 7.79999L4.05733 4.49999L0.757334 1.19999L1.7 0.257324L5 3.55732Z"
                                            fill="white"
                                        />
                                    </svg>
                                    &nbsp;Cancel
                                </button>
                                <button
                                    type="submit"
                                    disabled={buttonIsDisabled}
                                    className="blue-btn px-4 mx-2"
                                    style={{
                                        height: "40px",
                                    }}
                                >
                                    {" "}
                                    <svg
                                        className="mr-r10"
                                        width="12"
                                        height="9"
                                        viewBox="0 0 12 9"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M4.66666 6.61463L10.7947 0.485962L11.738 1.42863L4.66666 8.49996L0.423996 4.2573L1.36666 3.31463L4.66666 6.61463Z"
                                            fill="white"
                                        />
                                    </svg>
                                    &nbsp;Submit
                                </button>
                            </div>
                        </div>
                    </form>
                </FormProvider>
            </DialogContent>
        </Dialog>
    </>
}



export default ApplyRental;