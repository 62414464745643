import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/DocuVerusLogoorg.png";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import InputField2 from "../../componets/common/InputComponents/InputField2";
import { CircularProgress } from "@mui/material";
import { useNotify, useRefresh, useCreate } from "react-admin";
import { SOFTWARE_VERSION } from "../../config";

const ForgotUsername = () => {
    const notify = useNotify();
    const refresh = useRefresh();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");
    const [create, { isLoading, error }] = useCreate();


    const schema = yup.object().shape({
        email: yup
            .string()
            .typeError("Email is required!")
            .required("Email is required!")
    });

    const defaultFormValues = {
        email: "",
    };


    const methods = useForm({
        defaultValues: defaultFormValues,
        resolver: yupResolver(schema),
        mode: "onChange",
    });


    useEffect(() => {
        document.title = "Docuverus - Get Username";
    }, []);


    const onSubmit = (data) => {
        const sendData = {
            email: data?.email,
        };

        create(
            "forgotUsername",
            { data: sendData },
            {
                onSuccess: (data) => {

                    notify(`${data?.message}`, {
                        type: "success",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                    });

                    methods?.reset();

                },
                onError: (e: any) =>
                    notify(`Operation fail:${e.message}`, {
                        type: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                    }),
            }
        );
    };

    return (
        <section className="login-wrap">
            <div className="login-cell">
                <div className="login-block">
                    <div className="l-logo-block text-center">
                        <img src={Logo} alt="Docuverus" title="Docuverus" />
                    </div>
                    <br />
        
                    <div className="auth-form mr-t30">
                        <FormProvider {...methods}>
                            <form onSubmit={methods.handleSubmit(onSubmit)}>
                                <div className="form-group cus-input-group mr-b20">
                                    <InputField2
                                        label={"Email"}
                                        source={"email"}
                                        placeholder={"Enter Email Here"}
                                        labelClass={"lable-title"}
                                        additionalInputClass={"cus-control"}
                                    />
                                </div>
                                {isLoading ? (
                                    <div className="static-d-info d-flex justify-content-center align-items-center mw-100">
                                        <CircularProgress size="20px" />
                                        <h5>&nbsp; &nbsp; Please wait...</h5>
                                    </div>
                                ) : (
                                    <div className="d-flex justify-content-center align-items-center">
                                        <button type="submit" className="l-btn-forgetPsd">
                                            Get Username
                                        </button>
                                    </div>
                                )}
                            </form>
                            <br />
                            <div className="w-full p-t-3 p-b-5 d-flex justify-content-end">
                                <Link to="/" className="txt1" style={{ color: "5555FF" }}>
                                    Back to Login
                                </Link>
                            </div>
                        </FormProvider>
                    </div>
                    <div className="l-foot-links mr-t20">
                        <ul className="mx-auto">
                            <li></li>
                            <li></li>
                            <li>Version {SOFTWARE_VERSION}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ForgotUsername;