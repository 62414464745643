import { format } from "date-fns"
import { BooleanField, Datagrid, DateField, FunctionField, List, ListBase, ListGuesser, ListToolbar, NumberField, Pagination, Resource, TextField, useListContext, useNotify, useStore } from "react-admin"
import FilterChips from "../../../Ats/SuperAdminInbox/FilterChips";
import {
    Grid,
    Typography,
    TextField as MuiTextField,
    Box,
    Link,
    ToggleButton, 
    ToggleButtonGroup,
    Checkbox,
    Stack,
    Button as MuiButton
  } from "@mui/material";
  
  import PDF from "./../../../../assets/images/Frame (5).png";
  import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
  import InputAdornment from "@mui/material/InputAdornment";
  import SearchIcon from "@mui/icons-material/Search";
  import IconButton from "@mui/material/IconButton";
import { ATS_API_URL } from "../../../../config";
import { debounce } from "lodash";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import FilterModal from "../../../manageSubscribers/FilterModal";
import AdverseFilter from "./AdverseFilter";
import React from "react";
export const AdverseLetterResource = () => {
    return <Resource name="getadverseletter" list={AdverseLetter} />
}
const bgStyle = (condition: string) => {
    let style = {};
    switch (condition) {
        case "CompletedConditionallyApproved":
            style = { backgroundColor: "#FFF8E2", color: "#F9BB04" };
            break;
        case "NA":
            style = { backgroundColor: "#E9E9E9", color: "#909090" };
            break;
        case "Completed-Unacceptable":
            style = { backgroundColor: "#F8ECE2", color: "#FF4848" };
            break;
        case "Completed-Approved":
            style = { backgroundColor: "#E6FDF0", color: "#46A96F" };
            break;
        case "Invited":
            style = { backgroundColor: "#E9E9E9", color: "#909090" };
            break;
        case " In Progress":
            style = { backgroundColor: "#DDF8FF", color: "#1CA2C2" };
            break;
        case "Submitted":
        case "Review Pending":
            style = { backgroundColor: "#B0EFFF", color: "#127790" };
            break;
        // case "Screening":
        case "Completed":
        case "Valid":
            case "Active":
        // case "Completed-Approved":
        case "Verified":
            style = { backgroundColor: "#E6FDF0", color: "#46A96F" };
            break;
        case "Cancelled":
        case "Archived":
        // case "Completed-Unacceptable":
        case "Invalid":
            case "Inactive":
        case "Unverifiable":
            style = { backgroundColor: "#FFEEEE", color: "#FF4848" };
            break;
        case "Completed-Conditionally Approved":
        case "CompletedConditionallyApproved":
        case "Further Documentation Required":
        case "Verification Required":
        case "Further Doc Req.":
            style = { backgroundColor: "#FFF8E2", color: "#F9BB04" };
            break;
        default:
            style = {};
    }
    return style;
};
const MyListToolBar = () => {
    const { displayedFilters, filterValues, setFilters, hideFilter, sort } =
      useListContext();
      const [searchValue, setSearchValue] = useState(filterValues.searchcode || "");
    const [applicantType,setApplicantType] = useState("All")
    const navigate = useNavigate();
    const [q, setQ] = React.useState<null | string>(filterValues?.q);
    const [switchSubscriberObj] = useStore("switchSubscriberName");
    // Debounce the search function
    const debouncedSearch = debounce((value) => {
      // Perform your search logic here
      setFilters({ ...filterValues, searchcode: value }, []);
    }, 500); // 500ms debounce delay
  
    const handleSearchChange = (event) => {
      const value = event.target.value;
      debouncedSearch(value);
    };
  const notify=useNotify();
    const handleExportData = () => {
      const tokenString = localStorage.getItem('auth');
      let tokenSecret = '';
  
      if (tokenString) {
          try {
              const token = JSON.parse(tokenString);
              tokenSecret = token?.data?.tokendata || '';
          } catch (error) {
              console.error('Error parsing token:', error);
          }
      }
  
      const currentTimestamp = Date.now();
  
      // Construct the URL for exporting Adverse Letter
      const url = `${ATS_API_URL}/subscriber/exportadverseletter?subscriberid=${
          switchSubscriberObj?.value
      }&_sort=${sort?.field || ""}&_order=${sort?.order || ""}`;
  
      fetch(url, {
          method: 'GET',
          headers: {
              'Authorization': `${tokenSecret}`
          }
      }).then(response => {
          if (!response.ok) {
              throw new Error('Failed to export the file');
          }
          return response.blob();
      }).then(blob => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = `Adverse Letter-${currentTimestamp}.xlsx`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
  
          // Success notification
          notify(`File exported successfully.`, {
              type: "success",
              anchorOrigin: { vertical: "top", horizontal: "right" },
          });
      }).catch(error => {
          console.error('Error:', error);
  
          // Error notification
          notify(`Operation failed: ${error.message}`, {
              type: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
          });
      });
  };
  
    
    return (
      <Grid style={{ marginTop: 10, marginBottom: 8 }} container spacing={3}>
        {/* Left side with title */}
        <Grid item xs={12} md={6}>
        
        <p style={{font:"Open Sans",fontWeight:600,fontSize:'18px'}}>Adverse Letter</p>
     
      
  
        </Grid>
  
        {/* Right side with search input, filter button, and add button */}
        <Grid
          item
          xs={12}
          md={6}
          container
          justifyContent="flex-end"
          alignItems="center"
        >
          <div className="group-btn">
            {/* Search Input */}
            <MuiTextField
              placeholder="search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              defaultValue={filterValues.searchcode}
              onChange={handleSearchChange}
              variant="outlined"
              size="small"
            />
             <AdverseFilter />
             <button
              onClick={handleExportData}
              type="button"
              className="g-line-btn"
              title="Export all data"
            >
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_14375_6193)">
                  <path
                    d="M8.66667 8.4987H10.6667L8 11.1654L5.33333 8.4987H7.33333V5.83203H8.66667V8.4987ZM10 3.16536H3.33333V13.832H12.6667V5.83203H10V3.16536ZM2 2.49336C2 2.12803 2.298 1.83203 2.666 1.83203H10.6667L14 5.16536V14.494C14.0006 14.5816 13.984 14.6684 13.951 14.7495C13.9181 14.8306 13.8695 14.9045 13.808 14.9668C13.7466 15.0291 13.6734 15.0788 13.5928 15.1128C13.5121 15.1469 13.4255 15.1648 13.338 15.1654H2.662C2.48692 15.1641 2.31934 15.0941 2.19548 14.9704C2.07161 14.8466 2.0014 14.6791 2 14.504V2.49336Z"
                    fill="#2DC3E8"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_14375_6193">
                    <rect
                      width="16"
                      height="16"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </button>
  
            
           
  
    
          </div>
        </Grid>
      </Grid>
    );
  };
  
  const MyList = ({ children, toolBar, ...props }) => {
    return (
      <ListBase
        {...props}
        perPage={100} // Number of items per page
      >
        {toolBar}
        <FilterChips excludeKeys={['app_invitation_type','active']} />
        {children}
       
        <Pagination rowsPerPageOptions={[100, 200, 300, 400, 500]} />
        <br/>
        <br/>
        <br/>
        <br/>
      </ListBase>
    );
  };


const AdverseLetter=()=>{
    const navigate = useNavigate();
    return(
        <>
    <MyList
      toolBar={<MyListToolBar />}
      sort={{ field: "id", order: "DESC" }}
    //   filter={{ subscriberID: switchSubscriberObj?.value }}
      // filter={{ subscriberID: 10145 }}
    >
    <Datagrid bulkActionButtons={false} sx={{
        "& .RaDatagrid-rowEven": { backgroundColor: "#F9F9F9" },
        "& .RaDatagrid-expandIcon": { color: "#fff" },
        "& .RaDatagrid-expandIconCell": { backgroundColor: "primary.main" },
        "& .RaDatagrid-headerCell": {
            fontWeight: "bold",
            fontSize: 12,
        },
        "& .RaDatagrid-table": {
            fontSize: 12,
        },
        "& .MuiToggleButton-root.Mui-selected": {
            fontWeight: 700,
        },
    }}>
        {/* <TextField source="id" />
        <TextField source="LetterKey" /> */}
        <TextField source="LetterName" label={"Letter Name"}/>
        <TextField source="Subject" />
        {/* <TextField source="Body" /> */}
        <DateField source="Date" />
        {/* <FunctionField 
  
        label={"Status"}
       source="Status"
        render={(record) =>
          record.Active ? <ActiveBtn /> : <DeActiveBtn />
        }
        /> */}
         <FunctionField
            label={"Status"}
            source=" Status"
            render={(record) => {
                return (
                    <MuiButton
                        sx={{ width: "80%" }}
                        variant="contained"
                        disableElevation={true}
                        style={bgStyle(record["Status"])}
                    >
                      {record["Status"]}
                    </MuiButton>
                );
            }}
        />

<FunctionField
  label={""}
  source="Status"
  render={(record) => {
    return record["Status"] === "Active" ? (
      <span style={{ 
          color: "grey",
          fontFamily: "Open Sans",
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "16.34px"
       }}>
        Default
        </span>
    ) : (
      ""
    );
  }}
/>

        <FunctionField
          label={""}
          render={(record) => (
            // <Box display={"flex"}>
            <Box display={"flex"} sx={{ width: "80px" }}>
              {/* <CopyLinkHoverDropdown record={record} /> */}
              <IconButton
                aria-label="view details"
                color="primary"
                sx={{
                  py: 0,
                }}
                onClick={() =>
                  navigate(
                          `/ats/settings/getadverseletter/adversetemplateDetails/${record.id}`,
                        //   { state: { isAdding: false, thirdPartyAppId: 1 } }
                        )
                }
                className="show_on_hover"
              >
                <ArrowForwardIcon />
              </IconButton>
            </Box>
          )}
        />
    </Datagrid>
</MyList>
</>
    )
}
export default AdverseLetter;