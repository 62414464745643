import React, { useEffect } from "react";
import "../../App.css";
import InfoIcon from '@mui/icons-material/Info';
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";
import { useGetIdentity, useGetList, useStore } from "react-admin";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import TermsAndConditionForm from "./TermsAndCondtionForm";
const TermsandCondtionsAdmin = (props) => {
  const [expandIdSection, setExpandIdSection] = React.useState(true);
  const [switchSubscriberObj] = useStore("switchSubscriberName");
  const { data: identity }: any = useGetIdentity();
  const id = switchSubscriberObj?.value || identity?.subscriberid;
  const { data, isLoading } = useGetList("gettermsandcondition_admin")
  const { data:dataField } = useGetList("GETDataFiled")


  return (
    <>
      <section className="main-sec">
        <div className="home_content">

          <div
            style={{ fontWeight: "600", fontSize: "18px" }}
          >
            <p>
              Terms & Conditions

            </p>
          </div>
          <div className="application-tabs">
            <div className="nav-flexes" style={{ marginTop: "40px",fontSize: "12px",fontWeight:"700",fontFamily:"Open Sans",lineHeight:"18.5px"  }}>
              <nav>
                <div
                  className="nav nav-tabs app-nav-tabs"
                  id="nav-tab"
                  role="tablist"
                >
                  {data && data.length > 0 && data?.slice(props.start ||0 , props.end || 2)?.map((item, index) => {
                    return (
                      <button
                        type="button"
                        className={`nav-link ${index === 0 ? "active" : ""}`}
                        data-bs-toggle="tab"
                        data-bs-target={`#tab-${item.id}`}
                      >
                        {item.TermsConditionType} <InfoIcon style={{ height: "12px", width: "12px", color: "orange" }} />
                      </button>
                    )
                  })}

                </div>
              </nav>
            </div>
            <div className="tab-content app-nav-tabcontent " id="nav-tabContent" style={{ marginTop: "10px", backgroundColor: "#f8f8f8" }} >
              {data && data.length > 0 && data.slice(props.start ||0 , props.end || 2).map((item, index) => {
                return (
                  <div className={`tab-pane fade show ${index === 0 ? "active" : ""}`} id={`tab-${item.id}`}>
                    <div className="auth-form mr-t30">
                      <TermsAndConditionForm DataFrom={item} dataFieldOptions={dataField} />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export const TermsandCondtionsAdmin_Subscriber = (props) => <TermsandCondtionsAdmin start={2} end={3} />
export const TermsandCondtionsAdmin_Landoard = (props) => <TermsandCondtionsAdmin start={4} end={5} />
export const TermsandCondtionsAdmin_Privacy = (props) => <TermsandCondtionsAdmin start={3} end={4} />
export const TermsandCondtionsAdmin_termsofuse = (props) => <TermsandCondtionsAdmin start={5} end={10} />

export default TermsandCondtionsAdmin

