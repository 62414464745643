import React, { Fragment, useEffect } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useNavigate, useLocation } from "react-router";
import { ArrowBack } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import InputField2 from "../common/InputComponents/InputField2";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import ToggleButtons from "../common/InputComponents/ToggleButtons";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import { Box, Button } from "@mui/material";
import { CircularProgress } from "@mui/material";
import {
    Loading,
    useCreate,
    useGetList,
    useNotify,
    useRefresh,
    useStore,
} from "react-admin";
import { useParams } from "react-router-dom";
import save_icon from "../../assets/images/save_icon.svg";
import formValidation from "../../yupFormValidationRules/formValidator";
import InputSelectField from "../common/InputComponents/InputSelectField";
import TextInputPhone from "../DocumentAnalysis/components/inputComponents/TextInputPhone";
import InputCheckBoxField from "../common/InputComponents/InputCheckBoxField";
import { JSONTree } from "react-json-tree";
import MFAPopup from "./MFAPopup";


const AddUserForAdmin = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const notify = useNotify();
    const [open, setOpen] = React.useState(false);
    const refresh = useRefresh();
    const toggleButton = [
        {
            value: 0,
            label: "Inactive",
            icon: <ClearIcon />,
            borderColor: "#909090",
            backgroundColor: "#EEE",
            boxShadowColor: "#90909033",
            color: "#606060",
        },
        {
            value: 1,
            label: "Active",
            icon: <CheckIcon />,
            borderColor: "#46A96F",
            backgroundColor: "#EBFFF3",
            boxShadowColor: "#46a96f1a",
            color: "#46A96F",
        },
    ];

    const [switchSubscriberObj] = useStore("switchSubscriberName");
    const { id } = useParams();

    const {
        data: getadminuserrole,
        isLoading: loadingadminuserrole,
    } = useGetList(
        "user-setup-admin-role"
    );
    console.log(getadminuserrole, "getadminuserrole")



    // form validation schema
    const validationSchema = yup.lazy((value) =>
        yup.object().shape({
            firstname: formValidation.name,
            lastname: formValidation.name,
            active: formValidation.StringRequiredValidation,
            email: formValidation.email,
            twofactorenabled: formValidation.StringRequiredValidation,
            mobile: formValidation.mobileNo,

            username: yup.string()
                .when("isregister", {
                    is: (value) => {
                        return value == 1;
                    },
                    then: yup
                        .string()
                        .typeError("required")
                        .required("required")
                }),
            usertype: formValidation.validationForDropDown,
        })
    );

    // Initial form values
    const defaultFormValues = {
        username: "",
        firstname: "",
        lastname: "",
        active: 1,
        isregister: "",
        twofactorenabled: 1,
        email: "",
        mobile: "",
        usertype: "",

    };

    const methods = useForm({
        defaultValues: defaultFormValues,
        resolver: yupResolver(validationSchema),
        mode: "onChange",
    });



    const [create, { isLoading, error }] = useCreate();

    // form submit handler
    const onSubmit = (value) => {
        console.log("onsubmt", value)
        const addData = {
            ...value,

            username: value?.username,
            email: value?.email,
            firstname: value?.firstname,
            lastname: value?.lastname,
            active: parseInt(value?.active),
            role: value?.usertype?.value,
            isregister: value?.isregister,
            twofactorenabled: parseInt(value?.twofactorenabled)

        };

        console.log(addData, "dump")
        create(
            `add-user-admin-user-setup`,
            { data: addData },
            {
                onSuccess: (data) => {
                    notify(


                        `User created successfully.`,


                        {
                            type: "success",
                            anchorOrigin: { vertical: "top", horizontal: "right" },
                        }
                    );
                    refresh();
                    methods?.reset();
                    navigate(-1);
                },
                onError: (e: any) =>
                    notify(`Operation fail:${e.message}`, {
                        type: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                    }),
            }
        );
    };



    return (
        <Fragment>

            <div className="home_content">
                <div className="row">
                    <div className="col-12 d-flex">
                        <Breadcrumbs aria-label="breadcrumb">
                            <Typography sx={{ fontWeight: "bold" }}>
                                <button
                                    title="Go Back"
                                    style={{ color: "#2cc0e4" }}
                                    className="btn btn-link"
                                    onClick={() => navigate(-1)}
                                >
                                    <ArrowBack />
                                </button>

                                <span>Add New User</span>

                            </Typography>
                        </Breadcrumbs>
                    </div>
                </div>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)} className="mr-t30">
                        <div className="row">
                            <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <InputField2
                                    label="First Name"
                                    source={`firstname`}
                                    placeholder="Enter First Name"
                                    labelClass={"lable-title"}
                                    additionalInputClass={"cus-control"}
                                />
                            </div>
                            <div className="col-sm-12 col-md-3 col-lg-3 mr-b20">
                                <InputField2
                                    label="Last Name"
                                    source={`lastname`}
                                    placeholder="Enter Last Name"
                                    labelClass={"lable-title"}
                                    additionalInputClass={"cus-control"}
                                />
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <ToggleButtons
                                    toggleButton={toggleButton}
                                    label="Status"
                                    labelClass={"lable-title"}
                                    source={`active`}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <InputField2
                                    label="Email"
                                    source={`email`}
                                    placeholder="Email"
                                    labelClass={"lable-title"}
                                    additionalInputClass={"cus-control"}
                                />
                            </div>

                            <div className="col-sm-12 col-md-4 col-lg-4 mr-t30">

                                <InputCheckBoxField
                                    notRequired={true}
                                    disabled={false}

                                    label="Register using Username"
                                    name={`isregister`}
                                />
                            </div>
                            {methods?.watch()?.isregister && <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <InputField2
                                    label="User Name"
                                    source={`username`}
                                    placeholder="Enter User Name"
                                    labelClass={"lable-title"}

                                    additionalInputClass={"cus-control"}
                                />
                            </div>}

                        </div>
                        <div className="row">

                            <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <TextInputPhone
                                    source={`mobile`}
                                    label={`Mobile Number`}
                                    // isCountryCode={`applicant.mobile.${0}.country_code`}
                                    labelClass={"lable-title"}
                                    additionalInputClass={"cus-control"}
                                    widthClass={"w-100"}
                                    requiredClass={{
                                        fontSize: "70%",
                                    }}
                                // onChangeCallback={() => {
                                //   props?.handleAPIDebounced();
                                // }}
                                />
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <InputSelectField
                                    label={`Role`}
                                    source={`usertype`}
                                    options={
                                        getadminuserrole?.map((item, index) => (

                                            {
                                                value: item.Name,
                                                label: item?.Name,
                                            }
                                        ))
                                    }
                                    labelAsteriskClass={"color-red"}
                                    labelClass={"lable-title"}
                                    requiredClass={{
                                        fontSize: "70%",
                                    }}
                                />
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <ToggleButtons
                                    toggleButton={toggleButton}
                                    label="Multi Factor Authentication"
                                    labelClass={"lable-title"}
                                    source={`twofactorenabled`}
                                    onClickHandler={(e) => {
                                        // if (!state?.isAdding) {
                                        if (methods?.watch(`twofactorenabled`) != e?.target?.value) {
                                            setOpen(true);
                                        }
                                        // }
                                    }}
                                />
                            </div>
                        </div>

                        <div className="row mr-b50" >
                            <div className="col-12 mr-b20"  >
                                <div
                                    className="p-3"
                                    style={{ background: "#f5fdff", borderRadius: "8px" }}
                                >
                                    <div className="row" style={{
                                        display: "flex",
                                        alignItems: "flex-end",   
                                        justifyContent: "flex-end"
                                    }}>

                                        <div className="col-sm-12 col-md-8 col-lg-4 align-item-right"    style={{
                                                marginLeft: "700px"
                                            }}>
                                            <div className="d-flex justify-content-end">
                                                <div className="mx-3">
                                                    <Button
                                                        sx={{ mx: 1 }}
                                                        onClick={() => {
                                                            refresh();
                                                            methods?.reset();
                                                            navigate(-1);
                                                        }}
                                                        variant="outlined"
                                                        color="secondary"
                                                        type="button"
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        startIcon={!isLoading &&<CheckIcon />}
                                                        sx={{ mx: 0 }}
                                                        variant="contained"
                                                        type="submit"
                                                        disabled={isLoading}
                                                    >
                                                       {isLoading ? (
                                                            <CircularProgress size="20px" sx={{ color: "white" }} /> // Show loader when isLoading is true
                                                        ) : (
                                                            <span>Create user</span> // Show text when isLoading is false
                                                        )}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </FormProvider>
            </div>


            <MFAPopup
                open={open}
                setOpen={setOpen}
                setValue={methods?.setValue}
                status={methods?.getValues("twofactorenabled")}
            />
        </Fragment>
    );
};

export default AddUserForAdmin;



