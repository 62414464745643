import * as React from "react";
import {
  useListContext,
  useGetOne,
  useGetIdentity,
} from "react-admin";
import { useForm } from "react-hook-form";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useUnselectAll, Button } from 'react-admin'

export default function MainFilter(props) {
  const { id } = useParams();
  const { data: identity } = useGetIdentity();
  const { displayedFilters, filterValues, setFilters, hideFilter,resource } =
    useListContext();
    
  const unselectAll = useUnselectAll(resource);
  // get user role from  global store
  const userRole =
    useSelector((state: any) => state?.users?.role) || identity?.userrole;

  // get application detail data
  const { data: applicationDetail, isLoading: isLoadingData } = useGetOne(
    "getnewapplicationdetail",
    {
      id: id || identity?.subscriberid,
    },
    { enabled: id || identity?.subscriberid ? true : false }
  );
  const form = useForm({
    defaultValues: filterValues,
  });

  // if (!displayedFilters.main) return null;
  const applyFilter = (values) => {
    if (Object.keys(values).length > 0) {
      const filter = {
        ...values,
        end_date: values.end_date
          ? moment(values.end_date).format("MM/DD/yyyy")
          : "",
        start_date: values.start_date
          ? moment(values.start_date).format("MM/DD/yyyy")
          : "",
      };
      setFilters(filter, displayedFilters);
    }
  };


  // const [q, setQ] = React.useState<null | string>(filterValues?.q);

  // React.useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     form.setValue("q", q);
  //     if (q != null) {
  //       applyFilter({
  //         ...form.getValues(),
  //         app_invitation_type: filterValues?.app_invitation_type || 1,
  //       });
  //     }
  //   }, 500);
  //   return () => clearTimeout(timeoutId);
  // }, [q]);

  React.useEffect(() => {
    if (Object.keys(filterValues).length === 0) {
      if (applicationDetail?.ApplicationType?.ATS) {
        setFilters({ app_invitation_type: "1", ...filterValues }, displayedFilters);
      } else if (applicationDetail?.ApplicationType?.DocUpload) {
        setFilters({ app_invitation_type: "2", ...filterValues }, displayedFilters);
      } else if (applicationDetail?.ApplicationType?.GuestCard) {
        setFilters({ app_invitation_type: "3", ...filterValues }, displayedFilters);
      }
    }
  }, [filterValues, applicationDetail]);
  React.useEffect(() => {
    if (!isLoadingData && applicationDetail) {
      if (filterValues.app_invitation_type && applicationDetail) {
        setFilters({}, displayedFilters);
      }
      if (applicationDetail.ApplicationType.ATS) {
        setFilters({ app_invitation_type: "1" }, displayedFilters);
      } else if (applicationDetail.ApplicationType.DocUpload) {
        setFilters({ app_invitation_type: "2" }, displayedFilters);
      } else if (applicationDetail.ApplicationType.GuestCard) {
        setFilters({ app_invitation_type: "3" }, displayedFilters);
      }
    }
  }, [applicationDetail, isLoadingData]);
  return (
    <>
      <div className="app-option-btn" id="a-oprtions">
        {applicationDetail?.ApplicationType?.ATS && (
          <label htmlFor="c1">
            <input
              type="radio"
              onChange={() => {
                form.setValue("app_invitation_type", "1");
                applyFilter(form.getValues());
                unselectAll();
              }}
              checked={
                !filterValues.app_invitation_type ||
                filterValues.app_invitation_type == 1
              }
              name="a-oprtions"
              id="c1"
            />
            <span>Application</span>
          </label>
        )}
        {applicationDetail?.ApplicationType?.DocUpload && (
          <label htmlFor="c2">
            <input
              type="radio"
              onChange={() => {
                form.setValue("app_invitation_type", "2");
                applyFilter(form.getValues());
                unselectAll();
              }}
              checked={filterValues.app_invitation_type == 2}
              name="a-oprtions"
              id="c2"
            />
            <span>Doc Upload</span>
          </label>
        )}
        {applicationDetail?.ApplicationType?.GuestCard && (
          <label htmlFor="c3">
            <input
              type="radio"
              onChange={() => {
                form.setValue("app_invitation_type", "3");
                applyFilter(form.getValues());
                unselectAll();
              }}
              checked={filterValues.app_invitation_type == 3}
              name="a-oprtions"
              id="c3"
            />
            <span>Guest Card</span>
          </label>
        )}
      </div>
    </>
  );
}
