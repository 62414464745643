import React, { Suspense, useEffect, useRef, useState } from "react";
import "./App.css";
import {
  Admin,
  BooleanField,
  CustomRoutes,
  Datagrid,
  DateField,
  defaultTheme,
  FunctionField,
  List,
  ListGuesser,
  NumberField,
  Resource,
  TextField,
  useDataProvider,
  useGetIdentity,
  useGetList,
  useGetOne,
  usePermissions,
  useStore,
} from "react-admin";
import {
  Outlet,
  Route,
  useNavigate,
  useNavigation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { dataProvidersAts } from "./dataproviders/DataProvidersAts";
import Inbox from "./Container/ATS/Inbox";
import MyLayout from "./componets/MyLayout";
import "./assets/styles/style.css";
import ApplicantDetailEdit from "./componets/Ats/ApplicantDetailEdit";
import { Provider, useDispatch, useSelector } from "react-redux";
import store, { RootState } from "./Store/store";
import {
  setToken,
  setUserRole,
  setaspNetUserId,
} from "./Store/Slice/usersSlice";
import { JSONTree } from "react-json-tree";
import InboxAdmin, {
  Getglobalsearch,
} from "./Container/ATS/InboxAdmin";
import SubscriberAdminUsage from "./Container/ATS/SubscriberAdminUsage";
import Login from "./Container/Login/Login";
import authProvider from "./authProviders/authProviders";
import Getloginactivitylogs, {
  GetAuditLogs,
} from "./Container/ATS/Getloginactivitylogs";
import Getarchivedcancelled from "./Container/ATS/Getarchivedcancelled";
import GetAdminGlobalSearch from "./componets/Ats/GetAdmininboxGlobalList";
import DocumentView from "./componets/Ats/DocumentView";
import ForgotPassword from "./Container/Login/ForgotPassword";
import DataPrivacy from "./Container/Login/DataPrivacy";
import TermsOfUse from "./Container/Login/TermOfUse";
import ApplicationRegistration from "./componets/ApplicationRegistration/ApplicationRegistration";
import ResetPassword from "./Container/Login/ResetPassword";
import ProfileDetails from "./Container/ATS/ProfileDetails";
import ApplicationSettings from "./Container/ATS/ApplicationSettings";
import AddNewProfile from "./Container/ATS/AddNewProfile";
import EmailSettings from "./componets/Settings/EmailSettings/EmailSettings";
import TemplateDetails from "./componets/Settings/EmailSettings/TemplateDetails";
import SubscriberTemplateDetails from "./componets/Settings/EmailSettings/SubscriberTemplateDetails";
import { MyList } from "./componets/Ats/GetAdmininboxList";
import { DatagridTable } from "./componets/Ats/GetsubscriberinboxList";
import PropertyList from "./componets/Settings/PropertySetup/PropertyList";
import AddNewProperty from "./componets/Settings/PropertySetup/AddNewProperty";
import PropertyDetails from "./componets/Settings/PropertySetup/PropertyDetails";
import AddAndUpdateFloor from "./componets/Settings/PropertySetup/AddAndUpdateFloor";
import AddAndUpdateUnit from "./componets/Settings/PropertySetup/AddAndUpdateUnit";
import ManageSubscribersList from "./componets/manageSubscribers/ManageSubscribersList";
// import AddNewSubscriber from "./componets/manageSubscribers/AddNewSubscriber";
import { ATS_DOCS_URL, SOFTWARE_VERSION, typesofUsers } from "./config";
import MfaLogin from "./Container/Login/MfaLogin";
import AddorUpdateSubscriber from "./componets/manageSubscribers/AddorUpdateSubscriber";
import EntrataList from "./componets/Settings/IntegrationSettings/Entrata/EntrataList";
import AddorUpdateIntegrationSettings from "./componets/Settings/IntegrationSettings/Entrata/AddorUpdateIntegrationSettings";
import YardiList from "./componets/Settings/IntegrationSettings/Yardi/YardiList";
import HierarchyLevelList from "./componets/Settings/HierarchyLevel/HierarchyLevelList";
import AddorUpdateHierarchyLevel from "./componets/Settings/HierarchyLevel/AddorUpdateHierarchyLevel";
import UpdateStripeAccount from "./componets/Settings/PropertySetup/UpdateStripeAccount"
import ErrorIcon from "@mui/icons-material/Report"
import { useUserRole } from "./hooks/useUserRoleHook";
import TermsandCondtions from "./Container/TermsandCondtions/TermsandCondtions";
import TermsandCondtionsAdmin, { TermsandCondtionsAdmin_Landoard, TermsandCondtionsAdmin_Privacy, TermsandCondtionsAdmin_Subscriber, TermsandCondtionsAdmin_termsofuse } from "./Container/TermsandCondtionsAdmin/TermsandCondtions";
import UpdateTemplateDetails from "./componets/Settings/EmailSettings/UpdateTemplateDetails";
import AdminEmailSettings from "./Container/ATS/AdminEmailSettings";
import UpdateAdminTemplateDetail from "./Container/ATS/UpdateAdminTemplateDetail";
import { UserRoleProvider } from "./ContextApi/UserRoleContext";
import UserSetup from "./componets/Settings/UserSetup/UserSetup";
import AddOrUpdateUser from "./componets/Settings/UserSetup/AddOrUpdateUser";
import UpdateUser from "./componets/Settings/UserSetup/UpdateUser";
import UserLogs from "./componets/Settings/UserSetup/UserLogs";
import ManageUsers from "./componets/manageUsers/ManageUsers";
import AddUserForAdmin from "./componets/manageUsers/AddUserForAdmin";
import UpdateUserForAdmin from "./componets/manageUsers/UpdateUserForAdmin";

import TempPassWordResetUserSetup from "./Container/ATS/TempPassWordResetUserSetup";
import TermsAndConditionForUserSetup from "./Container/ATS/TermsConditionUserSetup";
// import { getverificationmanagementreportResourcedv } from "./componets/AdminManagementReport/VerificationReport";
// import { ScreeningManagementReportResourcedv } from "./componets/AdminManagementReport/ScreeningManagement";
// import { getrevenuemanagementreportResourcedv } from "./componets/AdminManagementReport/AdminRevenueManagement";
// import { getleadmanagementreportResourcedv } from "./componets/AdminManagementReport/AdminLeadManagement";
import { LeadManagementReportResource } from "./componets/SubscriberManagementReport/LeadMangement";
import RevenueManagementReportResource from "./componets/SubscriberManagementReport/RevenueManagement";
import { DocuploadManagementReportResource } from "./componets/SubscriberManagementReport/DocuploadManagement";
import AdminLeadManagement from "./Container/ATS/AdminLeadManagement";
import AdminScreeningManagement from "./Container/ATS/AdminScreeningManagement";
import AdminRevenueManagement from "./Container/ATS/AdminRevenueManagement";
import AdminVerificationManagement from "./Container/ATS/AdminVerificationReport";

import { Navigate, useLocation } from 'react-router-dom'
// import AdminLeadManagement from "./componets/AdminManagementReport/AdminLeadManagement";

import ForgotUsername from "./Container/Login/ForgotUsername";
import TermsConditionLandLoardSetup from "./Container/ATS/TermsConditionLandLoardSetup";
import InboxLandLoard from "./Container/ATS/InboxLandLoard";
import { ConfidentialityLogsResource } from "./componets/DocumentAnalysis/components/ConfidentialityLogs/Confidentialitylogs";
import { AdverseLetterResource } from "./componets/DocumentAnalysis/components/AdverseLetter/AdverseLetter";
import UpdateAdverseLetter from "./componets/DocumentAnalysis/components/AdverseLetter/UpdateAdverseLetter";
import { SystemNotificationResource } from "./componets/AdminSystemNotification/SystemNotification";
import AddNotification from "./componets/AdminSystemNotification/AddNotification";
import UpdateNotification from "./componets/AdminSystemNotification/UpdateNotification";
import SubscriberNotification from "./componets/AdminSystemNotification/SubscriberNotification";
import ViewAdverseLetter from "./componets/Ats/LandLoard/ViewAdverseLetter";



const theme = {
  ...defaultTheme,
  sidebar: {
    width: 240, // The default value is 240
    closedWidth: 66, // The default value is 55
  },
  appbar: {
    // root: {
    //   backgroundColor: 'red'
    // }
  },
  typography: {
    fontFamily: ["'Open Sans'", " sans-serif"].join(","),
    fontSize: 12,
    fontWeight: 400,
    LineHeight: 16,
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#2cc0e4",
      contrastText: "#fff",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: "#0066ff",
      main: "#4BD5E7",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#fff",
    },
    error: {
      main: "#ff4848",
      contrastText: "#fff",
    },
    warning: {
      main: "#f9bb04",
      contrastText: "#fff",
    },
    success: {
      main: "#46a96f",
      contrastText: "#fff",
    },
    custom: "#fff",
  },
  components: {
    ...defaultTheme.components,
    MuiTextField: {
      defaultProps: {
        variant: "outlined" as const,
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: "outlined" as const,
      },
    },
  },
};

// const TokenCheck = ({ isAdmin = false }) => {
//   const [searchParams, setSearchParams] = useSearchParams();
//   const [checkToken, setCheckToken] = useState<String | null>();
//   const token = searchParams.get("token");
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const { id } = useParams();
//   useEffect(() => {
//     setCheckToken(token);
//   }, []);
//   useEffect(() => {
//     dispatch(setToken(checkToken));
//     sessionStorage.setItem("token", `${checkToken}`);
//     if (checkToken) {
//       if (!isAdmin) {
//         // localStorage.clear();
//         navigate("/Ats/Subscriber/Inbox/" + id, { replace: true });
//       } else {
//         // localStorage.clear();
//         navigate("/Ats/Admin/SubscriberUsageList", { replace: true });
//       }
//     }
//   }, [checkToken]);
//   return (
//     <>
//       <JSONTree data={checkToken} />
//       <h2>No token Found</h2>
//     </>
//   );
// };

const TokenValid = (props) => {
  const { data } = useGetIdentity();
  // const token = sessionStorage.getItem('token');
  const dispatch = useDispatch();
  // const { data, isLoading, isError } = useGetOne('getTokkenDetails', { id: token }, { refetchOnWindowFocus: false });
  // if (!isError && !isLoading) {
  dispatch(setaspNetUserId(data?.userid));
  dispatch(setUserRole(data?.userrole));
  // const { permissions } = usePermissions();
  // const [switchSubscriberObj] = useStore("switchSubscriberName");
  // dispatch(setToken(token));
  // }
  const navigate = useNavigate();
  useEffect(() => {
    if (data?.userrole == "Applicant") {
      if (data.redirectUrl) navigate(data.redirectUrl, { replace: true });
    }
  }, [data]);
  // if (!isError && !isLoading)

  if (data?.userrole != "Applicant" && data?.userrole) return <Outlet />;
  else return <></>;
};

export const SubscriberSwitchValidation = (props) => {
  // let { isLoading: isLoadingPermissions, permissions } = usePermissions();
  const [switchSubscriberObj, setSwitchSubscriberObj] = useStore("switchSubscriberName");
  const { data, isLoading } = useGetIdentity();
  const { Component } = props;
  const navigate = useNavigate();

  // const prevPermissionsRef = useRef(permissions);
  useEffect(() => {
    // if (!isLoadingPermissions && prevPermissionsRef !== permissions) {
    if (props.isAdmin && localStorage.getItem("RaStore.switchSubscriber") === "true") {
      navigate(`/ats/Subscriber/Inbox/${switchSubscriberObj?.value}`, { replace: true })
      // localStorage.setItem("RaStore.switchSubscriber", JSON.stringify(true))
    }
    else if (!props.isAdmin && localStorage.getItem("RaStore.switchSubscriber") === "false") {
      setSwitchSubscriberObj({});
      navigate("/ats", { replace: true });
      // localStorage.setItem("RaStore.switchSubscriber", JSON.stringify(false))
    }
    //   }

    // prevPermissionsRef.current = permissions;
  }, [data, props])
  useEffect(() => {
    const termconditionStatus = localStorage.getItem("TermAndConditionVefificationStatus");
    if (!data?.isPasswordChanged && !isLoading) {
      navigate('/Ats/TempForgotPassword')
    } else if (!data?.isTandCAccepted && !isLoading && !termconditionStatus) {
      navigate('/Ats/TermsAndConditionForUserSetup')
    }

  }, [data])
  return <Component type={props?.type} />
}

const Dashbaord = () => {
  const { data, isLoading } = useGetIdentity();
  // const [switchSubscriber, setSwitchSubscriber] = useStore("switchSubscriber");
  // // useEffect(() => {
  // //   if (switchSubscriber) {
  // //     setSwitchSubscriber(false);
  // //   }
  // // }, [switchSubscriber]);
  const navigate = useNavigate();
  const [switchSubscriberObj, setSwitchSubscriberObj] = useStore("switchSubscriberName");
  useEffect(() => {
    if (data?.userrole == "Applicant") {
      if (data.redirectUrl) navigate(data.redirectUrl, { replace: true });
    }
    if (data?.userrole === "Docuverus Super Admin" && localStorage.getItem("RaStore.switchSubscriber") === null) {
      localStorage.setItem("RaStore.switchSubscriber", JSON.stringify(false))
    }
    if ((data?.userrole === "Agent" || data?.userrole === "Manager") && localStorage.getItem("RaStore.switchSubscriber") === null) {
      localStorage.setItem("RaStore.switchSubscriber", JSON.stringify(true))
    };
    if (data?.userrole === "Admin") {
      setSwitchSubscriberObj({ value: data?.subscriberid, label: data?.fullName, companyType: data?.subscribertype });
    }
  }, [data])
  useEffect(() => {
    const termconditionStatus = localStorage.getItem("TermAndConditionVefificationStatus");
    if (!data?.isPasswordChanged && !isLoading) {
      navigate('/Ats/TempForgotPassword')
    } else if (!data?.isTandCAccepted && !isLoading && !termconditionStatus) {
      navigate('/Ats/TermsAndConditionForUserSetup')
    }

  }, [data])

  const userRole = useUserRole();
  // if (!isError && !isLoading)

  if (data?.userrole != "Applicant" && data?.userrole) {
    if (data?.subscriberView) return <Inbox />;
    return <InboxAdmin />;
  }
  // return <></>;
  // if(userRole == typesofUsers.docuverusSuperAdmin) return <InboxAdmin />
  return <InboxLandLoard />
};

const Test = () => <p>Testing....</p>

const AtsApp = () => {
  // const dataProvider = useDataProvider();
  // const { data, isLoading, error } = useGetList('getapplicationgraph');
  return (
    <Provider store={store}>
      <Admin
        theme={theme}
        dashboard={Dashbaord}
        layout={MyLayout}
        authProvider={authProvider}
        requireAuth
        loginPage={Login}
        basename="/Ats"
        dataProvider={dataProvidersAts}
      >

        <CustomRoutes>
          {/* <Route path='/dashboard/login' element={<TokenCheck isAdmin={true} />} /> */}
          {/* <Route path='/Inbox/:id' element={<TokenCheck />} /> */}


          <Route
            path="/SubscriberUsageList"
            element={<SubscriberSwitchValidation isAdmin={true} Component={SubscriberAdminUsage} />}
          />
          <Route
            path="/getloginactivitylogs"
            element={<SubscriberSwitchValidation isAdmin={true} Component={Getloginactivitylogs} />}
          />
          {/* <Route
            path="/getEmailSettings"
            element={<Resource name='admin-email-template-list' list={<SubscriberSwitchValidation isAdmin={true} Component={AdminEmailSettings} />}/>}/> */}

          <Route
            path="/getscreeningmanagementreportdv"
            element={<SubscriberSwitchValidation isAdmin={true} Component={RevenueManagementReportResource} />}
          />
          {/* <Route path="/getleadmanagementreportdv" element={<Resource name="getleadmanagementreportdv" list={AdminLeadManagement}/>}/> */}
          {/* <Route
            path="/getverificationmanagementreportdv"
            element={<SubscriberSwitchValidation isAdmin={true} Component={getverificationmanagementreportResourcedv} />}
          /> */}
          <Route
            path="/adminlead"
            element={<SubscriberSwitchValidation isAdmin={true} Component={AdminLeadManagement} />}
          />
          <Route
            path="/adminverification"
            element={<SubscriberSwitchValidation isAdmin={true} Component={AdminVerificationManagement} />}
          />
          <Route
            path="/adminscreen"
            element={<SubscriberSwitchValidation isAdmin={true} Component={AdminScreeningManagement} />}
          />
          <Route
            path="/adminrevenue"
            element={<SubscriberSwitchValidation isAdmin={true} Component={AdminRevenueManagement} />}
          />
         
          <Route
            path="/getEmailSettings/admintemplateDetails/:id"
            element={<SubscriberSwitchValidation isAdmin={true} Component={UpdateAdminTemplateDetail} />}
          />
          <Route
            path="/getglobalsearch"
            element={<SubscriberSwitchValidation isAdmin={true} Component={Getglobalsearch} />}
          />
          <Route
            path="/termsandconditions_applicant"
            element={<SubscriberSwitchValidation isAdmin={true} Component={TermsandCondtionsAdmin} />}
          />
          <Route
            path="/termsandconditions_subscriber"
            element={<SubscriberSwitchValidation isAdmin={true} Component={TermsandCondtionsAdmin_Subscriber} />}
          />
          <Route
            path="/termsandconditions_landloard"
            element={<SubscriberSwitchValidation isAdmin={true} Component={TermsandCondtionsAdmin_Landoard} />}
          />
          <Route
            path="/termsandconditions_privacy"
            element={<SubscriberSwitchValidation isAdmin={true} Component={TermsandCondtionsAdmin_Privacy} />}
          />
          <Route
            path="/termsandconditions_termsofuse"
            element={<SubscriberSwitchValidation isAdmin={true} Component={TermsandCondtionsAdmin_termsofuse} />}
          />
          <Route
            path="/adverseletter"
            element={<ViewAdverseLetter />}
          />
          {/* <Route path="/Admin" element={<TokenValid />}> */}
          {/* <Route path="/Admin/lastapp" element={<SubscriberSwitchValidation isAdmin = {true} Component = {InboxAdmin1} />} /> */}
          {/* <Route
              path="/Admin/getglobalsearch"
              element={<SubscriberSwitchValidation isAdmin = {true} Component = {Getglobalsearch} />}
            /> */}
          {/* <Route path="/Admin/lastdoc" element={<SubscriberSwitchValidation isAdmin = {true} Component = {InboxAdmin2} />} /> */}
          {/* <Route path="/Admin/getatsqueue" element={<SubscriberSwitchValidation isAdmin = {true} Component = {InboxAdmin3} />} /> */}
          {/* <Route
              path="/Admin/SubscriberUsageList"
              element={<SubscriberSwitchValidation isAdmin = {true} Component = {SubscriberAdminUsage} />}
            /> */}
          {/* <Route
              path="/Admin/getloginactivitylogs"
              element={<SubscriberSwitchValidation isAdmin = {true} Component = {Getloginactivitylogs} />}
            /> */}
          {/* <Route path="/Admin/Inbox/:id/edit/:applicationId/:appType" element={<ApplicantDetailEdit />} /> */}
          {/* </Route> */}

          <Route path="/Subscriber" element={<TokenValid />}>

            <Route path="/Subscriber/Inbox/:id" element={<SubscriberSwitchValidation isAdmin={false} Component={Inbox} />} />
            <Route
              path="/Subscriber/getarchivedcancelled/:id"
              element={<SubscriberSwitchValidation isAdmin={false} Component={Getarchivedcancelled} />}
            />
            <Route
              path="/Subscriber/Inbox/:id/edit/:applicationId/:appType"
              element={<UserRoleProvider defaultRole=""><ApplicantDetailEdit /></UserRoleProvider>}
            />
            <Route
              path="/Subscriber/Inbox/:id/edit/:applicationId/:appType/auditlogs/:applicantId"
              element={<SubscriberSwitchValidation isAdmin={false} Component={GetAuditLogs} />}
            />
          </Route>
          <Route
            path="Copy/Property/:applicationType/:parm1/:parm2/:parm3"
            element={<>Property Docupload / Guest card </>}
          />
          <Route
            path="Copy/Office/:applicationType/:parm1/:parm2/:parm3"
            element={<>Office Guest card / Docupload</>}
          />

          <Route path="/dashboard/login" element={<Login />} />
          <Route path="/docview" element={<DocumentView />} />
          <Route path="/emailSettings/" element={<EmailSettings />} />
          <Route
            path="/emailSettings/templateDetails"
            element={<TemplateDetails />}
          />
          <Route path="/settings/getleadmanagementreport"
            element={
              <SubscriberSwitchValidation isAdmin={false}
                Component={LeadManagementReportResource} />
            } />
          <Route path="/settings/getrevenuemanagementreport"
            element={
              <SubscriberSwitchValidation isAdmin={false}
                Component={RevenueManagementReportResource} />

            } />
          <Route path="/managementreport/getleadmanagementreport"
            element={
              <SubscriberSwitchValidation isAdmin={false}
                Component={LeadManagementReportResource} />
            } />
          <Route path="/managementreport/getrevenuemanagementreport"
            element={
              <SubscriberSwitchValidation isAdmin={false}
                Component={RevenueManagementReportResource} />

            } />
          <Route path="/settings/getconfidentialitylogs"
            element={
              <SubscriberSwitchValidation isAdmin={false}
                Component={ConfidentialityLogsResource} />
            } />
          <Route path="/settings/getadverseletter"
            element={
              <SubscriberSwitchValidation isAdmin={false}
                Component={AdverseLetterResource} />
            } />
          <Route path="/settings/getadverseletter/adversetemplateDetails/:id" element={<SubscriberSwitchValidation isAdmin={false} Component={UpdateAdverseLetter} />} />

          {/* <Route path="/settings/getleadmanagementreport"element={<Resource name="getleadmanagementreport" list={ListGuesser}/>}/> */}
          {/* <Route path="/settings/emailSettings" element={<Resource name='email-template-list' list={<SubscriberSwitchValidation isAdmin={false} Component={EmailSettings} />} />} /> */}
          {/* <Route path="/settings/getrevenuemanagementreport"element={<Resource name="getrevenuemanagementreport" list={ListGuesser}/>}/> */}
          {/* <Route path="/settings/getdocuploadmanagementreport"element={<Resource name="getdocuploadmanagementreport" list={ListGuesser}/>}/> */}
          <Route path="/managementreport/getdocuploadmanagementreport"
            element={
              <SubscriberSwitchValidation isAdmin={false}
                Component={DocuploadManagementReportResource} />

            } />
          <Route
            path="/emailSettings/subscriberTemplateDetails"
            element={<SubscriberTemplateDetails />}
          />
          {/* <Route path="/settings/getdocuploadcount" element={<DocuploadReportCount/>}/> */}
          <Route
            path="/emailSettings/profiledetails"
            element={<ProfileDetails />}
          />
          <Route
            path="/emailSettings/applicationsettings"
            element={<ApplicationSettings />}
          />

          <Route
            path="/emailSettings/addnewprofile"
            element={<AddNewProfile />}
          />
          <Route path="/settings/propertySetup/" element={<Resource name='Property' list={<SubscriberSwitchValidation isAdmin={false} Component={PropertyList} />} />} />
          <Route path="/settings/propertySetup/addNewProperty" element={<SubscriberSwitchValidation isAdmin={false} Component={AddNewProperty} />} />
          <Route path="/settings/propertySetup/propertyDetails/:id" element={<SubscriberSwitchValidation isAdmin={false} Component={PropertyDetails} />} />
          <Route path="/settings/propertySetup/propertyDetails/:id/addNewFloor" element={<SubscriberSwitchValidation isAdmin={false} Component={AddAndUpdateFloor} />} />
          <Route path="/settings/propertySetup/propertyDetails/:id/addNewUnit" element={<SubscriberSwitchValidation isAdmin={false} Component={AddAndUpdateUnit} />} />
          <Route path="/settings/propertySetup/propertyDetails/:id/floorDetails/:floorId" element={<SubscriberSwitchValidation isAdmin={false} Component={AddAndUpdateFloor} />} />
          <Route path="/settings/propertySetup/propertyDetails/:id/unitDetails/:unitId" element={<SubscriberSwitchValidation isAdmin={false} Component={AddAndUpdateUnit} />} />
          <Route path="/settings/propertySetup/propertyDetails/stripeAccount/:id" element={<SubscriberSwitchValidation isAdmin={false} Component={UpdateStripeAccount} />} />
          <Route
            path="/settings/termsandconditions"
            element={<SubscriberSwitchValidation isAdmin={false} Component={TermsandCondtions} />
            }
          />
          {/* <Route path="/settings/officeSetup" element={<TokenValid isAdmin={false}/>}> */}
          <Route path="/settings/officeSetup/" element={<Resource name='get-office' list={<SubscriberSwitchValidation isAdmin={false} Component={PropertyList} type={"Office"} />} />} />
          <Route path="/settings/officeSetup/addNewOffice" element={<SubscriberSwitchValidation isAdmin={false} Component={AddNewProperty} />} />
          <Route path="/settings/officeSetup/officeDetails/:id" element={<SubscriberSwitchValidation isAdmin={false} Component={PropertyDetails} />} />
          <Route path="/settings/officeSetup/officeDetails/stripeAccount/:id" element={<SubscriberSwitchValidation isAdmin={false} Component={UpdateStripeAccount} />} />
          {/* </Route> */}
          {/* <Route path="/manageSubscribers" element={<TokenValid isAdmin={true}/>}> */}
          <Route path="/manageSubscribers" element={<Resource name='subscriber-list' list={<SubscriberSwitchValidation isAdmin={true} Component={ManageSubscribersList} />} />} />
          <Route path="/manageSubscribers/addNewSubscriber" element={<SubscriberSwitchValidation isAdmin={true} Component={AddorUpdateSubscriber} />} />
          <Route path="/manageSubscribers/subscriberDetails/:id" element={<SubscriberSwitchValidation isAdmin={true} Component={AddorUpdateSubscriber} />} />
          {/* </Route> */}
          {/* admin email settings */}
          <Route path="/getEmailSettings" element={<Resource name='admin-email-template-list' list={<SubscriberSwitchValidation isAdmin={true} Component={AdminEmailSettings} />} />} />
          {/* manage user for admin */}
          <Route path="/manageUsers" element={<Resource name='user-setup-admin' list={<SubscriberSwitchValidation isAdmin={true} Component={ManageUsers} />} />} />
          <Route path="/manageUsers/ManageUsers/addNewAdminUser" element={<SubscriberSwitchValidation isAdmin={true} Component={AddUserForAdmin} />} />
          
          <Route path="/manageUsers/ManageUsers/updateNewAdminUser/:Id" element={<SubscriberSwitchValidation isAdmin={true} Component={UpdateUserForAdmin} />} />
         {/* notification */}
          <Route
            path="/adminnotification"
            element={<SubscriberSwitchValidation isAdmin={true} Component={SystemNotificationResource} />}
          />
           <Route path="/sub" element={<SubscriberSwitchValidation isAdmin={true} Component={SubscriberNotification} />} />
          <Route path="/adminnotificatio/updateNotification/:id" element={<SubscriberSwitchValidation isAdmin={true} Component={UpdateNotification} />} />
           <Route path="/adminnotification/addnotification" element={<SubscriberSwitchValidation isAdmin={true} Component={AddNotification} />} />
          {/* <Route path="/settings/integrationSettings" element={<TokenValid isAdmin={true}/>}> */}
          <Route path="/settings/integrationSettings/entrata" element={<Resource name='integration-setting-rule-entrata' list={<SubscriberSwitchValidation isAdmin={false} Component={EntrataList} />} />} />
          <Route path="/settings/integrationSettings/entrata/addIntegrationSettings" element={<SubscriberSwitchValidation isAdmin={false} Component={AddorUpdateIntegrationSettings} />} />
          <Route path="/settings/integrationSettings/entrata/entrataDetails/:id" element={<SubscriberSwitchValidation isAdmin={false} Component={AddorUpdateIntegrationSettings} />} />
          <Route path="/settings/integrationSettings/yardi" element={<Resource name='integration-setting-rule-yardi' list={<SubscriberSwitchValidation isAdmin={false} Component={YardiList} />} />} />
          <Route path="/settings/integrationSettings/yardi/addyardiIntegrationSettings" element={<SubscriberSwitchValidation isAdmin={false} Component={AddorUpdateIntegrationSettings} />} />
          <Route path="/settings/integrationSettings/yardi/yardiDetails/:id" element={<SubscriberSwitchValidation isAdmin={false} Component={AddorUpdateIntegrationSettings} />} />
          {/* </Route> */}
          {/* <Route path="/settings/hierarchyLevel" element={<TokenValid isAdmin={false}/>}> */}
          <Route path="/settings/hierarchyLevel" element={<Resource name='hierarchy-levels-list' list={<SubscriberSwitchValidation isAdmin={false} Component={HierarchyLevelList} />} />} />
          <Route path="/settings/hierarchyLevel/addNewLevel" element={<SubscriberSwitchValidation isAdmin={false} Component={AddorUpdateHierarchyLevel} />} />
          <Route path="/settings/hierarchyLevel/levelDetails/:id" element={<SubscriberSwitchValidation isAdmin={false} Component={AddorUpdateHierarchyLevel} />} />

          {/* Email Settings */}
          <Route path="/settings/emailSettings" element={<Resource name='email-template-list' list={<SubscriberSwitchValidation isAdmin={false} Component={EmailSettings} />} />} />
          <Route path="/settings/emailSettings/templateDetails/:id" element={<SubscriberSwitchValidation isAdmin={false} Component={UpdateTemplateDetails} />} />

          {/* user setup */}
          {/* <Route path="/settings/usersetup" element={<Resource name='user-setup' list={<SubscriberSwitchValidation isAdmin={false} Component={UserSetup} />} />} /> */}
          <Route path="/settings/usersetup" element={<Resource name='user-setup' list={UserSetup} />} />
          <Route path="/settings/usersetup/adduser" element={<SubscriberSwitchValidation isAdmin={false} Component={AddNotification} />} />
         
          <Route path="/settings/usersetup/updateuser/:Id/:UserType" element={<SubscriberSwitchValidation isAdmin={false} Component={UpdateUser} />} />
          {/* <Route path="/settings/usersetup/updateuser/:Id" element={<Resource name='user-setup' list={<SubscriberSwitchValidation isAdmin={false} Component={UpdateUser} />} />}/> */}
          <Route path="/settings/usersetup/userlogs/:usernameVal" element={<Resource name='loginuserlogs' list={<SubscriberSwitchValidation isAdmin={false} Component={UserLogs} />} />} />
          {/* Email Settings */}
          {/* </Route> */}
        </CustomRoutes>
        <CustomRoutes noLayout>
          <Route path="/TempForgotPassword" element={<TempPassWordResetUserSetup />} />
          <Route path="/TermsAndConditionForUserSetup" element={<TermsAndConditionForUserSetup />} />
        </CustomRoutes>
        {/* Resource defined */}
        <Resource name="getsubscriberinbox" />
        <Route path="/ForgotPassword" element={<ForgotPassword />} />
      </Admin>
    </Provider>
  );
};

export const PublicRoutes = () => {
  return (
    <Provider store={store}>
      <Admin
        theme={theme}
        dashboard={Dashbaord}
        layout={MyLayout}
        basename="/atss"
        dataProvider={dataProvidersAts}
      >
        <CustomRoutes noLayout>
          <Route path="/Landloard" >
            <Route path="/Landloard/TermsAndConditionForUserLandLoard" element={<TermsConditionLandLoardSetup />} />
          </Route>
        </CustomRoutes>


        <CustomRoutes noLayout>
          <Route path="/ForgotPassword" element={<ForgotPassword />} />

          {/* <Route path='/profiledetails' element={<ProfileDetails/>}/> */}
          <Route
            path="/applicationsettings"
            element={<ApplicationSettings />}
          />
          <Route path="/DataPolicy" element={<DataPrivacy />} />
          <Route path="/TermsOfUse" element={<TermsOfUse />} />
          <Route path="/cp/*" element={<ApplicationRegistration />} />
          <Route path="/resetPassword/:id" element={<ResetPassword />} />
          <Route path="/ForgotPassword" element={<ForgotPassword />} />
          <Route path="/ForgotUsername" element={<ForgotUsername />} />
          <Route path="/DataPolicy" element={<DataPrivacy />} />
          <Route path="/TermsOfUse" element={<TermsOfUse />} />
          <Route path="/cp/*" element={<ApplicationRegistration />} />
          <Route path="/resetPassword/:id" element={<ResetPassword />} />
          {/* <Route path="/mfa" element={<MfaLogin />} /> */}
        </CustomRoutes>
        {/* Resource defined */}
      </Admin>
    </Provider>
  );
};

export default AtsApp;
