import * as React from "react";
import {
  useListContext,
  useGetList,
} from "react-admin";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useUnselectAll } from 'react-admin'


export default function MainFilterAdmin(props) {
  const navigate = useNavigate();

  const { displayedFilters, filterValues, setFilters, hideFilter,resource } =
    useListContext();

  // call getreviewqueue for total count
  const { total: getreviewqueueCount } = useGetList("getadmininbox");
  // call getlastthirtydaysqueue for total count
  const { total: getlastthirtydaysqueueCount } = useGetList(
    "getlastthirtydaysqueue"
  );
  const { total: getatsqueueTotal } = useGetList("getatsqueue");
  // call getlastthirtydaysqueue for total DocCount
  const { total: getlastthirtydayDocCount } = useGetList(
    "getlastthirtydaysqueue",
    {
      filter: { app_invitation_type: 2 },
    }
  );
  const ref = React.useRef<null | HTMLInputElement>(null);
  const getFocusToSearch = () => {
    ref.current?.focus();
  };
  const keydownHandler = (e) => {
    if ((e.key === "k" || e.key === "K") && e.ctrlKey) {
      e.preventDefault();
      getFocusToSearch();
    }
  };
  React.useEffect(() => {
    document.addEventListener("keydown", keydownHandler);
    return () => {
      document.removeEventListener("keydown", keydownHandler);
    };
  }, []);
  const filterForm = useForm<any>({
    defaultValues: {
      lastApplicationInDays: [{ label: `Last 30 Days`, value: 30 }],
    },
  });

  React.useEffect(() => {
    if (
      filterValues?.lastApplicationInDays &&
      filterForm.getValues("lastApplicationInDays")?.value !==
      filterValues?.lastApplicationInDays
    ) {
      filterForm.setValue("lastApplicationInDays", [
        {
          label: `Last ${filterValues?.lastApplicationInDays} Days`,
          value: filterValues?.lastApplicationInDays,
        },
      ]);
    }
  }, [filterValues]);


  const unselectAll = useUnselectAll(resource);
  return (
    <>
      <div className="app-option-btn" id="a-oprtions">
        <label htmlFor="c3">
          <input type="radio" defaultChecked={'DA_REVIEW_QUEUE' == filterValues.inbox_type || !filterValues.inbox_type } onClick={() => {
            // navigate('/ATS')
            //defaultChecked={'/ats' == location.pathname.toLowerCase()}
            // add filter here in place of direct redirecting to /ats
            setFilters({ 'app_invitation_type': 2, "dashboardOption": 1, "inbox_type": "DA_REVIEW_QUEUE" }, ['app_invitation_type', 'dashboardOption', 'inbox_type']);
            unselectAll();
          }
          }
            name="a-oprtions" id="c3" />
          <span>
            DA QA Review
            {getreviewqueueCount ? <div className="total_count">{getreviewqueueCount ? getreviewqueueCount : " "}</div> : <div className="total_count">0</div>}
          </span>
        </label>
        <label htmlFor="c1">
          <input type="radio" defaultChecked={(filterValues.inbox_type == "ATS_LAST_30_DAYS" )} onClick={() => {
            // navigate('/Ats/Admin/lastapp')
            // defaultChecked={'/Ats/Admin/lastapp'.toLowerCase() == location.pathname.toLowerCase()}
            // add filter here in place of direct redirecting to /Ats/Admin/lastapp
            setFilters({ 'app_invitation_type': "1", "dashboardOption": 1, "inbox_type": "ATS_LAST_30_DAYS" }, ['app_invitation_type', 'dashboardOption', 'inbox_type']);
            unselectAll();
          }} name="a-oprtions" id="c1" />
          <span>
            ATS Last 30 Days
            {getlastthirtydaysqueueCount ? <div className="total_count">{getlastthirtydaysqueueCount ? getlastthirtydaysqueueCount : 0}</div> : <div className="total_count">0</div>}
          </span>
        </label>
        <label htmlFor="c2">
          <input type="radio" defaultChecked={filterValues.inbox_type == "DOCUPLOAD_LAST_30_DAYS"} onClick={() => {
            // defaultChecked={'/Ats/Admin/lastdoc'.toLowerCase() == location.pathname.toLowerCase()}
            // navigate('/Ats/Admin/lastdoc')
            // add filter here in place of direct redirecting to /Ats/Admin/lastdoc
            setFilters({ 'app_invitation_type': 2, "dashboardOption": 1, "inbox_type": "DOCUPLOAD_LAST_30_DAYS" }, ['app_invitation_type', 'dashboardOption', 'inbox_type']);
            unselectAll();
          }} name="a-oprtions" id="c2" />
          <span>
            DA Last 30 Days
            {getlastthirtydayDocCount ? <div className="total_count">
              {getlastthirtydayDocCount ? getlastthirtydayDocCount : " "}</div> : <div className="total_count">0</div>}
          </span>
        </label>
        <label htmlFor="c4">
          <input type="radio" defaultChecked={filterValues.inbox_type == "ATS_SCREENING_QUEUE"} onClick={() => {
            // navigate('/Ats/Admin/getatsqueue')
            // defaultChecked={'/Ats/Admin/getatsqueue'.toLowerCase() == location.pathname.toLowerCase()}
            // add filter here in place of direct redirecting to /Ats/Admin/getatsqueue
            setFilters({ 'app_invitation_type': 1, "dashboardOption": 1, "inbox_type": "ATS_SCREENING_QUEUE" }, ['app_invitation_type', 'dashboardOption', 'inbox_type']);
            unselectAll();
          }} name="a-oprtions" id="c4" />
          <span>
            Screening 
            {getatsqueueTotal ? <div className="total_count">
              {getatsqueueTotal ? getatsqueueTotal : " "}</div> : <div className="total_count">0</div>}

          </span>
        </label>

      </div>
    </>
  );
}
