import FilterReports from "../../componets/SubscriberManagementReport/FilterReports";
import ActionsReports from "../../componets/SubscriberManagementReport/ActionsReports";
import ActionsReportsLeadMag from "../../componets/SubscriberManagementReport/ActionsReportsLeadMag";
import { Button, Datagrid, DateField, FunctionField, List, ListBase, ListGuesser, ListToolbar, NumberField, Pagination, Resource, TextField, useListContext, useStore } from 'react-admin';
import {
    Box, TextField as MuiTextField,
    Button as MuiButton, Grid
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { useState } from 'react';
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
// import FilterModal from '../manageSubscribers/FilterModal';
import { useForm, FormProvider } from "react-hook-form";
// import InputDateField from '../common/InputComponents/InputDateFieldOld';
import { ATS_API_URL } from '../../config';
import { debounce } from "lodash";
import React from 'react';
import ExportScreenReport from "../../componets/SubscriberManagementReport/ExportScreenReport";
import FilterChips from "../../componets/Ats/SuperAdminInbox/FilterChips";
import moment from "moment";
const AdminScreeningManagement = () => {
    return <>
       <Resource name="adminScreenManagement" list={AdminScreenmanagementList} />
    </>
}
const MyList = ({ children, filters, actions, ...props }) => {
    
  

    return (
        <ListBase
            {...props}
            perPage={100} // Number of items per page
        >
            <ListToolbar
                filters={filters}
                actions={actions}
            />
            
            <FilterChips excludeKeys={['app_invitation_type']} />
            {children}
            <Pagination rowsPerPageOptions={[100, 200, 300, 400, 500]} />
            <br />
            <br />
            <br />
            <br />
        </ListBase>
    );
};

// const page=<Pagination rowsPerPageOptions={[100, 200, 300, 400, 500]} />
export const AdminScreenmanagementList = () => {
    return (
        <>

            {/* <JSONTree data={datanew} />
                {statusCountData && <StatsAndGraph data={datanew}/>} */}
            <br />
            <br />
            <MyList
                filters={<FilterReports />}
                actions={<ExportScreenReport/>}
                sort={{ field: "ApplicationNumber", order: "DESC" }}
                // filterDefaultValues={{ subscriberID: 1 }}
                children={<DataGrid />}
            />
            {/* { (
               
            )} */}
            {/* <StatsAndGraph isAdmin={true} statusCountData={[]} subscriberCount={10} data={{ }} key={'d'} /> */}
            {/* {!loadingdocuploadmanagementstats && <StatsAndGraph data={datanew} />} */}
            {/* {datanew (
        <StatsAndGraph
          data={datanew}
        />
      )} */}


        </>
    )
}

    // <List filters={<FilterReports/>} actions={<ExportScreenReport/>} pagination={page} perPage={100} sx={{marginTop: '20px'}}>
    const DataGrid = () => {
   return( <Datagrid bulkActionButtons={false} sx={{
                    "& .RaDatagrid-rowEven": { backgroundColor: "#F9F9F9" },
                    "& .RaDatagrid-expandIcon": { color: "#fff" },
                    "& .RaDatagrid-expandIconCell": { backgroundColor: "primary.main" },
                    "& .RaDatagrid-headerCell": {
                        fontWeight: "bold",
                        fontSize: 12,
                    },
                    "& .RaDatagrid-table": {
                        fontSize: 12,
                    },
                    "& .MuiToggleButton-root.Mui-selected": {
                        fontWeight: 700,
                    },
                    marginTop: '40px'
                }}>
        <TextField source="ApplicationNumber" label={"App Id"}/>
        <FunctionField
        sortBy="AppDate"
      label="Date"
      render={(record) => {
        // Format the AppDate using moment.js
        const formattedDate = moment.utc(record.AppDate).format('MM/DD/YYYY');

        return (
          <>
            
            <p>{formattedDate}</p> {/* Display the formatted AppDate */}
            {/* <p>{record.LandLordPhone}</p> */} {/* Other data rendering if needed */}
          </>
        );
      }}
    />
        <TextField source="SubscriberName" label={"Subscriber"}/>
        <TextField source="PropertyName/OfficeName" label={"Property/Office"} />
        <TextField source="ApplicantName" label={"Applicant"}/>
        <TextField source="ApplicationFee" label={"Applicant Fee"}/>
        <TextField source="ScreeningFee" label={"Screening Fee"}/>
        <TextField source="ScreeningCompanyName" label={"Screening Company"}/>
    
        
        <TextField source="ScreeningCompanyAccountNumber" label={"Account Number"}/>
    </Datagrid>)

    
            }
export default AdminScreeningManagement;