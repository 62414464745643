import { useGetList, useGetOne } from "react-admin";
import { JSONTree } from "react-json-tree";
import { useLocation } from "react-router-dom";
import parse from "html-react-parser";
import ApplyRental from "./ApplyRental";
import { Card, CardContent, CardMedia, Typography, CardActionArea } from '@mui/material';
const ViewAdverseLetter = () => {
    const location = useLocation();
    const record = location.state;
    const { data: applicantData, isLoading } = useGetList(
        "get_subscriber_inbox_detials",
        {
            filter: { ApplicationNumber: record?.id, subscriberid: record?.SubscriberID },
        }
    );
    const applicantId = applicantData?.filter(item => item.ApplicantType == 'Applicant')[0]?.id;
    const { data } = useGetOne('viewadverseletter', { id: applicantId, meta: { ApplicationStatus: record?.ApplicationStatus } }, { enabled: !isLoading && !!applicantId });
    return <>
        <div className="home_content">
            <div className="row">
                <div className="col-12">
                    <Card>
                        <CardContent>
                            <ApplyRental edit={true} data={record} />
                        </CardContent>
                    </Card>
                </div>
            </div>
            <br/>
            <br/>
            {parse(data?.data || "")}
        </div>
        {/* <JSONTree data={record} />
        
        <JSONTree data={applicantData?.filter(item=>item.ApplicantType=='Applicant')[0].id} /> */}

        {/* <JSONTree data={data.data} /> */}
    </>
}



export default ViewAdverseLetter;