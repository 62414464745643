import FilterReports from "../../componets/SubscriberManagementReport/FilterReports";
import ActionsReports from "../../componets/SubscriberManagementReport/ActionsReports";
import ActionsReportsLeadMag from "../../componets/SubscriberManagementReport/ActionsReportsLeadMag";
import { Button, Datagrid, DateField, FunctionField, List, ListBase, ListGuesser, ListToolbar, NumberField, Pagination, Resource, TextField, useListContext, useStore } from 'react-admin';
import {
    Box, TextField as MuiTextField,
    Button as MuiButton, Grid
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { useState } from 'react';
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
// import FilterModal from '../manageSubscribers/FilterModal';
import { useForm, FormProvider } from "react-hook-form";
// import InputDateField from '../common/InputComponents/InputDateFieldOld';
import { ATS_API_URL } from '../../config';
import { debounce } from "lodash";
import React from 'react';
import StatsAndGraphVerification from "../../componets/SubscriberManagementReport/VerificationReportCount";
import FilterChips from "../../componets/Ats/SuperAdminInbox/FilterChips";
import moment from "moment";
const AdminVerificationManagement = () => {
    return <>
       <Resource name="adminVerificationmgt" list={AdminVerificationmanagementList} />
    </>
}
const bgStyle = (condition: string) => {
    let style = {};
    switch (condition) {
        case "CompletedConditionallyApproved":
            style = { backgroundColor: "#FFF8E2", color: "#F9BB04" };
            break;
        case "NA":
            style = { backgroundColor: "#E9E9E9", color: "#909090" };
            break;
        case "Completed-Unacceptable":
            style = { backgroundColor: "#F8ECE2", color: "#FF4848" };
            break;
        case "Completed-Approved":
            style = { backgroundColor: "#E6FDF0", color: "#46A96F" };
            break;
        case "Invited":
            style = { backgroundColor: "#E9E9E9", color: "#909090" };
            break;
        case " In Progress":
            style = { backgroundColor: "#DDF8FF", color: "#1CA2C2" };
            break;
        case "Submitted":
        case "Review Pending":
            style = { backgroundColor: "#B0EFFF", color: "#127790" };
            break;
        // case "Screening":
        case "Completed":
        case "Valid":
        // case "Completed-Approved":
        case "Verified":
            style = { backgroundColor: "#E6FDF0", color: "#46A96F" };
            break;
        case "Cancelled":
        case "Archived":
        // case "Completed-Unacceptable":
        case "Invalid":
        case "Unverifiable":
            style = { backgroundColor: "#FFEEEE", color: "#FF4848" };
            break;
        case "Completed-Conditionally Approved":
        case "CompletedConditionallyApproved":
        case "Further Documentation Required":
        case "Verification Required":
        case "Further Doc Req.":
            style = { backgroundColor: "#FFF8E2", color: "#F9BB04" };
            break;
        default:
            style = {};
    }
    return style;
};
// export const Count=()=>{
// return(
// <StatsAndGraphVerification/>
// )
// }
const MyList = ({ children, filters, actions, ...props }) => {
    
  

    return (
        <ListBase
            {...props}
            perPage={100} // Number of items per page
            
        >
            
            <ListToolbar
                filters={filters}
                actions={actions}
            />
            
            <StatsAndGraphVerification/>
            <FilterChips excludeKeys={['app_invitation_type','subscriberID']} />
            {children}
            <Pagination rowsPerPageOptions={[100, 200, 300, 400, 500]} />
            <br />
            <br />
            <br />
            <br />
        </ListBase>
    );
};
const page=<Pagination rowsPerPageOptions={[100, 200, 300, 400, 500]} />
export const AdminVerificationmanagementList = () => {
    
    
    return (
        <>

            {/* <JSONTree data={datanew} />
                {statusCountData && <StatsAndGraph data={datanew}/>} */}
            <br />
            <br />
            <MyList
                filters={<FilterReports />}
                actions={<ActionsReportsLeadMag/>}
                sort={{ field: "ApplicationNumber", order: "DESC" }}
                filterDefaultValues={{ subscriberID: 1 }}
                children={<DataGrid />}
            />
            {/* { (
               
            )} */}
            {/* <StatsAndGraph isAdmin={true} statusCountData={[]} subscriberCount={10} data={{ }} key={'d'} /> */}
            {/* {!loadingdocuploadmanagementstats && <StatsAndGraph data={datanew} />} */}
            {/* {datanew (
        <StatsAndGraph
          data={datanew}
        />
      )} */}


        </>
    )
}
    const DataGrid = () => {
    return(<Datagrid bulkActionButtons={false} sx={{
                    "& .RaDatagrid-rowEven": { backgroundColor: "#F9F9F9" },
                    "& .RaDatagrid-expandIcon": { color: "#fff" },
                    "& .RaDatagrid-expandIconCell": { backgroundColor: "primary.main" },
                    "& .RaDatagrid-headerCell": {
                        fontWeight: "bold",
                        fontSize: 12,
                    },
                    "& .RaDatagrid-table": {
                        fontSize: 12,
                    },
                    "& .MuiToggleButton-root.Mui-selected": {
                        fontWeight: 700,
                    },
                }}>
    <TextField source="ApplicationNumber" label={'APP ID'} />
    <FunctionField
    sortBy="AppDate"
      label="Date"
      render={(record) => {
        // Format the AppDate using moment.js
        const formattedDate = moment.utc(record.AppDate).format('MM/DD/YYYY');

        return (
          <>
            
            <p>{formattedDate}</p> {/* Display the formatted AppDate */}
            {/* <p>{record.LandLordPhone}</p> */} {/* Other data rendering if needed */}
          </>
        );
      }}
    />
    <TextField source="SubscriberName" label={"Subscriber"} />
    <TextField source="State" />
    <TextField source="City" />
    <FunctionField
    
            label={"Status"}
            source="Overall Status"
            render={(record) => {
                return (
                    <MuiButton
                        sx={{ width: "50%" }}
                        variant="contained"
                        disableElevation={true}
                        style={bgStyle(record["Overall Status"])}
                    >
                        {record["Overall Status"]}
                    </MuiButton>
                );
            }}
        />
    
    
    </Datagrid>)
}

export default AdminVerificationManagement;