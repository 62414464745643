import React, { useContext, useEffect, useState } from "react";
import {
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  useNotify,
  useRefresh,
  Toolbar,
  useDataProvider,
  useCreate,
  CheckboxGroupInput,
  BooleanInput,
  usePermissions,
} from "react-admin";
import AddIcon from "@mui/icons-material/Add";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { useMutation, useQueryClient } from 'react-query';
import Button from '@mui/material/Button';
import { useSelector } from "react-redux";
import { useWatch, useFormContext } from 'react-hook-form';
import { CheckCircle } from "@mui/icons-material";
import { JSONTree } from "react-json-tree";

function AddDocuverusCommetApplicant({
  label,
  title,
  trn_id,
  document_id,
  is_applicant,
  // fetchRecord,
  tag,
  invalidateQueries,
  emp_id,
  role,
  defaultValue = "",
  defaultValueInternal = "",
}) {
  const [showDialog, setShowDialog] = useState(false);

  const notify = useNotify();
  const dataProvider = useDataProvider();
  // const [loading, setLoading] = React.useState(false);
  const queryClient = useQueryClient()

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  // fix the co-applicant issue
  const trnId = trn_id;  //useSelector((state: RootState) => state.users.userTrnId)
  const [create, status] = useCreate();

  const handleSubmit = (values) => {
    create("saveSOCRComment", {
      data: emp_id ? {
        comment: values.comment,
        trn_id: trnId,
        document_id: document_id,
        isApplicant: is_applicant,
        emp_id: emp_id,
        isinternal: values?.isinternal || 0
      } : {
        comment: values.comment,
        trn_id: trnId,
        document_id: document_id,
        isApplicant: is_applicant,
        isinternal: values?.isinternal || 0
      }
    }, {
      onSuccess: (data) => {
        queryClient.invalidateQueries([`${invalidateQueries}`])
        notify(`Comment Added Successfully`, { type: "success", anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        setShowDialog(false);
      },
      onError: (data) => {
        notify(`Fail to save comment`, { type: "error", anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        setShowDialog(false);
      }
    })

  };




  return (
    role === 'applicant' || role === 'Applicant' || role === 'Docuverus Super Admin' || !is_applicant ?
      <>
        <a
          href="#"
          className="btn btn-link"
          style={{
            // fontFamily: '"Poppins", sans-serif',
            textDecoration: 'none',
            color: '#2dc3e8',
            fontFamily: 'OpenSans-Bold'
          }}
          onClick={(e) => {
            e.preventDefault();
            handleClick()
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chat-left-dots-fill" viewBox="0 0 16 16">
            <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793V2zm5 4a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm4 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
          </svg> {label}
        </a>
        <Dialog
          fullWidth
          open={showDialog}
          onClose={handleCloseClick}
          aria-label="Create post"
          PaperProps={{
            style: { borderRadius: 20, padding: "20px 0 0 0" },
          }}
        >
          {/* <DialogTitle>{title}</DialogTitle> */}
          <div className="modal-header px-3 ">
            <h5 className="modal-title" id="emailchange">
              {title}
            </h5>
            <a
              href="#"
              className="btn-close"
              // data-bs-dismiss="modal"
              // aria-label="Close"
              onClick={(e) => {
                e.preventDefault()
                handleCloseClick()
              }}
            ></a>
          </div>
          <DialogContent>
            <SimpleForm
              onSubmit={handleSubmit}
              toolbar={
                <PostAddDocuverusCommetApplicantToolbar onCancel={handleCloseClick} />
              }
            >
              <CommentInput externalComment={defaultValue}  />
            </SimpleForm>
          </DialogContent>
        </Dialog>
      </>
      : null
  );
}

const CommentInput = ({ externalComment}) => {
  const { permissions } = usePermissions();
  const customValidation = (value, allValues, props) => {
    //  debugger;
    if (value && value.length > 5001) {
      return 'Max 5000 characters';
    }

  };
  
  return <>
    <TextInput
      validate={[customValidation]}
      defaultValue={externalComment}
      resettable
      source="comment"
      label="Add Comment"
      fullWidth
      multiline
      maxRows={4}
      minRows={3}
      variant="outlined"
    />
  </>
}

export default AddDocuverusCommetApplicant;

function PostAddDocuverusCommetApplicantToolbar({ onCancel, ...props }) {
  return (
    <Toolbar
      {...props}
      style={{
        backgroundColor: "white",
        justifyContent: "flex-end",
      }}
    >
      <CancelButton onClick={onCancel} />
      <SaveButton
        label="Save Comment"
        style={{
          color: "white",
          backgroundColor: "#2CC0E4",
        }}
      />
    </Toolbar>
  );
}

function CancelButton(props) {
  return (
    <Button variant="outlined" startIcon={<svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.99999 4.05734L8.29999 0.757339L9.24266 1.70001L5.94266 5.00001L9.24266 8.30001L8.29999 9.24267L4.99999 5.94267L1.69999 9.24267L0.757324 8.30001L4.05732 5.00001L0.757324 1.70001L1.69999 0.757339L4.99999 4.05734Z"
        fill="#2DC3E8"
      />
    </svg>} className="mx-2" {...props}>
      Cancel
    </Button>
  );
}
