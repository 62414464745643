import React, { Fragment } from "react";
import ArchiveIcon from "@mui/icons-material/Archive";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InfoIcon from "@mui/icons-material/Info";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm, FormProvider } from "react-hook-form";
import { Box, Button } from "@mui/material";
import InputField2 from "../../common/InputComponents/InputField2";
import {
  Loading,
  useCreate,
  useGetList,
  useNotify,
  useRefresh,
  useStore,

} from "react-admin";

const MarkActiveModal = (props) => {
  const [open, setOpen] = React.useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const [create] = useCreate();

  
  const {status,selectedIds,setSelectedIds} = props;

  
  // form submit handler
  const handleSubmit = (e) => {
    e.preventDefault();
   create(
    "update-template-status",
    {
      data: {
        Active: status == "Active"?true:false,
        id: selectedIds,
      },
    },
    {
      onSuccess: (data) => {
        notify(`Selected Template ${status} successfully.`, {
          type: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
        setOpen(false);
        refresh();
        setSelectedIds([]);

      },
      onError: (e: any) =>
        notify(`Operation fail:${e.message}`, {
          type: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        }),
    }
  );
  };

 
  console.log("status,selectedIds",status,selectedIds)

  const dialogTitle = (
    <DialogTitle color="error" id="alert-dialog-title">
      <InfoIcon /> Are you sure?
    </DialogTitle>
  );

  const dialogContent = (
    <DialogContent>
      <p>
        Are you sure you want to {status} the selected templates?
      </p>
        <form>
          <Box display={"flex"} justifyContent={"end"} marginTop={5}>
            <Button
              sx={{ mx: 1 }}
              onClick={() => {
                setOpen(false);
                refresh();
              }}
              variant="outlined"
              color="secondary"
              type="button"
            >
              Cancel
            </Button>
            <Button
              // startIcon={<ArchiveIcon />}
              sx={{ mx: 1 }}
              variant="contained"
              type="submit"
              onClick={handleSubmit}
            >
              {status}
            </Button>
          </Box>
        </form>
    </DialogContent>
  );

  return (
    <Fragment>
      <Button
        onClick={() => setOpen(true)}
        variant="outlined" 
        color="primary"
        sx={{ textTransform: "capitalize" }}
      >
        Mark as {status}
      </Button>
      <Dialog
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {dialogTitle && dialogTitle}
        {dialogContent && dialogContent}
      </Dialog>
    </Fragment>
  );
};

export default MarkActiveModal;
