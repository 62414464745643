import {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useCallback,
  useMemo,
  useContext,
} from "react";
import {
  AccordionDetails,
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Stack,
} from "@mui/material";
import { useFieldArray, FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useDataProvider,
  useNotify,
  Confirm,
  useGetIdentity,
  useGetOne,
  useStore,
  useRefresh,
} from "react-admin";
import YesNoRadioButton from "../../../componets/common/InputComponents/YesNoRadioButton";
import NoDocReasonRadioButtons from "../../common/InputComponents/NoDocReasonRadioButtons";
import InputField2 from "../../common/InputComponents/InputField2";
import overa from "../../../assets/images/overa.svg";
import redTick from "../../../assets/images/redTick.svg";
import greenTick from "../../../assets/images/greenTick.svg";
import Card from "@mui/material/Card";
import { CardHeader } from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import AlertDialogEmp from "../../common/AlertDialogEmp";
import { NumericFormat } from "react-number-format";
import debounce from "lodash.debounce";
import debouce from "lodash.debounce";
import { set, throttle } from 'lodash';
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useSelector } from "react-redux";
import { RootState } from "../../../Store/store";
import { useParams } from "react-router-dom";
import EmployerSource from "./EmployerSource";
import { JSONTree } from "react-json-tree";
import { useUserRole } from "../../../ContextApi/UserRoleContext";
export interface ValidationMessageStatu {
  type: string;
  key: boolean;
  message: string;
}

export interface Earning {
  ID: string;
  earning_name: string;
  earning_type: string;
  current: number;
  ytd: number;
  calculatedCurrent: number;
  calculatedYtd: number;
}

export interface Deduction {
  ID: string;
  deduction_name: string;
  deduction_type: string;
  current: number;
  ytd: number;
  calculatedCurrent: number;
  calculatedYtd: number;
}

export interface SummaryTable {
  ID: string;
  table_header: string;
  displayedCurrent: number;
  displayedYtd: number;
  calculatedCurrent: number;
  calculatedYtd: number;
}

export interface File {
  income_row_id: string;
  application_document_id: string;
  path: string;
  path_image: string;
  file_name: string;
  Start_date: Date;
  End_date: Date;
  Pay_date: Date;
  Pay_frequency: string;
  Gross_Pay: number;
  Net_Pay: number;
  doc_state_type: string;
  calculated_net_pay: number;
  calculated_gross_pay: number;
  gross_pay_ytd?: any;
  annual_income: number;
  monthly_income: number;
  All_text?: any;
  is_Duplicate: number;
  paystub_type: string;
  socr_error_fields: any[];
  isActive: boolean;
  OCRcomplted: boolean;
  isReprocess: boolean;
  isFlagPaystub: boolean;
  isFlagSocrFields: boolean;
  earnings: Earning[];
  deduction: Deduction[];
  summaryTable: SummaryTable[];
}

export interface Employer {
  id: string;
  application_id: string;
  applicant_id: string;
  subscriber_id: string;
  employer_name: string;
  position: string;
  phone_no?: any;
  employer_address: string;
  employer_city: string;
  employer_state: string;
  employer_status: string;
  employer_zipcode: string;
  employment_start_date?: any;
  employment_end_date?: any;
  iscurrent_employer: boolean;
  isfuture_employer: boolean;
  manager_name: string;
  no_doc_reason?: any;
  other_reason?: any;
  total_income?: any;
  total_annual_income?: any;
  monthly_avr_gross?: any;
  validation_message_status: ValidationMessageStatu[];
  avrgross_pay?: any;
  pay_frequency: string;
  files: File[];
  EmployeeFirstName?: any;
  EmployeeMiddleName?: any;
  EmployeeLastName?: any;
  EmployerAddress: string;
  EmployeeState?: any;
  EmployeeCity?: any;
  CalculatedTotalIncome: number;
  CalculatedTotalAnnualIncome: number;
  EmployerCode?: any;
  EmployeeID?: any;
  EmployerNumber: number;
  ReportedTotalIncome?: any;
  ReportedTotalAnnualIncome?: any;
  isReportedEqualsMonthly: boolean;
  CreatedBy: string;
  CreatedOn: Date;
  ModifiedBy?: any;
  ModifiedOn?: any;
  section_comment: string;
  isReprocess: number;
  correctedDocType: any;
  allowOfferLetter?: any;
  availableToUpload: any;
  fileCount: any;
}

export interface SOCRstatus {
  Source_1?: any;
  Source_2?: any;
  Source_3?: any;
}

export interface RootObject {
  Question: string;
  response: string;
  no_doc_reason?: any;
  other_reason?: any;
  employer: Employer[];
  SOCRstatus: SOCRstatus;
}

const ProofOfEmploymentIncome = forwardRef((props: any, ref) => {
  console.log(props, "proof of income")
  // return <JSONTree data={props}/>;
  // Fix the co-applicant issue
  const { role: userRoleContext } = useUserRole();
  const trn_id = props.IncomeAPIResponse.data.trn_id; // useSelector((state: RootState) => state.users.userTrnId);
  // const isGuestCard = useSelector((state: RootState) => state.app.isGuestCard);
  // const IncomeAPIResponse = useGetOne(
  //   "income",
  //   { id: trn_id },
  //   { enabled: !isGuestCard && trn_id != null, refetchOnWindowFocus: false }
  // );
  const { IncomeAPIResponse, LookbacksData } = props;
  const { id, appType } = useParams();
  const invitationType: any = appType;
  const [filePreview, setFilePreview] = useState({
    proof_of_income: "",
    proof_of_income_filePath: "",
  });
  const dataProvider = useDataProvider();
  const queryClient = useQueryClient();
  const notify = useNotify();
  const isOpeningForDashboard = useSelector(
    (state: RootState) => state.app.isOpeningForDashboard
  );
  const roleRedux: any = useSelector((state: RootState) => state.users.role);
  const role = userRoleContext || roleRedux;
  const [mlClassDetails, setMlClassDetails] = useState([]);

  const submitButtonRef = useRef<HTMLButtonElement>(null);

  useImperativeHandle(ref, () => ({ handleClickByRef, handleSubmitManual }));

  const localSchema = Yup.object({
    response: Yup.string().required("required").typeError("required"),
    employer: Yup.array().when("response", {
      is: (value) => {
        return value === "Yes";
      },
      then: Yup.array()
        .of(
          Yup.object().shape({
            files: Yup.array().min(1, "At Least 1 Document Is Required"),
          })
        )
        .min(1, "must have atleast 1 records"),
    }),
    no_doc_reason: Yup.string().when("response", {
      is: (value) => {
        return value == "No";
      },
      then: Yup.string().typeError("required").required("Required"),
      otherwise: Yup.string().nullable(),
    }),
    other_reason: Yup.string().when("response", {
      is: (value) => {
        return value == "No";
      },
      then: Yup.string().when("no_doc_reason", {
        is: (value) => {
          return value == "other";
        },
        then: Yup.string()
          .typeError("required")
          .required("Required")
          .matches(/^$|^\S+.*/g, "Enter a valid value"),
        otherwise: Yup.string().nullable(),
      }),
      otherwise: Yup.string().nullable(),
    }),
  });

  const schema = Yup.object({
    response: Yup.string().required("required").typeError("required"),
  });

  const schemaNotRequired = Yup.object({
    response: Yup.string().nullable(),
  });

  const getSchema = () => {
    if (props.required) {
      if (role === "applicant") {
        return localSchema;
      } else {
        return schema;
      }
    } else {
      return schemaNotRequired;
    }
  };

  const methods = useForm<RootObject>({
    resolver: yupResolver(getSchema()),
    mode: "onChange",
  });
  // const methods = useForm<RootObject>();

  async function handleClickByRef() {
    submitButtonRef?.current?.click();
    const isValidForm = await methods.trigger();

    return isValidForm;
  }

  const { identity, isLoading: identityLoading } = useGetIdentity();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [disableAddSource, setDisableAddSource] = useState(true);
  const [disableAddSourceoption, setDisableAddSourceoption] = useState(false);
  const [confirmDeleteSource, setConfirmDeleteSource] = useState(false);
  const [employerNumber, serEmployerNumber] = useState<any>(0);
  const [hasExecuted, setHasExecuted] = useState(false);
  const [mannualSOCRstatus, setMannualSOCRstatus] = useState({
    Source_1: null,
    Source_2: null,
    Source_3: null,
  });

  const [socrStatus, setSocrStatus] = useState({
    Source_1: {
      SOCRStatus: null,
      concurrent: null,
      current: null,
      look_back: null,
      look_back_period: "",
      name_match: null,
      no_of_weeks: "",
      total_gross: 0,
    },
    Source_2: {
      SOCRStatus: null,
      concurrent: null,
      current: null,
      look_back: null,
      look_back_period: "",
      name_match: null,
      no_of_weeks: "",
      total_gross: 0,
    },
    Source_3: {
      SOCRStatus: null,
      concurrent: null,
      current: null,
      look_back: null,
      look_back_period: "",
      name_match: null,
      no_of_weeks: "",
      total_gross: 0,
    },
  });

  const { control, getValues, watch } = methods;
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: "employer", // unique name for your Field Array
      keyName: "fieldId",
    }
  );

  const { data: meta_d_response } = useGetOne(
    "getMetaData",
    { id: 3, meta: { trn_id } },
    { refetchOnWindowFocus: false }
  );

  const fetchMlclassDetails = async () => {
    try {
      let data: any = {};
      data = await dataProvider.paystubProcessing({
        subUrl: "/document/GetMLclassDetails",
        DocumentTypeID: 3,
        method: "POST",
      });
      let mlClassArray = data?.data?.records?.map((record) => ({
        value: record.ID,
        label: record.ClassName,
      }));
      setMlClassDetails(mlClassArray);
    } catch (err) {
      notify("Error fetching emplyment details", {
        type: "warning",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    }
  };

  useEffect(() => {
    fetchMlclassDetails();
  }, []);

  const [adminValidationMessages, setAdminValidationMessages] = useState({
    Source_1: [],
    Source_2: [],
    Source_3: [],
  } as any);

  const [applicantValidationMessages, setApplicantValidationMessages] =
    useState({
      Source_1: [],
      Source_2: [],
      Source_3: [],
    } as any);

  useEffect(() => {
    if (meta_d_response?.records && role !== "applicant") {
      const { validation_messages, other_check } = meta_d_response?.records;
      if (validation_messages) {
        const admin_messages_source_1 = validation_messages?.source_1.map(
          (message) => ({
            ...message.admin,
          })
        );
        const admin_messages_source_2 = validation_messages?.source_2.map(
          (message) => ({
            ...message.admin,
          })
        );
        const admin_messages_source_3 = validation_messages?.source_3.map(
          (message) => ({
            ...message.admin,
          })
        );
        const admin_validation_messages = {
          Source_1: admin_messages_source_1,
          Source_2: admin_messages_source_2,
          Source_3: admin_messages_source_3,
        };
        setAdminValidationMessages(admin_validation_messages);
      }
      if (other_check) {
        setSocrStatus(other_check);
      }
    }
  }, [meta_d_response]);

  const {
    mutate: updateEmployer,
    isLoading: loadingupdateEmployer,
    isSuccess: updateEmployerSuccesse,
  } = useMutation(
    "updateEmployer",
    (data: any) =>
      dataProvider.update("other_sections", {
        id: data.id,
        data: data.data,
        previousData: data.previousData,
      }),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["getMetaData"]);
        queryClient.invalidateQueries({ queryKey: ['income'] })
        if (role !== "applicant") {

          notify("Employer details successfully saved.", {
            type: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          // refresh();
        }
      },
      onError: (data) => {
        alert("Fail updating employer details");
      },
      onMutate: () => {
        // debugger;
        notify("Updating employer details...", {
          type: "info",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
        setDisableAddSourceoption(false);
        setDisableAddSource(true);
      },
    }
  );

  // const onSubmitEmpty = (val) => null;

  const handleSubmitManual = () => {
    if (role === "applicant") {
      submitButtonRef?.current?.click();
    } else {
      OnSubmitIncome(methods.getValues());
    }
  };

  const OnSubmitIncome = (value) => {
    if (value.response === "Yes") {
      const more_props = {
        availableToUpload: "Yes",
      };

      const payload = value.employer.map((obj) => {
        const filesObjArray = obj.files.map((fileObj) => ({
          ...fileObj,
          Pay_frequency: fileObj.Pay_frequency.value,
        }));
        return {
          ...obj,
          ...more_props,
          files: filesObjArray,
          fileCount: filesObjArray.length,
          other_reason:
            obj?.availableToUpload !== "Yes" && obj?.no_doc_reason == "Other"
              ? obj?.other_reason
              : null,
          phone_no: obj?.country_code
            ? obj.phone_no.split(obj?.country_code)[1]
              ? obj.phone_no
              : null
            : obj.phone_no,
          no_doc_reason:
            obj?.availableToUpload !== "Yes" ? obj?.no_doc_reason : null,
          // total_income : invitationType == "1" ? obj?.total_income : averageMonthlyIncome,
        };
      });
      // debugger;
      const final_paylaod = {
        employment_details: {
          ...value,
          employer: payload,
          employerCount: payload.length,
          no_doc_reason: null,
          response: "Yes",
        },
        trn_id: trn_id,
        subUrl: "/income/SaveEmployerSection",
      };

      delete final_paylaod.employment_details.SOCRstatus;

      updateEmployer({
        id: trn_id,
        data: final_paylaod,
        previousData: [],
      });
    } else {
      const final_paylaod = {
        employment_details: {
          Question: "Are you currently employed (or self employed)?",
          response: "No",
          no_doc_reason:
            value.no_doc_reason ||
            "I do not currently have access to document(s), but can provide later",
          other_reason:
            value?.response !== "Yes" &&
              value?.no_doc_reason?.toLowerCase() == "other"
              ? value.other_reason
              : null,
          employer: [],
          employerCount: 0,
        },
        trn_id: trn_id,
        subUrl: "/income/SaveEmployerSection",
      };

      updateEmployer({
        id: trn_id,
        data: final_paylaod,
        previousData: [],
      });
    }
    // methods.reset(value);
  };
  // const formsChildContext = useContext(FormsChildContext);
  useEffect(() => {
    // formsChildContext?.updateFormAtIndex(props.index,{ ...formsChildContext?.forms, incomeSection: methods.formState?.errors })
    props.setChildFormError(methods.formState?.errors);
  }, [methods.formState]);
  const [disableNewBtn, setdisableNewBtn] = useState(false);
  const {
    mutate: createNewEmployer,
    isLoading: loadingcreateNewEmployer,
    isSuccess: createNewEmployerSuccesse,
  } = useMutation(
    "createNewEmployer",
    (data: any) => {
      console.log(data, "data passed to mutation"); // Log the data being passed to the mutation
      return dataProvider.create("income", {
        data: {
          document_id: data.document_id,
          source_id: data.source_id,
          trn_id: data.trn_id,
        },
      });
    },
    {
      onSuccess: (data) => {
        setDisableAddSourceoption(true);
        console.log(data, "data returned from mutation"); // Log the data returned from the mutation
        queryClient.invalidateQueries(["income"]);
        notify("New employer added successfully", {
          type: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
        if (methods.watch("employer").length === 1) {
          setHasExecuted(false);
        }
        // setdisableNewBtn(false);
      },
      onError: (error) => {
        console.log(error, "error from mutation"); // Log the error if the mutation fails
        alert("Failed adding new employer");
        setdisableNewBtn(false);
      },
      onMutate: () => {
        notify("Adding new employer...", {
          type: "info",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      },
    }
  );
  const {
    mutate: deleteEmployer,
    isLoading: loadingdeleteEmployer,
    isSuccess: createdeleteEmployer,
  } = useMutation(
    "deleteEmployer",
    (data: any) =>
      dataProvider.deleteSequence("income", {
        document_id: data.document_id,
        source_id: data.source_id,
        trn_id: data.trn_id,
        subUrl: data.subUrl,
      }),
    {
      onSuccess: (data) => {
        // serEmployerNumber(0);
        setConfirmDeleteSource(false);
        queryClient.invalidateQueries(["income"]);
        notify("Employer deleted successfully", {
          type: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
        if (methods.watch("employer").length === 1) {
          methods.setValue("response", "No");
          handleSubmitDocReason("No")
          setHasExecuted(false);
        }
      },
      onError: (data) => {
        // serEmployerNumber(0);
        setConfirmDeleteSource(false);
        alert("Fail deleting employer");
      },
      onMutate: () => {
        notify("Deleting employer...", {
          type: "info",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
        setConfirmDeleteSource(false);
      },
    }
  );

  const {
    mutate: deleteAllEmployer,
    isLoading: loadingAllEmployer,
    isSuccess: AllEmployeSuccess,
  } = useMutation(
    "deleteAllEmployer",
    (data: any) => {
      let promises: any = [];
      data.employer?.map(async (emplyr) => {
        promises.push(
          console.log(emplyr, "emplyr"),
          dataProvider.deleteSequence("income", {
            document_id: 3,
            source_id: emplyr?.id,
            trn_id: data?.trn_id,
            subUrl: "/income/deleteSource",
          })
        );
      });
      return Promise.all(promises);
    },
    {
      onSuccess: (data) => {
        handleSubmitDocReason("No");
        setConfirmDelete(false);
        setDisableAddSourceoption(false);
        // queryClient.invalidateQueries(["income"]);
        notify("Employer deleted successfully", {
          type: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      },
      onError: (data) => {
        setConfirmDelete(false);
        // alert("Fail deleting employer");
        notify("Fail deleting employer", {
          type: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      },
      onMutate: () => {
        notify("Deleting employer...", {
          type: "info",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
        setConfirmDelete(false);
      },
    }
  );

  const {
    mutate: employerProcessing,
    mutateAsync: employerProcessingAsyc,
    isLoading: deleteemployerProcessing,
    isSuccess: employerProcessingSuccess,
  } = useMutation(
    "employerProcessing",
    (data: any) => dataProvider.paystubProcessing({ ...data }),
    {
      onSuccess: (data) => {
        console.log(methods.watch("employer").length, "emplength")
        queryClient.invalidateQueries(["income"]);
        notify("Employer deleted successfully...", {
          type: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
        if (methods.watch("employer").length === 1) {
          methods.setValue("response", "No");
          handleSubmitDocReason("No")
          setHasExecuted(false);
        }
      },
      onError: (data) => {
        setConfirmDeleteSource(false);
        // alert("Fail reprocessing employer1");
      },
      onMutate: () => {
        notify("Employer deleting...", {
          type: "info",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
        setConfirmDeleteSource(false);
      },
    }
  );

  // const handleAddEmployer = () => {
  //   createNewEmployer({
  //     document_id: 3,
  //     source_id:
  //       IncomeAPIResponse?.data?.employment_details?.employer?.length + 1,
  //     trn_id: trn_id,
  //   });
  // };

  useEffect(() => {
    console.log("IncomeAPIResponse", IncomeAPIResponse)
    console.log("test new", IncomeAPIResponse?.data?.employment_details?.employer?.length)
    setdisableNewBtn(false);
  }, [fields.length])

  // const handleAddEmployer = useCallback(

  //   throttle(() => {
  //     if(methods.watch("employer")?.length < 3){
  //       console.log("IncomeAPIResponse",IncomeAPIResponse)
  //       createNewEmployer({
  //         document_id: 3,
  //         source_id: IncomeAPIResponse?.data?.employment_details?.employer?.length + 1,
  //         trn_id: trn_id,
  //       });
  //     }
  //    //setdisableNewBtn(false);    
  //   }, 4000), 
  //   [methods,IncomeAPIResponse]
  // );

  // useEffect(() => {
  //   const subscription = watch((value, { name, type }) => {
  //     // console.log("NAMECHANGE:156", name);
  //     // debouncedSave(name);
  //     if (type === "change" && name === `response`) {
  //       if (getValues("response") === "Yes") {
  //         console.log("fields.length", fields.length)
  //         createNewEmployer({
  //           trn_id: trn_id,
  //           document_id: "3",
  //           source_id: fields.length + 1,
  //         });
  //       }
  //     }
  //   });

  //   return () => subscription.unsubscribe();
  // }, [createNewEmployer, fields.length + 1, getValues, trn_id, watch]);
  console.log(IncomeAPIResponse?.data?.employment_details?.employer?.length, "lenght567")

  // let hasExecuted = false;
  const handleAddEmployerYesNo = async () => {
    if (hasExecuted) return;
    const payload = {
      document_id: 3,
      source_id: 1,
      trn_id: trn_id,
    };

    // createNewEmployer(payload);
    // setDisableAddSourceoption(false);
    try {
      await createNewEmployer(payload);
      setDisableAddSourceoption(false); // Set after success
    } catch (error) {
      console.error("Error in creating new employer:", error);
    }

    setHasExecuted(true); // Ensure function won't be called again

  };


  const handleAddEmployerAPIDebounced = useCallback(
    debounce(
      (nextValue) =>
        (async () => {
          handleAddEmployerYesNo();
        })(),
      1000
    ),
    [] // will be created only once initially
  );

  // useEffect(() => {
  //   console.log(props?.IncomeAPIResponse?.data?.employment_details?.employer?.length, "length");
  //   console.log(watch("employer").length, "length1");
  //   if (watch("employer").length === 0)


  //     methods.setValue("response", "No");
  //   handleSubmitDocReason("No");
  // }, [props?.IncomeAPIResponse?.data?.employment_details?.employer?.length]);
  const [buttonVisible, setButtonVisible] = useState(true);
  const handleDeleteEmployer = () => {

    if (employerNumber !== 0) {
      employerProcessing({
        document_id: 3,
        source_id: employerNumber,
        trn_id: trn_id,
        subUrl: "/income/deleteSource",
      });
      setButtonVisible(false);
      setTimeout(() => {
        setButtonVisible(true);
      }, 5000);
      // .then(_ => {
      //   handleAPIDebounced();
      // })
    }

  };

  const handleSubmitDocReason = async (value) => {
    console.log("hieeeeeeeeeeee")
    const final_paylaod = {
      employment_details: {
        Question: "Are you currently employed (or self employed)?",
        response: value,
        no_doc_reason: value.no_doc_reason
          ? value.no_doc_reason
          : invitationType == "1"
            ? "I am not currently employed"
            : "I do not currently have access to document(s), but can provide later",
        other_reason: value.other_reason,
        employer: [],
        employerCount: 0,
      },
      trn_id: trn_id,
      subUrl: "/income/SaveEmployerSection",
    };
    console.log(final_paylaod, "gonsajn")
    updateEmployer({
      id: trn_id,
      data: final_paylaod,
      previousData: [],
    });
  };

  const handleDeleteAllEmployer = () => {

    console.log("delete,", IncomeAPIResponse?.data?.employment_details?.employer)
    deleteAllEmployer({
      employer: IncomeAPIResponse?.data?.employment_details?.employer,
      trn_id: trn_id,
    });
    remove()
    console.log("deleteAfter,", IncomeAPIResponse?.data?.employment_details?.employer)
  };

  const handleReprocessEmployer = async (index, employer_id) => {
    //
    const paystub_type = methods.watch(`employer.${index}.correctedDocType`);
    if (paystub_type) {
      employerProcessing({
        subUrl: "/income/reprocessEmployer",
        trn_id: trn_id,
        document_id: "3",
        employer_id: employer_id,
        new_type: paystub_type?.value,
        method: "POST",
      });
      //
    } else {
      methods.setError(
        `employer.${index}.correctedDocType`,
        { type: "required", message: "required" },
        { shouldFocus: true }
      );
    }
  };

  const getSrcfromStatus = (socrValue) => {
    if (socrValue) {
      switch (Number(socrValue)) {
        case 1:
          return greenTick;
        case 2:
          return redTick;
        case 3:
          return overa;
      }
    } else {
      return overa;
    }
  };

  const getClassfromStatus = (socrValue) => {
    //
    if (socrValue) {
      switch (Number(socrValue)) {
        case 1:
          return "green-block bg-success";
        case 2:
          return "red-block bg-danger";
        case 3:
          return "brwn-block bg-warning";
      }
    } else {
      return "brwn-block";
    }
  };

  const gettextStylefromStatus = (socrValue) => {
    //
    if (socrValue) {
      switch (Number(socrValue)) {
        case 1:
          return "green-ltr";
        case 2:
          return "red-ltr";
        case 3:
          return "brown-ltr text-dark";
      }
    } else {
      return "brown-ltr";
    }
  };

  const handleAPIDebounced = useMemo(() => {
    return debouce(handleSubmitManual, 3000);
  }, []);

  useEffect(() => {
    if (IncomeAPIResponse?.data?.employment_details) {
      const formData = methods.getValues();
      let noDocReason: any = null;
      if (formData.response === "No") {
        noDocReason =
          IncomeAPIResponse?.data?.employment_details?.no_doc_reason ||
          "I do not currently have access to document(s), but can provide later";
      }
      const response = formData.response
        ? formData.response
        : IncomeAPIResponse?.data?.employment_details.response; //? IncomeAPIResponse?.data?.employment_details.response : props.status == 1 ? 'Yes' : null;
      const responseData = {
        ...IncomeAPIResponse?.data?.employment_details,
        employer: IncomeAPIResponse?.data?.employment_details?.employer?.map(
          (item) => {
            return {
              ...item,
              no_doc_reason:
                item?.no_doc_reason ||
                "I don't currently have access to document(s), but can provide later",
            };
          }
        ),
        no_doc_reason: IncomeAPIResponse?.data?.employment_details
          ?.no_doc_reason
          ? IncomeAPIResponse?.data?.employment_details?.no_doc_reason
          : invitationType == "1"
            ? "I am not currently employed"
            : "I do not currently have access to document(s), but can provide later",
        response: response,
      };

      Object.keys(responseData).forEach((key: any) => {
        methods.setValue(key, responseData[key]);
      });

      // methods.reset();
    }
  }, [IncomeAPIResponse?.data]);

  const [collapse, setCollapse] = useState([]);
  const grossPayAvg = (files) => {
    let avgGross = 0;
    let oneTimeGross = 0;
    // IncomeAPIResponse?.data?.employment_details.SOCRstatus[`Source_${index + 1}`] || meta_d_response?.records?.other_check[`source_${index + 1}`].SOCRStatus
    const uniqueFile: any = files
      ?.filter((item) => item.is_Duplicate !== 1)
      ?.filter((item) => item.Pay_frequency.value != "OneTime");
    const alreadyCount: string[] = [];
    if (uniqueFile)
      for (let file of uniqueFile) {
        avgGross += Number(file.monthly_income);
        if (
          !alreadyCount.includes(
            new Date(file.Start_date).toLocaleDateString("en-US") +
            "," +
            new Date(file.End_date).toLocaleDateString("en-US")
          )
        )
          alreadyCount.push(
            new Date(file.Start_date).toLocaleDateString("en-US") +
            "," +
            new Date(file.End_date).toLocaleDateString("en-US")
          );
      }

    const onetimeFile = files
      .filter((item) => item.is_Duplicate !== 1)
      .filter((item) => item.Pay_frequency.value == "OneTime");
    for (let file of onetimeFile) {
      oneTimeGross += Number(file.monthly_income);
    }
    if (!oneTimeGross && !avgGross) return 0;
    if (!alreadyCount.length) return oneTimeGross;
    return avgGross / alreadyCount.length + oneTimeGross;
  };

  function isIterable(obj) {
    // checks for null and undefined
    if (obj == null) {
      return false;
    }
    return typeof obj[Symbol.iterator] === "function";
  }

  function grossPayCal(Employe: Employer[]) {
    try {
      let sumGross = 0;
      let index = 0;
      if (isIterable(Employe))
        for (let emp of Employe) {
          if (
            (IncomeAPIResponse?.data?.employment_details?.SOCRstatus[
              `Source_${index + 1}`
            ] ||
              meta_d_response?.records?.other_check[`source_${index + 1}`]
                .SOCRStatus) != "2"
          )
            sumGross += grossPayAvg(emp.files);
          index++;
        }
      props.grossValue(sumGross.toFixed(2))
      return sumGross.toFixed(2);
    } catch (error) {
      return 0;
    }
  }

  const [fileUploading, setfileUploading] = useStore(`IncomeUpload_loading`);
  const [expandIncomeSection, setExpandIncomeSection] = useState(true);
  useEffect(() => {
    if (role === "applicant") setExpandIncomeSection(true);
  }, [role]);


  useEffect(() => {
    if (methods.watch("response") === "Yes") setExpandIncomeSection(true);
  }, [methods.watch("response")]);

  const refresh = useRefresh();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    methods.setValue("response", "Yes");
    setOpen(false);
  };
  const [userCloseBrowser, setUserCloseBrowser] = useState(false);

  // const handleBeforeUnload = (event) => {
  //   if (methods.formState.isDirty) {
  //     const confirmationMessage = 'You have unsaved changes. Do you want to save them before leaving?';
  //     event.returnValue = confirmationMessage;
  //     setUserCloseBrowser(true);
  //     return confirmationMessage;
  //   }
  // };

  // const handlePopstate = (event) => {
  //   if (methods.formState.isDirty && !window.confirm('You have unsaved changes. Do you want to leave?')) {
  //     event.preventDefault();
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener('beforeunload', handleBeforeUnload);
  //   // window.addEventListener('popstate', handlePopstate);
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //     // window.removeEventListener('popstate', handlePopstate);
  //   };
  // }, []);
  const [lastSavedData, setLastSavedData] = useState<any>(null);

  // useEffect(() => {
  //   if (methods.formState.isDirty) {
  //     const timer = setTimeout(() => {
  //       // Save data after a certain delay to prevent too frequent saving
  //         setLastSavedData(getValues());
  //         methods.handleSubmit(role === "applicant" ? OnSubmitIncome : OnSubmitIncome)();

  //     }, 10000); // Adjust the delay time as needed
  //     return () => clearTimeout(timer); // Cleanup function to clear the timer if component unmounts or form values change again
  //   }
  // }, [methods.formState.isDirty]);
  const count = methods.watch("employer")?.length > 0;
  const buttonStyle = {
    position: "fixed" /* or 'absolute' */,
    bottom: "20px" /* adjust as needed */,
    right: "20px" /* adjust as needed */,
    backgroundColor: "#007bff" /* button color */,
    color: "white" /* text color */,
    border: "none",
    borderRadius: "50%" /* make it circular */,
    padding: "15px" /* adjust as needed */,
    fontSize: "16px",
    cursor: "pointer",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" /* add shadow for depth */,
  };
  return (
    <>

      <FormProvider {...methods}>
        <form
          method="POST"
          onSubmit={methods.handleSubmit(
            role === "applicant" ? OnSubmitIncome : OnSubmitIncome
          )}
        >
          <fieldset
            disabled={
              loadingcreateNewEmployer ||
              deleteemployerProcessing ||
              fileUploading
            }
          >
            <div className="info-toggle">
              <div className="toggle-heads">
                <div
                  className="income-wrap d-flex justify-content-between align-items-center"
                  style={
                    methods.formState.isDirty && role !== "applicant"
                      ? { backgroundColor: "rgb(255 248 248)" }
                      : {}
                  }
                >
                  <div className="d-flex align-items-center">
                    <b className="mx-3">Employment Income</b>
                    <AlertDialogEmp />

                    <div className="subcontent p-1 pb-t10">
                      <div className="upload-subwrap">
                        {role == "applicant" && (
                          <p className="prf-upload">
                            <strong style={{ color: "#FF8585" }}>
                              Note :{" "}
                            </strong>
                            Upload PDF Paystubs (Earnings Statements) Here
                          </p>
                        )}
                        {role != "applicant" &&
                          methods.watch("response") !== "Yes" && (
                            <p className="prf-upload">
                              <strong style={{ color: "#FF8585" }}>
                                Note :{" "}
                              </strong>
                              No Documents uploaded
                            </p>
                          )}
                      </div>
                    </div>
                    {/* {methods.formState.isDirty && role !== "applicant" &&  <p> &nbsp;&nbsp; <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-repeat" viewBox="0 0 16 16">
                    <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41m-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9" />
                    <path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5 5 0 0 0 8 3M3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9z" />
                  </svg> save your changes</p>} */}
                  </div>

                  <div className="d-flex justify-content-end align-items-center">
                    <div className="month-inc">
                      {role !== "applicant" &&
                        methods.watch("response") == "Yes" && (
                          <b className="fw-normal sub-text">
                            <>
                              Monthly Gross Pay:{" "}
                              <NumericFormat
                                thousandSeparator={","}
                                style={{ fontSize: 16 }}
                                prefix={"$"}
                                decimalScale={2}
                                displayType={"text"}
                                value={
                                  methods
                                    .watch()
                                    ?.employer?.findIndex(
                                      (item: any) =>
                                        item.files.length > 0 &&
                                        item.employer_source_ocr_complete ==
                                        true
                                    )
                                    .toString() != "-1"
                                    ? grossPayCal(methods.watch().employer)
                                    : 0
                                }
                              />
                              &nbsp;&nbsp;
                            </>
                          </b>
                        )}
                    </div>
                    <div className="print-show">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                    {role !== "applicant" && (
                      <IconButton
                        className="m-1 print-hide"
                        onClick={() => setExpandIncomeSection((v) => !v)}
                        color="primary"
                      >
                        {!expandIncomeSection ? <AddIcon /> : <RemoveIcon />}
                      </IconButton>
                    )}
                  </div>
                </div>

                <div
                  className={` ${!expandIncomeSection ? "d-none" : "d-display"
                    } card-body cust-card-appl px-3`}
                >

                  <YesNoRadioButton
                    required={props.required}
                    // label={
                    //   "Do you have proof of employment income available to upload?"
                    // }
                    label={
                      invitationType == "1"
                        ? "Are you currently employed, self employed, or starting a new job?"
                        : "Do you have proof of employment income available to upload?"
                    }
                    source="response"
                    // disable={count || loadingcreateNewEmployer}
                    disable={!disableAddSource && loadingcreateNewEmployer || IncomeAPIResponse?.isFetching}
                    handleOnClick={(val) => {
                      console.log(val, fields?.length, "val12");
                      if (!loadingcreateNewEmployer) {

                        // let hasAddedEmployer = false;
                        if (
                          val === "Yes" &&
                          // !hasAddedEmployer &&
                          fields?.length === 0 && !hasExecuted
                        ) {

                          handleAddEmployerAPIDebounced();
                          // hasAddedEmployer = true;
                        }
                        // console.log(hasAddedEmployer,val,fields?.length  , methods.watch("response"),"hasAddedEmployer")
                        // if (
                        //   val === "No" &&
                        //   methods.watch("employer")?.length === 0 &&
                        //   (methods.watch("response") === "Yes" ||
                        //     !methods.watch("response"))
                        // ) {
                        //    handleSubmitDocReason(val);

                        // }
                        if (
                           val === "No" &&
                          methods.watch("employer")?.length > 0
                        ) {
                          //setConfirmDelete(true);
                          setOpen(true);
                        }
                      }
                    }}
                  />
                  <div
                    className={
                      methods.watch("response") != null
                        ? "login-radio-types p-2 mb-4"
                        : "d-none"
                    }
                  >
                    {methods.watch("response") == "No" && (
                      <>
                        {/* <div> */}
                        <div className="p-2">
                          <NoDocReasonRadioButtons
                            // handleOnchange={() => {
                            //   handleAPIDebounced();
                            // }}
                            source="no_doc_reason"
                            options={
                              invitationType == "1"
                                ? [
                                  {
                                    name: "I am not currently employed",
                                    value: "I am not currently employed",
                                  },
                                  {
                                    name: "I am retired",
                                    value: "I am retired",
                                  },
                                  {
                                    name: "Full-Time Student",
                                    value: "Full-Time Student",
                                  },
                                  {
                                    name: "Other",
                                    value: "Other",
                                  },
                                ]
                                : [
                                  {
                                    name: "I do not currently have access to document(s), but can provide later",
                                    value:
                                      "I do not currently have access to document(s), but can provide later",
                                  },
                                  {
                                    name: "I do not have proof of Income",
                                    value: "I do not have proof of Income",
                                  },
                                  {
                                    name: "Other",
                                    value: "other",
                                  },
                                ]
                            }
                            section_id={3}
                          />
                          {methods?.watch("no_doc_reason")?.toLowerCase() ==
                            "other" && (
                              <div className="col-12 col-md-4 col-lg-4 mr-l30 mr-b10">
                                <InputField2
                                  handleOnChange={() => {
                                    handleAPIDebounced();
                                  }}
                                  source="other_reason"
                                  label={"Other reason"}
                                  placeholder=""
                                  labelRequired={role === "applicant"}
                                />
                              </div>
                            )}

                          {/* {role === "applicant" && ( */}
                          {/* <button
                            type="submit"
                            ref={submitButtonRef}
                            className="btn btn-primary"
                            style={{ display: "none" }}
                          >
                            {" "}
                            Save
                          </button> */}
                          {/* )} */}
                        </div>
                      </>
                    )}
                    {methods.watch("response") == "Yes" &&
                      fields.length > 0 &&
                      (
                        <>


                          <div className="">
                            {fields.map((field: any, index) => (
                              <EmployerSource
                                key={index}
                                // key={field.controlId}
                                field={field}
                                index={index}
                                mannualSOCRstatus={mannualSOCRstatus}
                                socrStatus={socrStatus}
                                serEmployerNumber={serEmployerNumber}
                                setConfirmDeleteSource={setConfirmDeleteSource}
                                methods={methods}
                                loadingdeleteEmployer={loadingdeleteEmployer}
                                confirmDeleteSource={confirmDeleteSource}
                                meta_d_response={meta_d_response}
                                IncomeAPIResponse={IncomeAPIResponse}
                                role={role}
                                trn_id={trn_id}
                                handleDeleteEmployer={handleDeleteEmployer}
                                setButtonVisible={setButtonVisible}
                                buttonVisible={buttonVisible}
                                getSrcfromStatus={getSrcfromStatus}
                                getClassfromStatus={getClassfromStatus}
                                gettextStylefromStatus={gettextStylefromStatus}
                                grossPayAvg={grossPayAvg}
                                refresh={refresh}
                                adminValidationMessages={
                                  adminValidationMessages
                                }
                                invitationType={invitationType}
                                required={props.required}
                                handleAPIDebounced={handleAPIDebounced}
                                filePreview={filePreview}
                                setFilePreview={setFilePreview}
                                collapse={collapse}
                                setCollapse={setCollapse}
                                LookbacksData={LookbacksData}
                                handleReprocessEmployer={
                                  handleReprocessEmployer
                                }
                                mlClassDetails={mlClassDetails}
                              />
                            ))}
                          </div>

                        </>
                      )}
                    <br />

                  </div>
                  <Card>
                    <CardHeader sx={{ padding: 0 }} />
                  </Card>
                  {/* <Confirm
                    isOpen={confirmDelete}
                    sx={{
                      "& .RaConfirm-iconPaddingStyle": { display: "none" },
                    }}
                    title="Are you sure want to delete employer ?
                    "
                    content={" Warning! Once deleted cannot revert back ."}
                    onConfirm={() => {
                      handleDeleteAllEmployer()
                      setDisableAddSource(false);
                    }}
                    onClose={() => {
                      methods.setValue("response", "Yes");
                      setConfirmDelete(false);
                    }}
                    loading={loadingAllEmployer}
                  /> */}
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">{"Warning!"}</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        You must delete all existing sources first.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => {
                          methods.setValue("response", "Yes");
                          // setConfirmDelete(false); // Ensures response does not change
                          handleClose();
                        }}
                        color="primary"
                      >
                        OK
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Confirm
                    isOpen={userCloseBrowser && role !== "applicant"}
                    sx={{
                      "& .RaConfirm-iconPaddingStyle": { display: "none" },
                    }}
                    title="Do you want to save your changes?"
                    content={"If not saved your changes will be lost."}
                    onConfirm={() => {
                      OnSubmitIncome(methods.getValues());
                      setUserCloseBrowser(false);
                    }}
                    onClose={() => {
                      setUserCloseBrowser(false);
                    }}
                  />

                  <div className="d-flex justify-content-between print-hide mb-2">
                    {methods.watch("response") && role != "applicant" ? (
                      <button
                        type="submit"
                        className="btn btn-primary print-hide mb-2"
                        style={{
                          backgroundColor: "rgb(45, 195, 232)",
                          color: "white",
                          marginLeft: 18,
                          padding: "8px 15px 8px 15px",
                        }}
                        ref={submitButtonRef}
                      >
                        {" "}
                        Save
                      </button>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>

                {methods.watch("employer")?.length < 3 &&
                  methods.watch("response") === "Yes" && (
                    <div
                      className={`  ${!expandIncomeSection ? "d-none" : "d-display"
                        } collpase-ic-new text-center print-hide`}
                    >
                      {/* <JSONTree data={disableAddSourceoption}/> */}
                      {props?.IncomeAPIResponse?.data?.employment_details?.employer?.length >= 1 && !IncomeAPIResponse?.isFetching && <button
                        type="button"
                        style={{
                          fontSize: "1rem",
                          fontFamily: "OpenSans-Bold",
                        }}
                        disabled={disableNewBtn || !buttonVisible}
                        onClick={() => {
                          setdisableNewBtn(true)
                          createNewEmployer({
                            trn_id: trn_id,
                            document_id: "3",
                            source_id: fields.length + 1,
                          });
                          setDisableAddSource(true);
                        }}
                      >

                        Add Employer
                      </button>}
                    </div>
                  )}
              </div>
            </div>
          </fieldset>
        </form>
      </FormProvider>
    </>
  );
});

export default ProofOfEmploymentIncome;
