import React, { useEffect, useRef } from 'react'
import { Quill } from 'react-quill';

export default function RenderQuillText({ text }) {
    const quillRef = useRef(null);
    const quillInstanceRef = useRef<any>(null);
   
    useEffect(() => {
        const isEmpty = !quillInstanceRef?.current?.root?.innerHTML.trim() || quillInstanceRef?.current?.root?.innerHTML === '<p><br></p>';

        if (isEmpty) {
            // Set the default value because the editor is empty
            quillInstanceRef?.current?.clipboard?.dangerouslyPasteHTML(0, text);
        }
    }, [text])

 
    useEffect(() => {
        if (quillRef.current) {
            const editor = new Quill(quillRef.current, {
                theme: 'snow',
                readOnly: true,
                modules: {
                    toolbar:
                        false,


                }
            });

           

             quillInstanceRef.current = editor;

            }
    }, []);
    return (
        <div ref={quillRef}>

        </div>
    )
}
