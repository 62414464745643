// import React, { useEffect } from 'react';

// const GoogleTranslate: React.FC = () => {
//   useEffect(() => {
//     const addScript = document.createElement('script');
//     addScript.setAttribute(
//       'src',
//       '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
//     );
//     document.body.appendChild(addScript);

//     // Ensure googleTranslateElementInit is available on the window object
//     (window as any).googleTranslateElementInit = googleTranslateElementInit;
//   }, []);

//   const googleTranslateElementInit = () => {
//     if ((window as any).google && (window as any).google.translate) {
//       new (window as any).google.translate.TranslateElement(
//         {
//           pageLanguage: 'en',
//           layout: (window as any).google.translate.TranslateElement.InlineLayout.HORIZONTAL,
//         },
//         'google_translate_element'
//       );
//     }
//   };

//   return <div id="google_translate_element"></div>;
// };

// export default GoogleTranslate;


import React, { useEffect } from 'react';

const GoogleTranslate: React.FC = () => {
  useEffect(() => {
    const scriptId = 'google-translate-script';

    // Check if the script is already added
    if (!document.getElementById(scriptId)) {
      const addScript = document.createElement('script');
      addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
      addScript.setAttribute('id', scriptId);
      document.body.appendChild(addScript);

      // Ensure googleTranslateElementInit is available on the window object
      (window as any).googleTranslateElementInit = googleTranslateElementInit;
    } else {
      // If script is already present, just initialize
      googleTranslateElementInit();
    }

    return () => {
      // Clean up the script and initialization function
      const script = document.getElementById(scriptId);
      if (script) {
        document.body?.removeChild(script);
      }
      // delete (window as any).googleTranslateElementInit;
    };
  }, []);

  const googleTranslateElementInit = () => {
    if ((window as any).google && (window as any).google.translate) {
      new (window as any).google.translate.TranslateElement(
        {
          pageLanguage: 'en',
          layout: (window as any).google.translate.TranslateElement.InlineLayout.HORIZONTAL,
        },
        'google_translate_element'
      );
    }
  };

  return <div id="google_translate_element"></div>;
};

export default GoogleTranslate;
