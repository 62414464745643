import commentSquare from "../../../assets/images/commentSquare.svg";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import { JSONTree } from "react-json-tree";

const ShowCommentsInternal = ({ section_comment, role,comment_heading='Docuverus Comment'}) => {
  const MAX_CHAR_PER_LINE = 195;
 
  const breakLines = (text, maxLength) => {
    const regex = new RegExp(`.{1,${maxLength}}(?=\\s|$)`, 'g');
     return text?.match(regex);
  };
 
  const renderComment = (text) => {
    const lines = breakLines(text, MAX_CHAR_PER_LINE);
    return lines?.map((line, index) => (
      <div key={index}>{line}</div>
    ));
  };
  return section_comment ? (
    <>
      {role !== "applicant" &&
        section_comment["admin"] &&
        section_comment["admin"] !== "" && (
          <div
            className={`notify-wrap ${
              !section_comment["applicant"] ||
              section_comment["applicant"] === ""
                ? "mr-b20"
                : ""
            }`}
            style={{
              width: "auto",
              whiteSpace: "normal",
              wordWrap: "break-word",
              
            }}
          >
            <div className="notify-head mt-2">
              <h4>{comment_heading}</h4>
            </div>
            <Alert severity="info" sx={{ fontSize: 13 }}>
            {renderComment(section_comment["admin"])}
            </Alert>
          </div>
        )}

      {/* {section_comment["applicant"] && section_comment["applicant"] !== "" && (
        <div className="notify-wrap mr-b20 mt-2 ">
          <div className="notify-head">
            <h4 style={{ fontSize: 11 }}>Applicant Comment</h4>
          </div>
          <Alert severity="info" sx={{ fontSize: 13 }}>
            {renderComment(section_comment["applicant"])}
          </Alert>
        </div>
      )} */}
    </>
  ) : null;
};

export default ShowCommentsInternal;
