import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/DocuVerusLogoorg.png";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DevTool } from "@hookform/devtools";
import { FormProvider, useForm } from "react-hook-form";
// import { toast, ToastContainer } from "react-toastify";
import { Outlet, Link, useNavigate, Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import InputField2 from "../../componets/common/InputComponents/InputField2";
import { Button, useCreate, useNotify, useRefresh, useGetList } from "react-admin";
import {
  setaspNetUserId,
  setIsLoggedIn,
  setLoginData,
  setToken,
  setUserRole,
} from "../../Store/Slice/usersSlice";
import { ATS_DOCS_URL, SOFTWARE_VERSION, typesofUsers } from "../../config";
import { CircularProgress } from "@mui/material";
import { useLogin, Notification } from "react-admin";
import "../../assets/styles/style.css";
import ForgotPassword from "./ForgotPassword";
import DataPrivacy from "./DataPrivacy";
import TermsOfUse from "./TermOfUse";
import MfaLogin from "./MfaLogin";

const Login = (props) => {
  const login = useLogin();
  const notify = useNotify();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const [loginResponse, setLoginResponse] = useState<any>(null);
  const [create, { isLoading, error }] = useCreate();



  const schema = yup.object().shape({
    password: yup.string().required("required"),
    email: yup.string().typeError("required").required("required"),
  });

  const methods = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  useEffect(() => {
    document.title = "Docuverus";
  }, []);

 

  // const onSuccessCall = (data) => {
  //   notify(data?.data?.messages, { type: "success", anchorOrigin: { vertical: 'top', horizontal: 'right' }});
  //   refresh();
  //   // methods?.reset();
  //   sessionStorage.setItem(
  //     "accountLogin",
  //     JSON.stringify({
  //       accountLoggedIn: true,
  //       auth: data?.data,
  //       token: data?.data?.tokendata,
  //     })
  //   );
  //   dispatch(setLoginData(data?.data));
  //   dispatch(setIsLoggedIn(true));
  //   //
  //   dispatch(setaspNetUserId(data?.data?.userid));
  //   dispatch(setUserRole(data?.data?.userrole));
  //   dispatch(setToken(data?.data?.tokendata));
  // };

  const onSubmit = (data) => {
    const sendData = {
      username: data?.email,
      password: data?.password,
    };
    login({ ...sendData }).then(data => {
      setLoginResponse({...data,sendData});
    }).catch((e) => {
      setLoginResponse(null)
      notify(e.message, {
        anchorOrigin: { vertical: "top", horizontal: "right" },
      })
    }
    );
    // create(
    //   "accountLogin",
    //   { data: sendData },
    //   {
    //     onSuccess: (data) => {
    //       const userTypes = {
    //         subscribers: [
    //           typesofUsers?.subscriberUser,
    //           typesofUsers?.subscriberAdmin,
    //           typesofUsers?.agent,
    //           typesofUsers?.manager,
    //         ],
    //         admins: [
    //           typesofUsers?.docuverusSuperAdmin,
    //           typesofUsers?.admin,
    //           typesofUsers?.superAdmin,
    //         ],
    //         applicants: [
    //           typesofUsers?.applicant,
    //           typesofUsers?.coApplicant,
    //           typesofUsers?.coSigner,
    //         ],
    //         thirdPartyAgent: [typesofUsers?.landlordThirdPartyAgent],
    //       };

    //       if (userTypes.subscribers.includes(data?.data?.userrole)) {
    //         navigate("/Ats/Subscriber/Inbox/" + data?.data?.subscriberid, {
    //           replace: true,
    //         });
    //         onSuccessCall(data);
    //       } else if (userTypes.admins.includes(data?.data?.userrole)) {
    //         navigate("/Ats/Admin/SubscriberUsageList", { replace: true });
    //         onSuccessCall(data);
    //       } else if (userTypes.applicants.includes(data?.data?.userrole)) {
    //         window.open(
    //           `https://uatmergeats.dvapply.com/login?trn_id=${data?.data?.trn_id}&token=${data?.data?.tokendata}`,
    //           "_self"
    //         );
    //         onSuccessCall(data);
    //       } else if (userTypes.thirdPartyAgent.includes(data?.data?.userrole)) {
    //         notify(`Please login to the old system.`, { type: "error", anchorOrigin: { vertical: 'top', horizontal: 'right' }});
    //       } else {
    //         notify(`This type of user role is not available.`, {
    //           type: "error",
    //            anchorOrigin: { vertical: 'top', horizontal: 'right' }
    //         });
    //       }
    //     },
    //     onError: (e: any) =>
    //       notify(`Operation fail:${e.message}`, { type: "error", anchorOrigin: { vertical: 'top', horizontal: 'right' }}),
    //   }
    // );
  };
  if (loginResponse?.status==301) {
    return <MfaLogin username={loginResponse?.sendData?.username} />
  }
  return (
    <>
      <section className="login-wrap">
        <div className="login-cell">
          <div className="login-block">
            <div className="l-logo-block text-center">
              <img src={Logo} alt="Docuverus" title="Docuverus" />
            </div>

            <div className="auth-form mr-t30">
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <div className="form-group cus-input-group mr-b20">
                    <InputField2
                      label="Email or Username"
                      // register={methods.register("email")}
                      source={`email`}
                      placeholder="Enter Email or Username here"
                      labelClass={"lable-title"}
                      additionalInputClass={"cus-control"}
                    />
                  </div>

                  <div className="form-group cus-input-group mr-b20">
                    {/* <InputSimplePassword
                      label="Password"
                      register={methods.register("password")}
                    /> */}
                    <InputField2
                      label="Password"
                      source={`password`}
                      type="password"
                      placeholder="Enter Password here"
                      labelClass={"lable-title"}
                      additionalInputClass={"cus-control"}
                    />
                  </div>

                  {/* <div className="f-link text-right">
                    <a href="#" onClick={(e) => setForgotFlag(true)}>
                      Forgot Password?
                    </a>
                  </div> */}
                  <div
                    className="ForgetPassword"
                    style={{
                      marginLeft: "220px",
                      color: "5555FF",
                      fontSize: "14px",
                    }}
                  >
                    <Link to="/atss/ForgotPassword">Forgot Password?</Link>

                  </div>
                  <div
                    className="ForgetPassword"
                    style={{
                      marginLeft: "220px",
                      color: "5555FF",
                      fontSize: "14px",
                    }}
                  >
                    <Link to="/atss/ForgotUsername">Forgot Username?</Link>

                  </div>
                  {isLoading ? (
                    <div className="static-d-info d-flex justify-content-center align-items-center">
                      <CircularProgress size="20px" />
                      <h5>&nbsp; &nbsp; Please wait...</h5>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center align-items-center">
                      <button type="submit" className="l-btn">
                        Login
                      </button>
                    </div>
                  )}
                </form>
              </FormProvider>
            </div>

            <div className="l-foot-links mr-t20">
              <ul className="mx-auto">
                <li>
                  {/* <a href={ATS_DOCS_URL + "atss/DataPolicy/Index"} target="_blank"> */}
                  <a href="/atss/DataPolicy" target="_blank">
                    Data Policy
                  </a>
                </li>

                <li>
                  <a href="/atss/TermsOfUse" target="_blank">
                    Terms of Use
                  </a>
                </li>
                <li>
                  Version {SOFTWARE_VERSION}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default Login;
