import { Button, Datagrid, DateField, FunctionField, List, ListBase, ListToolbar, NumberField, Pagination, Resource, TextField, useGetList, useGetOne, useListContext, useStore } from 'react-admin';
import {
    Box, TextField as MuiTextField,
    Button as MuiButton, Grid
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { useState } from 'react';
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import FilterModal from '../manageSubscribers/FilterModal';
import { useForm, FormProvider } from "react-hook-form";
import InputDateField from '../common/InputComponents/InputDateFieldOld';
import { ATS_API_URL } from '../../config';
import { debounce } from "lodash";
import InputSelectField from '../common/InputComponents/InputSelectField';
import React from 'react';
import StatsAndGraph from './DocuploadReportCount';
import { JSONTree } from 'react-json-tree';
import {
    CreateButton,
    DatagridConfigurable,
    ExportButton,
    FilterButton,
    SelectColumnsButton,
    TopToolbar,
    SearchInput,
} from 'react-admin';
import { position } from 'html2canvas/dist/types/css/property-descriptors/position';
import FilterReports from './FilterReports';
import ActionsReports from './ActionsReports';
import FilterChips from '../Ats/SuperAdminInbox/FilterChips';
import moment from 'moment';
// const ListActions = () => (
//     <TopToolbar>
//         <Button variant='contained' size='large' label='Export CSV' />
//     </TopToolbar>
// );
export const DocuploadManagementReportResource = () => {
    return <Resource name="getdocuploadmanagementreport" list={DocuploadManagement} />
}

const bgStyle = (condition: string) => {
    let style = {};
    switch (condition) {
        case "CompletedConditionallyApproved":
            style = { backgroundColor: "#FFF8E2", color: "#F9BB04" };
            break;
        case "NA":
            style = { backgroundColor: "#E9E9E9", color: "#909090" };
            break;
        case "Completed-Unacceptable":
            style = { backgroundColor: "#F8ECE2", color: "#FF4848" };
            break;
        case "Completed-Approved":
            style = { backgroundColor: "#E6FDF0", color: "#46A96F" };
            break;
        case "Invited":
            style = { backgroundColor: "#E9E9E9", color: "#909090" };
            break;
        case " In Progress":
            style = { backgroundColor: "#DDF8FF", color: "#1CA2C2" };
            break;
        case "Submitted":
        case "Review Pending":
            style = { backgroundColor: "#B0EFFF", color: "#127790" };
            break;
        // case "Screening":
        case "Completed":
        case "Valid":
        // case "Completed-Approved":
        case "Verified":
            style = { backgroundColor: "#E6FDF0", color: "#46A96F" };
            break;
        case "Cancelled":
        case "Archived":
        // case "Completed-Unacceptable":
        case "Invalid":
        case "Unverifiable":
            style = { backgroundColor: "#FFEEEE", color: "#FF4848" };
            break;
        case "Completed-Conditionally Approved":
        case "CompletedConditionallyApproved":
        case "Further Documentation Required":
        case "Verification Required":
        case "Further Doc Req.":
            style = { backgroundColor: "#FFF8E2", color: "#F9BB04" };
            break;
        default:
            style = {};
    }
    return style;
};


const MyList = ({ children, filters, actions, ...props }) => {
    
  

    return (
        <ListBase
            {...props}
            perPage={100} // Number of items per page
        >
            <ListToolbar
                filters={filters}
                actions={actions}
            />
            <StatsAndGraph  />
            <FilterChips excludeKeys={['app_invitation_type']} />
            {children}
            <Pagination rowsPerPageOptions={[100, 200, 300, 400, 500]} />
            <br />
            <br />
            <br />
            <br />
        </ListBase>
    );
};

const DocuploadManagement = () => {

    // console.log("datanew:", datanew);

    // const statusCountData: any = datanew?.records;
    // const statusCountData:any = datanew?.records ;

    return (
        <>

            {/* <JSONTree data={datanew} />
                {statusCountData && <StatsAndGraph data={datanew}/>} */}
            <br />
            <br />
            <MyList
                filters={<FilterReports />}
                actions={<ActionsReports />}
                sort={{ field: "ApplicationNumber", order: "DESC" }}
                // filterDefaultValues={{ subscriberID: 1 }}
                children={<DataGrid />}
            />
            {/* { (
               
            )} */}
            {/* <StatsAndGraph isAdmin={true} statusCountData={[]} subscriberCount={10} data={{ }} key={'d'} /> */}
            {/* {!loadingdocuploadmanagementstats && <StatsAndGraph data={datanew} />} */}
            {/* {datanew (
        <StatsAndGraph
          data={datanew}
        />
      )} */}


        </>
    )
}


const DataGrid = () => {
    return (<Datagrid bulkActionButtons={false}sx={{
        "& .RaDatagrid-rowEven": { backgroundColor: "#F9F9F9" },
        "& .RaDatagrid-expandIcon": { color: "#fff" },
        "& .RaDatagrid-expandIconCell": { backgroundColor: "primary.main" },
        "& .RaDatagrid-headerCell": {
            fontWeight: "bold",
            fontSize: 12,
        },
        "& .RaDatagrid-table": {
            fontSize: 12,
        },
        "& .MuiToggleButton-root.Mui-selected": {
            fontWeight: 700,
        },
    }}>
        <TextField source="ApplicationNumber" label={'Application No.'} />
        <TextField source="ApplicantID" label={'Applicant ID.'} />
        <FunctionField
        sortBy="AppDate"
      label="Date"
      render={(record) => {
        // Format the AppDate using moment.js
        const formattedDate = moment.utc(record.AppDate).format('MM/DD/YYYY');

        return (
          <>
            
            <p>{formattedDate}</p> {/* Display the formatted AppDate */}
            {/* <p>{record.LandLordPhone}</p> */} {/* Other data rendering if needed */}
          </>
        );
      }}
    />
        {/* <TextField source="id" /> */}
        <TextField source="Applicant Name" label={'Applicant'} />
        <TextField source="Type" />
        {/* <TextField source="OfficeName" label={'Office/Property'} /> */}
        <FunctionField
            label={"Office/Property"}
            sortBy="OfficeName"
            render={(record) => {
                return <span>{record.OfficeName || record.PropertyName}</span>
            }
            }
        />
        {/* <TextField source="PropertyName" /> */}
        <FunctionField
            label={"ID Status"}
            source="ID Status"
            render={(record) => {
                return (
                    <MuiButton
                        sx={{ width: "80%" }}
                        variant="contained"
                        disableElevation={true}
                        style={bgStyle(record["ID Status"])}
                    >
                        {record["ID Status"]}
                    </MuiButton>
                );
            }}
        />
        <FunctionField
            label={"Income Status"}
            source="Income Status"
            render={(record) => {
                return (
                    <MuiButton
                        sx={{ width: "80%" }}
                        variant="contained"
                        disableElevation={true}
                        style={bgStyle(record["Income Status"])}
                    >
                        {record["Income Status"]}
                    </MuiButton>
                );
            }}
        />
        <FunctionField
            label={"Banking Status"}
            source="Banking Status"
            render={(record) => {
                return (
                    <MuiButton
                        sx={{ width: "80%" }}
                        variant="contained"
                        disableElevation={true}
                        style={bgStyle(record["Banking Status"])}
                    >
                        {record["Banking Status"]}
                    </MuiButton>
                );
            }}
        />
        <FunctionField
            label={"Overall Status"}
            source="Overall Status"
            render={(record) => {
                return (
                    <MuiButton
                        sx={{ width: "70%" }}
                        variant="contained"
                        disableElevation={true}
                        style={bgStyle(record["Overall Status"])}
                    >
                        {record["Overall Status"]}
                    </MuiButton>
                );
            }}
        />
        <TextField source="Calculated Income" label={'Income'} />

    </Datagrid>)
}
export default DocuploadManagement;