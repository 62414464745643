import React, { Suspense, useEffect } from 'react';
import './App.css';
import { Navigate, Outlet, Route, useLocation } from 'react-router-dom'
import { BrowserRouter, Routes } from 'react-router-dom';
import ValidatingLink from './Container/ValidatingLink/ValidatingLink';
import AtsApp, { PublicRoutes } from './AtsApp';
import Login from './Container/Login/Login';
import Mfaverify from './Container/MFA/Mfaverify';
import AtsAppLandloard from './AtsAppLandloard';
import { JSONTree } from 'react-json-tree';
import { typesofUsers } from './config';

const LazyDocupload = React.lazy(() => import('./Docupload'));
const LazyMyML = React.lazy(() => import('./MyML'));

function Testing() {
  return (
    <div>App</div>
  )
}

const PrivateRoutes = () => {
  const location = useLocation();
  const authLogin = JSON.parse(sessionStorage.getItem('auth') || "")

  if (authLogin === undefined) {
    return null; // or loading indicator/spinner/etc
  }

  return authLogin
    ? <Outlet />
    : <Navigate to="/login" replace state={{ from: location }} />;
}

const App = () => {
  // useEffect(() => {
  //   // Function to handle the 'beforeunload' event, triggered when the user attempts to leave the page or close tab
  //   const handleBeforeUnload = (event) => {
  //     event.preventDefault();
  //     event.returnValue = "";
  //   };
  //   // Add the event listener when the component mounts
  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   // Cleanup function to remove the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);



  // export default AuthGuard;

  return <BrowserRouter>
    <Routes>
      {/* <Route path='/avi' element={<Login />} /> */}
      <Route path='/ml/*' element={<Suspense fallback={<div>Loading...</div>}>
        <LazyMyML />
      </Suspense>} />

      <Route path='/ats/*' element={<AtsApp />} />

      <Route path='/landloard/*' element={<AtsAppLandloard />} />

      <Route path='/atss/*' element={<PublicRoutes />} />
      <Route path='/admin/*' element={
        <Suspense fallback={<div>Loading...</div>}>
          <LazyDocupload />
        </Suspense>} />
      <Route path='/' element={<Navigate to="/ats" replace />} />

    </Routes>
  </BrowserRouter>
};

export default App;
